import React, { useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Budgets.module.scss';
import PageHeader from '../../components/PageHeader';
import AddIcon from '@mui/icons-material/Add';
import { AuthContext } from '../../context';
import {
  ADMIN,
  AGENCY,
  BRAND_MANAGER,
  IS_DIY_ADZ,
  MARKETING_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import {
  getBudgetReport,
  getBudgetReportByAgency,
  getBudgetReportByLocation,
  getBudgetReportByManager,
  getBudgetReportBySalesperson,
} from '../../services/budget';
import { Agency, Brand, BudgetReport } from '../../types';
import moment, { Moment } from 'moment';
import { humanizeString } from '../../utils/stringModifier';
import { CSVLink } from 'react-csv';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../../components/Buttons';
import CircularLoading from '../../components/CircularLoading';
import MonthAndYearPicker from '../../components/DateAndTimePickers/MonthAndYearPicker';
import { buildRefreshToken } from '../../utils/helpers/DefaultTokenBuilder';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { setEndRange, setStartRange } from '../../redux/actions';
import DateRangePicker from '../../components/DateAndTimePickers/DateRangePicker';
import BudgetReportChatzTable from '../../components/Tables/BudgetList/BudgetReportChatzTable';

const ChatzBudgets: React.FC = () => {
  const dispatch = useDispatch();
  const campaignProvider = useSelector(
    (state: any) => state?.budgetReportProvider?.provider,
  );
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );
  const startRange = useSelector((state: any) => state.startRange.date)?.tz(
    timezone,
  );
  const endRange = useSelector((state: any) => state.endRange.date)?.tz(
    timezone,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [reportRows, setReportRows] = useState<any[]>([]);
  const [reportMonth, setReportMonth] = useState<string>(
    moment().utc().local().format('DD') === '01'
      ? moment().utc().local().subtract(1, 'day').format('MMMM')
      : moment().utc().local().format('MMMM'),
  );
  const [reportYear, setReportYear] = useState<string>(
    moment().utc().local().format('DD') === '01'
      ? moment().utc().local().subtract(1, 'day').year().toString()
      : moment().utc().local().year().toString(),
  );
  const [reportDateValue, setReportDateValue] = useState<Moment | null>(
    moment().utc().local().subtract(1, 'day'),
  );
  const [daysCount, setDaysCount] = useState<number>(
    parseInt(moment().utc().local().subtract(1, 'day').format('DD')),
  );
  const [monthDays, setMonthDays] = useState<number>(
    moment().utc().local().format('DD') === '01'
      ? moment().utc().local().subtract(1, 'day').daysInMonth()
      : moment().utc().local().daysInMonth(),
  );
  const [defaultRefreshToken, setDefaultRefreshToken] = useState<string>(null);

  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const isAgency = state.role === AGENCY;
  const allowedAddBudget = isSuperAdmin || isAdmin;
  const allowedExport = isSuperAdmin || isAdmin || isAgency;
  const isBrandManager = state.role === BRAND_MANAGER;
  const isMarketingManager = state.role === MARKETING_MANAGER;
  const isSalesperson = state.role === SALESPERSON;
  const userBrand = state.authUser?.brand;
  const isBrand = userBrand !== null && userBrand !== undefined;
  const refreshToken = state.authUser?.refreshToken;
  const roleBasedId = state.roleBasedId;
  const agency: Agency = useSelector((state: any) => state?.agency?.agency);
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const pathLocation = useLocation();
  const pathNames = pathLocation.pathname.split('/');
  const isReport = pathNames.includes('kpiz');

  useEffect(() => {
    handleBuildProviderTokens();
  }, []);

  useEffect(() => {
    try {
      if (reportYear && reportMonth && defaultRefreshToken && !isReport) {
        setReportRows([]);
        getBudgetReports();
      }
    } catch (error: any) {
      console.log(error.message);
    }
  }, [
    reportYear,
    reportMonth,
    campaignProvider,
    defaultRefreshToken,
    agency,
    isReport,
  ]);

  useEffect(() => {
    try {
      if (startRange && endRange && IS_DIY_ADZ && brand && isReport) {
        setReportRows([]);
        getBudgetReports();
      }
    } catch (error: any) {
      console.log(error.message);
    }
  }, [startRange, endRange, IS_DIY_ADZ, brand, isReport]);

  const getBudgetReports = async () => {
    setLoading(true);

    try {
      if (IS_DIY_ADZ) {
        const data = await getBudgetReportByLocation(
          brand?._id,
          reportMonth,
          reportYear,
          defaultRefreshToken,
          campaignProvider,
          isReport,
          startRange,
          endRange,
        );

        reportRowData(data?.data || [], daysCount, monthDays);
      } else {
        if (isAgency) {
          const data = await getBudgetReportByAgency(
            roleBasedId,
            reportMonth,
            reportYear,
            defaultRefreshToken,
            campaignProvider,
            isReport,
            startRange,
            endRange,
          );

          reportRowData(data?.data || [], daysCount, monthDays);
        } else if (isSalesperson) {
          const data = await getBudgetReportBySalesperson(
            roleBasedId,
            reportMonth,
            reportYear,
            defaultRefreshToken,
            campaignProvider,
            isReport,
            startRange,
            endRange,
            isBrand,
          );

          reportRowData(data?.data || [], daysCount, monthDays);
        } else if (isBrandManager) {
          const data = await getBudgetReportByManager(
            roleBasedId,
            reportMonth,
            reportYear,
            defaultRefreshToken,
            campaignProvider,
            isReport,
            startRange,
            endRange,
            isBrand,
          );

          reportRowData(data?.data || [], daysCount, monthDays);
        } else if (isMarketingManager) {
          const data = await getBudgetReportByLocation(
            brand?._id,
            reportMonth,
            reportYear,
            defaultRefreshToken,
            campaignProvider,
            isReport,
            startRange,
            endRange,
          );

          reportRowData(data?.data || [], daysCount, monthDays);
        } else {
          if (agency) {
            const data = await getBudgetReportByAgency(
              agency?._id,
              reportMonth,
              reportYear,
              defaultRefreshToken,
              campaignProvider,
              isReport,
              startRange,
              endRange,
            );

            reportRowData(data?.data || [], daysCount, monthDays);
          } else {
            const data = await getBudgetReport(
              reportMonth,
              reportYear,
              defaultRefreshToken,
              campaignProvider,
              isReport,
              startRange,
              endRange,
            );

            reportRowData(data?.data || [], daysCount, monthDays);
          }
        }
      }
    } catch (error: any) {
      reportRowData([], daysCount, monthDays);
      setLoading(false);
      console.log(error.message);
    }

    setLoading(false);
  };

  const handleSetReportRange = (date: Moment | null) => {
    const month = date.format('MMMM');
    const year = date.year();
    setReportYear(`${year}`);
    setReportMonth(`${month}`);
    setMonthDays(date.daysInMonth());
    const isCurrentMonth = date.isSame(moment().utc().local(), 'month');

    if (isCurrentMonth) {
      const days = date.format('DD');
      setDaysCount(parseInt(days));
    } else {
      const days = date.daysInMonth();
      setDaysCount(days);
    }
  };

  const reportRowData = (
    data: BudgetReport[],
    countDays: number,
    monthDays: number,
  ) => {
    const rows = data.map((report: any, index: number) => {
      const {
        id,
        brand,
        monthlyBudget,
        totalSpent,
        impressions,
        leadCount,
        cpm,
        cpl,
        clicks,
        status,
        cpc,
        daily,
      } = report;

      return {
        id,
        brand,
        platform: campaignProvider,
        status: humanizeString(status),
        budget: monthlyBudget,
        spend: totalSpent,
        percentage: Number(
          monthlyBudget
            ? (totalSpent / ((monthlyBudget / monthDays) * countDays)) * 100
            : 0,
        ).toFixed(2),
        impressions: isReport
          ? impressions
          : parseFloat(Number(impressions / 10000).toFixed(2)),
        m2Lead: isReport
          ? Number(leadCount > 0 ? impressions / leadCount : 0).toFixed(2)
          : parseFloat(
              Number(
                leadCount > 0 ? impressions / 10000 / leadCount : 0,
              ).toFixed(2),
            ),
        c2Lead: parseFloat(
          Number(leadCount > 0 ? clicks / leadCount : 0).toFixed(2),
        ),
        cpm,
        clicks,
        leads: leadCount,
        cpl,
        cpc: parseFloat(Number(cpc).toFixed(2)),
        daily,
      };
    });

    setReportRows(rows);
  };

  const buildReportExportData = () => {
    return reportRows.map((row: any) => {
      const {
        brand,
        platform,
        status,
        budget,
        spend,
        percentage,
        impressions,
        m2Lead,
        cpm,
        clicks,
        c2Lead,
        leads,
        cpc,
        daily,
      } = row;

      return {
        Brand: brand,
        Platform: platform,
        Status: status,
        Budget: budget || 0,
        Spend: spend,
        Percentage: `${percentage}%`,
        Daily: parseFloat(Number(daily).toFixed(2)),
        Impressions: impressions,
        M2Lead: m2Lead,
        CPM: cpm,
        Clicks: clicks,
        C2Lead: c2Lead,
        CPC: cpc,
        Leads: leads,
        CPL: parseFloat(Number(leads === 0 ? 0.0 : spend / leads).toFixed(2)),
      };
    });
  };

  const handleBuildProviderTokens = () => {
    buildRefreshToken(refreshToken, setDefaultRefreshToken);
  };

  const handleChangeDateRange = (dates: Moment[] | []) => {
    dispatch(setStartRange(dates[0]));
    dispatch(setEndRange(dates[1]));
  };

  return (
    <div className={styles.budgets}>
      <div className={styles.base}>
        <CircularLoading loading={loading} />

        <div className={styles.header}>
          <PageHeader title={IS_DIY_ADZ ? 'Reportz' : 'Budgets'} />

          {isReport ? (
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sx={{
                  justifyContent: 'center',
                  display: 'flex',
                  mb: 1,
                }}
              >
                <DateRangePicker
                  toDate={endRange}
                  fromDate={startRange}
                  onChange={handleChangeDateRange}
                  maxDate={moment()}
                  separator="-"
                />
              </Grid>
            </Grid>
          ) : null}

          <Grid
            container
            spacing={1}
            sx={{
              justifyContent: xsOnly ? 'center' : 'space-between',
            }}
          >
            <Grid item sx={{ flexDirection: xsOnly ? 'column' : '' }}>
              {allowedAddBudget && !IS_DIY_ADZ ? (
                <PrimaryButton
                  title={`Set Up ${humanizeString(
                    campaignProvider,
                  )} Ads Budgets`}
                  type="button"
                  startIcon={<AddIcon />}
                  handleOnClick={() => navigate('/budgets/set-up')}
                  width={xsOnly ? '100%' : 'unset'}
                />
              ) : null}

              {allowedExport ||
              (IS_DIY_ADZ &&
                (isSuperAdmin || isAdmin || isMarketingManager)) ? (
                <CSVLink
                  data={reportRows.length > 0 ? buildReportExportData() : []}
                  filename={`budget_report_${moment().unix()}.csv`}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    marginLeft: xsOnly ? 0 : '5px',
                  }}
                >
                  <PrimaryButton
                    variant="outlined"
                    type="button"
                    title="Export"
                    startIcon={<FileDownloadOutlinedIcon />}
                    width={xsOnly ? '100%' : 'unset'}
                    marginTop={xsOnly ? '10px' : 'unset'}
                  />
                </CSVLink>
              ) : null}
            </Grid>

            {!isReport ? (
              <Grid item>
                <MonthAndYearPicker
                  value={reportDateValue}
                  setValue={setReportDateValue}
                  handleChangeDate={handleSetReportRange}
                  endDay={daysCount.toString()}
                  isDiy={IS_DIY_ADZ}
                  timezone={timezone}
                />
              </Grid>
            ) : null}
          </Grid>
        </div>

        <div>
          <BudgetReportChatzTable
            budgetReports={reportRows}
            loading={loading}
            isDiy={IS_DIY_ADZ}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatzBudgets;
