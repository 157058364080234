import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Modal,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Brand } from '../../../../types';
import {
  fetchAssetLocationSet,
  fetchGoogleTemplates,
  assembleGoogleAds,
  fetchGoogle,
} from '../../../../services/googleAds';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import LinearProgressBarWithLabel from '../../../ProgressBar/LinearProgressBarWithLabel';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';
import CircularLoading from '../../../CircularLoading';
import {
  biddingStrategies,
  formatNumber,
  GoogleAssetsContainer,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import CampaignCreationInstructions from './GGAdzCreationInstruction';
import GoogleTemplatesPreview from '../../Templatez/Google/GGLTemplatesPreview';
import GGLAssetsLocationTable from '../../Assetz/Google/GGLAssetsLocationTable';
import GoogleTemplateSummary from '../../Templatez/Google/GGLTemplateSummary';

interface GGAdzCreationProps {
  brand: Brand;
  doneCreating: any;
  adsCollection: any[];
  location?: string;
  baseAd?: any;
  adsData?: any[];
  editPages?: string[];
  cancelCreation: () => void;
}

const GGAdzCreation: React.FC<GGAdzCreationProps> = ({
  brand,
  location,
  doneCreating,
  adsCollection,
  baseAd,
  adsData,
  editPages,
  cancelCreation,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [templateCollection, setTemplateCollection] = useState([]);
  const [assetsLocationSet, setAssetsLocationSet] = useState([]);
  const [campaignCollection, setCampaignCollection] = useState([]);
  const [adName, setAdName] = useState(``);
  const [adDescription, setAdDescription] = useState(``);
  const [campaignNameTag, setCampaignNameTag] = useState(``);
  const [adGroupNameTag, setAdGroupNameTag] = useState(``);
  const [GGLCampaignName, setGGLCampaignName] = useState(``);
  const [GGLAdGroupName, setGGLAdGroupName] = useState(``);
  const [GGLAdName, setGGLAdName] = useState(``);
  const [campaignDuration, setCampaignDuration] = useState('everyMonth');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedLocationType, setSelectedLocationType] = useState(null);
  const [pages, setPages] = useState(
    editPages?.length
      ? editPages
      : [
          'Instructions',
          'Template',
          'Location',
          'Budget',
          'Campaign',
          'Review',
        ],
  );
  const [currentPage, setCurrentPage] = useState('');
  const [budget, setBudget] = useState('');
  const [budgetNextMonth, setBudgetNextMonth] = useState('');
  const [dailyBudget, setDailyBudget] = useState('');
  const [dateStart, setDateStart] = useState(
    new Date().toISOString().slice(0, 16),
  );
  const [biddingStrategy, setBiddingStrategy] = useState('MANUAL_CPC');
  const [biddingStrategyModal, setBiddingStrategyModal] = useState(false);
  const [isABTest, setIsABTest] = useState(false);
  const [dateEnd, setDateEnd] = useState('');
  const [selectedExistingCampaign, setSelectedExistingCampaign] = useState('');
  const [campaignList, setCampaignList] = useState([]);
  const [businessName, setBusinessName] = useState('');

  useEffect(() => {
    let campaignName = '';
    let adGroupName = '';
    const category = selectedTemplate?.category || 'search';

    if (baseAd) {
      campaignName = `${
        campaignList[baseAd?.googleResourceNumbers?.campaign]?.name
      }`;
      adGroupName = `${selectedTemplate?.name}-${
        campaignList[baseAd.googleResourceNumbers.campaign]?.adGroups?.length +
          1 || 1
      }`;
    } else {
      campaignName = `aiADZ-${location ? `${location}-` : ``}${category}-${
        Object.keys(campaignList).length
      }`;
      adGroupName = `${selectedTemplate?.name}-1`;
    }

    setGGLCampaignName(campaignName);
    setGGLAdGroupName(`${campaignName}-${adGroupName}`);
    setGGLAdName(`${campaignName}-${adGroupName}`);
    setAdName(`${campaignName}-${adGroupName}`);
  }, [selectedTemplate]);

  const handleExistingCampaignChange = (event: SelectChangeEvent) => {
    setSelectedExistingCampaign(event.target.value);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignDuration((event.target as HTMLInputElement).value);
  };

  const saveAd = () => {
    setIsLoading(true);
    assembleGoogleAds({
      isABTest,
      baseAd,
      brandId: brand._id,
      name: adName,
      description: adDescription,
      budget: budget,
      dailyBudget: dailyBudget,
      monthlyBudget: budgetNextMonth,
      dateStart: dateStart,
      dateEnd: dateEnd,
      budgetName: `${Date.now()}`,
      business_name: businessName,
      campaign: {
        name: GGLCampaignName,
        status: 'ENABLED',
        advertisingChannelType:
          selectedTemplate?.category === 'search' ? 'SEARCH' : 'DISPLAY',
      },
      adgroup: {
        name: `${GGLAdGroupName}`,
        status: `ENABLED`,
      },
      ad: {
        name: `${GGLAdName}`,
        status: `ENABLED`,
      },
      template: selectedTemplate,
      locationSet: selectedLocationType,
    })
      .then((res: any) => {
        console.log(`assembleGoogleAds:`);
        console.log(res);
        if (res.error) {
          dispatch(
            toggleAlert({
              toggle: true,
              type: 'error',
              message: res.error,
            }),
          );
        } else {
          doneCreating();
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(
          toggleAlert({
            toggle: true,
            type: 'error',
            message: 'Error creating Google Ad',
          }),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNextBtnClick = () => {
    let errors = false;
    let errorMsg = '';

    if (currentPage === 'Template') {
      errors = !selectedTemplate;
      errorMsg = 'Please select a template to continue';
    }

    if (currentPage === 'Location') {
      errors = !selectedLocationType;
      errorMsg = 'Please select a location set to continue';
    }

    if (currentPage === 'Budget') {
      errors =
        !dateStart ||
        !budget ||
        !budgetNextMonth ||
        !dailyBudget ||
        (campaignDuration === 'fixedDuration' ? !dateEnd : false);
      errorMsg = 'Please fill in all fields to continue';
    }

    if (!errors) {
      const getIndex = pages.indexOf(currentPage);
      console.log(`Current Page: ${currentPage}`);

      const nextPage = pages[getIndex + 1];
      setCurrentPage(nextPage);
      console.log(`Next Page: ${nextPage}`);
    } else {
      dispatch(
        toggleAlert({
          toggle: true,
          type: 'error',
          message: errorMsg,
        }),
      );
    }
  };

  const fetchTemplates = async () => {
    const { data } = await fetchGoogleTemplates(brand?._id);
    console.log(`$ts`);
    console.log(data);

    setTemplateCollection(data);
    setIsLoading(false);
  };

  const fetchLocationSets = () => {
    fetchAssetLocationSet({
      brandId: brand._id,
    }).then((res: any) => {
      setAssetsLocationSet(res);
    });
  };

  const checkIfABTest = () => {
    if (baseAd) {
      setCurrentPage('Template');
      setIsABTest(true);
      setAdName(baseAd?.name);
      setAdDescription(baseAd?.description);
      setCampaignNameTag(
        `${baseAd?.name.split('-')[0]}-${baseAd?.name.split('-')[1]}-${
          baseAd?.name.split('-')[2]
        }`,
      );
      setAdGroupNameTag(baseAd?.adGroupNameTag);
      setGGLCampaignName(
        `${baseAd?.name.split('-')[0]}-${baseAd?.name.split('-')[1]}-${
          baseAd?.name.split('-')[2]
        }`,
      );
      setGGLAdGroupName(baseAd?.adGroupName);
      setGGLAdName(baseAd?.adName);
      setBudget(baseAd?.budget);
      setDailyBudget(baseAd?.dailyBudget);
      setBudgetNextMonth(baseAd?.monthlyBudget);
      setDateStart(baseAd?.dateStart);
      setDateEnd(baseAd?.dateEnd);
      setSelectedLocationType(baseAd?.locationSet);
    } else {
      setCurrentPage(editPages?.length ? editPages[0] : 'Template');
    }
  };

  const fetchCampaignsAndAdGroupsAndAds = async () => {
    const rawData = adsData || [];
    const campaigns: any = {};

    rawData.forEach((c: any) => {
      campaigns[c.campaign.resourceName] = {
        ...campaigns[c.campaign.resourceName],
        ...c.campaign,
      };

      campaigns[c.campaign.resourceName].adGroups = [
        ...(campaigns[c.campaign.resourceName].adGroups || []),
        c.adGroup,
      ];
    });

    setCampaignList(campaigns);
  };

  const fetchGoogleAccount = async () => {
    const res = await fetchGoogle({
      brandId: brand._id,
      db: 'googleAccounts',
    });

    if (res.length > 0) {
      setBusinessName(res[0].business_name);
    }
  };

  useEffect(() => {
    fetchTemplates();
    fetchLocationSets();
    checkIfABTest();
    fetchCampaignsAndAdGroupsAndAds();
    fetchGoogleAccount();
  }, []);

  const handleBiddingStrategyChange = (event: SelectChangeEvent) => {
    setBiddingStrategy(event.target.value);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `100%`,
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: `4px solid ${DEFAULT_COLOR_THEME}`,
    boxShadow: 24,
    p: 4,
    minHeight: '200px',
  };

  const bidStratModal = (
    <>
      <Modal
        open={biddingStrategyModal}
        onClose={() => setBiddingStrategyModal(false)}
      >
        <Box sx={modalStyle}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{ backgroundColor: `#ffffff` }}
            >
              Bidding Strategy
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={biddingStrategy}
              label="Age"
              onChange={handleBiddingStrategyChange}
            >
              {biddingStrategies.map((b) => (
                <MenuItem value={b.biddingType}>{b.biddingType}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              color: DEFAULT_COLOR_THEME,
              fontWeight: 800,
              marginTop: 4,
              marginBottom: 1,
            }}
          >
            {biddingStrategy}
          </Box>

          <Box>
            {biddingStrategies
              .filter((b) => b.biddingType === biddingStrategy)
              .map((b) => (
                <Typography>{b.description}</Typography>
              ))}
          </Box>

          <Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: DEFAULT_COLOR_THEME,
                marginTop: 2,
                float: `right`,
              }}
              onClick={() => setBiddingStrategyModal(false)}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );

  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `space-between`,
        ...GoogleAssetsContainer,
      }}
    >
      <CircularLoading loading={isLoading} />
      <Box
        sx={{
          width: `100%`,
          overflowY: `auto`,

          ...scrollBarGreen,
        }}
      >
        {currentPage === 'Instructions' ? (
          <>
            <CampaignCreationInstructions />
          </>
        ) : null}
        {currentPage === 'Template' ? (
          <Box
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              gap: `2rem`,
            }}
          >
            <Box
              sx={{
                flexShrink: 0,
              }}
            >
              <Typography
                variant="h6"
                color={`primary`}
                sx={{ fontWeight: 800 }}
              >
                Select a template to create a new Google Ad
              </Typography>
              <Box sx={{ maxWidth: 400, paddingLeft: 2, marginTop: 2 }}>
                {templateCollection.map((t) => (
                  <Button
                    key={t._id}
                    sx={{
                      width: `100%`,
                      display: `block`,
                      textAlign: `left`,
                      border: '1px solid #aaa',
                      marginBottom: 1,
                      fontWeight: 800,
                      backgroundColor: selectedTemplate
                        ? selectedTemplate._id === t._id
                          ? `#e6ffd4`
                          : `#ffffff`
                        : `#ffffff`,
                      borderColor: selectedTemplate
                        ? selectedTemplate._id === t._id
                          ? DEFAULT_COLOR_THEME
                          : `#aaa`
                        : `#aaa`,
                    }}
                    onClick={() => {
                      console.log(t);
                      setSelectedTemplate(t);
                    }}
                  >
                    {t.name}
                  </Button>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                flexShrink: 1,
                //maxWidth: 700,
                maxWidth: 600,
                width: `100%`,
                overflowY: 'auto',
                backgroundColor: selectedTemplate ? `#ebebeb` : `#ffffff`,
                padding: `0.5rem`,
                ...scrollBarGreen,
              }}
            >
              {selectedTemplate ? (
                <Box
                  sx={{
                    backgroundColor: `#ffffff`,
                  }}
                >
                  {Object.keys(selectedTemplate).length > 0 ? (
                    <Box sx={{ padding: 2 }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: `1.2rem`,
                        }}
                      >
                        Preview:
                      </Typography>
                      <GoogleTemplatesPreview
                        category={selectedTemplate?.category}
                        headlines={selectedTemplate.headlines}
                        descriptions={selectedTemplate.descriptions}
                        sitelinks={selectedTemplate.sitelinks}
                        url={selectedTemplate.final_url}
                        showToolbar={false}
                        center={true}
                        images={selectedTemplate.images?.map(
                          (i: any) => i?.url,
                        )}
                        business_logo={selectedTemplate.business_logo?.url}
                      />
                      <GoogleTemplateSummary
                        template={selectedTemplate}
                        ad={baseAd?.name ? baseAd : null}
                      />
                    </Box>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : null}

        {currentPage === 'Location' ? (
          <Box sx={{}}>
            <Typography variant="h6" color={`primary`}>
              Select a location set to target your Google Ad
            </Typography>
            <Box sx={{ maxWidth: 800, paddingLeft: 2, marginTop: 2 }}>
              {assetsLocationSet?.map((l) => (
                <Box
                  key={l.name}
                  sx={{
                    width: `100%`,
                    textAlign: `left`,
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    paddingX: '0.5rem',
                    paddingY: '0.2rem',
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      border: '1px solid #aaa',
                      marginBottom: 1,
                      backgroundColor:
                        selectedLocationType?.name === l.name
                          ? `#e6ffd4`
                          : `#ffffff`,
                      borderColor:
                        selectedLocationType?.name === l.name
                          ? DEFAULT_COLOR_THEME
                          : `#aaa`,

                      maxHeight: 150,
                      minWidth: 700,
                      overflowY: 'auto',
                      ...scrollBarGreen,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingX: '0.5rem',
                        paddingY: '0.2rem',
                        position: 'sticky',
                        top: 0,
                        backgroundColor:
                          selectedLocationType?.name === l.name
                            ? `#e6ffd4`
                            : `#ffffff`,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: DEFAULT_COLOR_THEME,
                          fontWeight: 'bold',
                        }}
                      >
                        {l.name}
                      </Typography>
                      <Typography
                        sx={{
                          color: DEFAULT_COLOR_THEME,
                          fontWeight: 'bold',
                        }}
                      >
                        {l.locations.length} Locations
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                        padding: '0.5rem',
                      }}
                    >
                      <GGLAssetsLocationTable
                        locations={l.locations.map((loc: any) => {
                          return {
                            ...loc,
                            reach: formatNumber(loc.reach),
                          };
                        })}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Button
                    onClick={() => setSelectedLocationType(l)}
                    variant="contained"
                    sx={{
                      backgroundColor: DEFAULT_COLOR_THEME,
                      paddingX: '20px',
                    }}
                  >
                    Select
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        ) : null}

        {currentPage === 'Budget' ? (
          <Box sx={{}}>
            <Typography
              variant="h6"
              color={`primary`}
              sx={{
                fontWeight: 800,
              }}
            >
              PLEASE SET YOUR SCHEDULE AND BUDGET
            </Typography>
            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                label="Start Date/Time"
                variant="standard"
                type="datetime-local"
                value={dateStart}
                onChange={(e) => {
                  setDateStart(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  minWidth: 300,
                }}
              />
            </Box>

            <Box
              sx={{
                marginTop: 2,
              }}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={campaignDuration}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="everyMonth"
                    control={<Radio />}
                    label="Run campaign every month"
                  />
                  <FormControlLabel
                    value="fixedDuration"
                    control={<Radio />}
                    label="Stop campaign on"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box
              sx={{
                marginBottom: 2,
                opacity: campaignDuration === 'fixedDuration' ? 1 : 0,
                height: campaignDuration === 'fixedDuration' ? 'auto' : 0,
                transition: 'opacity 0.5s ease',
              }}
            >
              <TextField
                label="End Date/Time"
                variant="standard"
                type="datetime-local"
                value={dateEnd}
                onChange={(e) => {
                  setDateEnd(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  minWidth: 300,
                }}
              />
            </Box>

            <Box
              sx={{
                marginY: 4,
              }}
            >
              <TextField
                label="Current Month's Budget"
                variant="standard"
                value={budget}
                sx={{ marginRight: '2rem' }}
                name="currentMonthBudget"
                onChange={(e) => {
                  //remove letters and non .
                  const val = e.target.value.replace(/[^0-9.]/g, '');
                  setBudget(val);
                  //get remaining days in month
                  const today = new Date();
                  const lastDay = new Date(
                    today.getFullYear(),
                    today.getMonth() + 1,
                    0,
                  );
                  const daysLeft = lastDay.getDate() - today.getDate() + 1;
                  const dailyBudget = parseFloat(e.target.value) / daysLeft;
                  setDailyBudget(
                    val ? (Math.floor(dailyBudget * 100) / 100).toFixed(2) : '',
                  );
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">USD</InputAdornment>
                  ),
                }}
              />

              <TextField
                label="Future Monthly Budget"
                variant="standard"
                value={budgetNextMonth}
                onChange={(e) => {
                  //remove letters and non .
                  const val = e.target.value.replace(/[^0-9.]/g, '');

                  setBudgetNextMonth(val);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">USD</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                label="Daily Budget"
                variant="standard"
                value={dailyBudget}
                onChange={(e) => {
                  //remove letters and non .
                  const val = e.target.value.replace(/[^0-9.]/g, '');
                  setDailyBudget(val ? val : '');

                  //get remaining days in month
                  const today = new Date();
                  const lastDay = new Date(
                    today.getFullYear(),
                    today.getMonth() + 1,
                    0,
                  );
                  const daysLeft = lastDay.getDate() - today.getDate();
                  const targetBudget = parseFloat(val) * daysLeft;
                  setBudget(val ? targetBudget.toFixed(2) : '');
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">USD</InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box
              sx={{
                marginTop: 4,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                maxWidth: 500,
              }}
            >
              <Box>
                <Typography color={DEFAULT_COLOR_THEME} fontWeight={800}>
                  Bidding Strategy
                </Typography>
                <Typography fontWeight={800}>{biddingStrategy}</Typography>
              </Box>

              <Button
                variant="contained"
                sx={{
                  display: 'inline-block',
                }}
                onClick={() => setBiddingStrategyModal(true)}
              >
                Change
              </Button>
            </Box>
            <Box
              sx={{
                marginBottom: 4,
                marginTop: 1,
                textAlign: 'justify',
                maxWidth: 500,
              }}
            >
              {biddingStrategies
                .filter((b) => b.biddingType === biddingStrategy)
                .map((b) => (
                  <Typography>{b.description}</Typography>
                ))}
            </Box>
          </Box>
        ) : null}

        {currentPage === 'Preview' ? (
          <>
            <Typography
              variant="h6"
              color={`primary`}
              sx={{
                fontWeight: 800,
              }}
            >
              CAMPAIGN DETAILS
            </Typography>

            {isABTest ? (
              <Typography
                sx={{
                  fontWeight: 400,
                }}
              >
                Campaign name and description will be inherited from the
                original ad
              </Typography>
            ) : null}

            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                label="Ad Name"
                variant="outlined"
                sx={{
                  minWidth: 500,
                }}
                value={adName}
                onChange={(e) => {
                  setAdName(e.target.value);
                }}
                disabled={isABTest ? true : false}
              />
            </Box>

            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                multiline
                rows={4}
                label="Description"
                variant="outlined"
                sx={{
                  minWidth: 500,
                }}
                value={adDescription}
                onChange={(e) => {
                  setAdDescription(e.target.value);
                }}
                disabled={isABTest ? true : false}
              />
            </Box>
            <Typography
              color={`primary`}
              sx={{
                fontWeight: 600,
              }}
            >
              Campaign Name Tags
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
              }}
            >
              This tag will be used as the naming convention for the Google Ad
              Campaign
            </Typography>
            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                label="Campaign Name Tag"
                variant="outlined"
                sx={{
                  minWidth: 500,
                }}
                value={campaignNameTag}
                onChange={(e) => {
                  setCampaignNameTag(
                    e.target.value.length < GGLCampaignName.length
                      ? GGLCampaignName
                      : e.target.value.includes(GGLCampaignName)
                      ? e.target.value
                      : GGLCampaignName + e.target.value,
                  );
                }}
                helperText={GGLCampaignName}
                disabled={isABTest ? true : false}
              />
            </Box>
            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                label="Ad Group Name Tag"
                variant="outlined"
                sx={{
                  minWidth: 500,
                }}
                value={adGroupNameTag}
                onChange={(e) => {
                  setAdGroupNameTag(
                    e.target.value.length < GGLAdGroupName.length
                      ? GGLAdGroupName
                      : e.target.value.includes(GGLAdGroupName)
                      ? e.target.value
                      : GGLAdGroupName + e.target.value,
                  );
                }}
                helperText={GGLAdGroupName}
                disabled={isABTest ? true : false}
              />
            </Box>
            <Box
              sx={{
                display: `none`,
                marginY: 2,
                maxWidth: 500,
              }}
            >
              <Typography>Add to existing Google Campaign?</Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Campaigns</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedExistingCampaign}
                  label="Age"
                  onChange={handleExistingCampaignChange}
                >
                  <MenuItem value={10}>aiADZ-Search-13</MenuItem>
                  <MenuItem value={20}>aiADZ-Search-12</MenuItem>
                  <MenuItem value={30}>aiADZ-Search-11</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: `none`,
              }}
            >
              <Typography
                color={`primary`}
                sx={{
                  fontWeight: 600,
                }}
              >
                Google Campaign Configuration
              </Typography>

              <Box
                sx={{
                  marginY: 2,
                }}
              >
                <TextField
                  label="Campaign Name"
                  variant="outlined"
                  sx={{
                    minWidth: 500,
                  }}
                  value={GGLCampaignName}
                  onChange={(e) => {
                    setGGLCampaignName(e.target.value);
                  }}
                />
              </Box>

              <Box
                sx={{
                  marginY: 2,
                }}
              >
                <TextField
                  label="Ad Group Name"
                  variant="outlined"
                  sx={{
                    minWidth: 500,
                  }}
                  value={GGLAdGroupName}
                  onChange={(e) => {
                    setGGLAdGroupName(e.target.value);
                  }}
                />
              </Box>

              <Box
                sx={{
                  marginY: 2,
                }}
              >
                <TextField
                  label="Ad Name"
                  variant="outlined"
                  sx={{
                    minWidth: 500,
                  }}
                  value={GGLAdName}
                  onChange={(e) => {
                    setGGLAdName(e.target.value);
                  }}
                />
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
      {bidStratModal}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '1rem',
          padding: '1rem',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '50%',
          }}
        >
          <LinearProgressBarWithLabel
            value={((pages.indexOf(currentPage) + 1) / pages.length) * 100}
          />
        </Box>
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            justifyContent: 'end',
            gap: '0.5rem',
            alignItems: 'end',
          }}
        >
          <Button variant="outlined" onClick={cancelCreation} sx={{}}>
            Cancel
          </Button>

          <Button
            variant="outlined"
            sx={{ display: currentPage === pages[0] ? 'none' : 'block' }}
            onClick={() => {
              const getIndex = pages.indexOf(currentPage);
              const prevPage = pages[getIndex - 1];
              setCurrentPage(prevPage);
            }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            sx={{ display: currentPage === 'Preview' ? 'none' : 'block' }}
            onClick={handleNextBtnClick}
          >
            Next
          </Button>

          <Button
            variant="contained"
            sx={{
              display: currentPage === 'Preview' ? 'flex' : 'none',
            }}
            onClick={saveAd}
            startIcon={<RocketLaunchIcon />}
          >
            Go Live
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GGAdzCreation;
