import {
  Typography,
  Box,
  TextField,
  Button,
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { DEFAULT_COLOR_THEME, IS_DIY_ADZ } from '../../../../utils';
import { scrollBarGreen } from '../../../../utils/constants/google';
import { FloatingButton } from '../../../Buttons';
import { Close } from '@mui/icons-material';
import {
  CampaignFormFields,
  Brand,
  AdsAccountFormFields,
} from '../../../../types';
import {
  humanizeString,
  snakeCaseToHumanized,
} from '../../../../utils/stringModifier';
import FormHeader from '../../FormHeader';
import styles from '../../../../assets/styles/components/Modal/CampaignModal.module.scss';
import {
  fetchGoogleAdsAccount,
  fetchGoogleCampaigns,
} from '../../../../services/googleAds';
import {
  fetchAdsAccountByBrand,
  fetchBrandCampaigns,
} from '../../../../services/ads';
import { campaignProvider } from '../../../../redux/reducers/Campaigns';
import { arrayUniqueFilter } from '../../../../utils/arrayFormatter';
import { adsProvider } from '../../../../utils/constants/facebookAds';

interface AssetGoogleCampaignsProps {
  brand: Brand;
  defaultRefreshToken: string;
}

const AssetGoogleCampaigns: React.FC<AssetGoogleCampaignsProps> = ({
  brand,
  defaultRefreshToken,
}) => {
  // providerCampaigns: CampaignFormFields[];
  // brandCampaigns: CampaignFormFields[];
  // onSelectCampaign: (campaign: CampaignFormFields) => void;
  // onRemoveCampaign: (campaign: CampaignFormFields) => void;
  // provider: string;
  const [providerCampaigns, setProviderCampaigns] = useState<
    CampaignFormFields[]
  >([]);
  const [brandCampaigns, setBrandCampaigns] = useState<CampaignFormFields[]>(
    [],
  );
  const [campaigns, setCampaigns] = useState<CampaignFormFields[]>([]);
  const [connectedAccountIds, setConnectedAccountIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [adAccounts, setAdAccounts] = useState<AdsAccountFormFields[]>([]);
  const [googleAdAccounts, setGoogleAdAccounts] = useState<
    AdsAccountFormFields[]
  >([]);

  const onSelectCampaign = (campaign: CampaignFormFields) => {
    setBrandCampaigns([...brandCampaigns, campaign]);
  };
  const onRemoveCampaign = (campaign: CampaignFormFields) => {
    setBrandCampaigns(
      brandCampaigns.map((c) =>
        c.id === campaign.id ? { ...c, deleted: true } : c,
      ),
    );
  };

  const selectedOption = (o: any) => {
    return !!brandCampaigns.find((e: any) => e.id === o.id && !e.deleted);
  };

  const getCampaigns = async () => {
    try {
      setLoading(true);
      const campaigns = await fetchBrandCampaigns(brand?._id, 'google');

      setCampaigns(campaigns.data);
      setBrandCampaigns(campaigns.data);
      console.log(`$setBrandCampaigns`);
      console.log(campaigns);

      const accountIds = campaigns.data.map((obj: any) => obj.accountId);

      const filteredIds = arrayUniqueFilter(accountIds);

      setConnectedAccountIds(filteredIds);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getAdsAccounts = async () => {
    try {
      setLoading(true);

      const response = await fetchAdsAccountByBrand(brand?._id, 'Google');

      const temp: AdsAccountFormFields[] = await response?.data.filter(
        (data: any) => data.provider === adsProvider.GOOGLE,
      );

      setAdAccounts(temp);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getGoogleAdAccounts = async () => {
    try {
      setLoading(true);

      const response = await fetchGoogleAdsAccount(
        brand?._id,
        defaultRefreshToken,
      );

      setGoogleAdAccounts(response?.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getGoogleCampaigns = async () => {
    try {
      setLoading(true);
      const campaigns = await fetchGoogleCampaigns(
        brand?._id,
        defaultRefreshToken,
      );
      console.log(`$campaigns`);
      console.log(campaigns);
      setProviderCampaigns(campaigns?.data || []);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (brand) {
      getCampaigns();
      getAdsAccounts();
      getGoogleCampaigns();
      getGoogleAdAccounts();
    }
  }, []);

  return (
    <div className={styles.campaigns}>
      <FormHeader title={`Select Google Campaigns`} />

      <Autocomplete
        id="campaigns"
        multiple
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        options={providerCampaigns.sort(
          (a, b) => -b.name?.localeCompare(a.name),
        )}
        getOptionDisabled={(option) => selectedOption(option)}
        value={brandCampaigns}
        getOptionLabel={(option) => option?.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Campaigns"
            placeholder="Select your campaigns"
          />
        )}
        disableClearable
        onChange={async (e: any, value: any[], reason, detail) => {
          if (reason === 'selectOption') {
            onSelectCampaign(detail.option as CampaignFormFields);
          } else if (reason === 'removeOption') {
            onRemoveCampaign(detail.option as CampaignFormFields);
          }
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              disabled={!option.manual && IS_DIY_ADZ}
            />
          ))
        }
      />

      <Grid container spacing={1} mt={1}>
        {brandCampaigns?.map((campaign: CampaignFormFields) => {
          return campaign.deleted ? null : (
            <Grid item xs={12} sm={6} lg={3} key={campaign.id}>
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  subheader={campaign.name}
                  subheaderTypographyProps={{
                    sx: {
                      fontWeight: 'bold',
                      color: 'black',
                      textAlign: 'center',
                    },
                  }}
                />

                <CardContent sx={{ paddingTop: 0 }}>
                  <Typography variant="body2" fontWeight="bold" component="div">
                    ID:
                    <Typography variant="body2" display="inline" ml={1}>
                      {campaign.id}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" fontWeight="bold" component="div">
                    Status:
                    <Typography variant="body2" display="inline" ml={1}>
                      {humanizeString(campaign.status)}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" fontWeight="bold" component="div">
                    Objective:
                    <Typography variant="body2" display="inline" ml={1}>
                      {humanizeString(snakeCaseToHumanized(campaign.objective))}
                    </Typography>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default AssetGoogleCampaigns;
