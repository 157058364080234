import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';

interface GoogleTemplateSummaryProps {
  template: any;
  ad?: any;
  templateType?: string;
}

const GoogleTemplateSummary: React.FC<GoogleTemplateSummaryProps> = ({
  template,
  ad = null,
  templateType = 'search',
}) => {
  console.log('template', template);
  useEffect(() => {
    console.log('ad', ad);
    console.log('template', template);
  }, []);
  return (
    <>
      <Typography sx={{ fontWeight: 600, fontSize: `1.2rem` }}>
        Summary:
      </Typography>
      <Box sx={{ marginBottom: '10px' }}>
        {ad ? (
          <>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
              }}
            >
              Budget:
            </Typography>
            <Typography
              sx={{
                fontSize: '1rem',
                color: '#000000',
              }}
            >
              ${ad.budget}
            </Typography>

            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
              }}
            >
              Location Set:
            </Typography>
            <Typography
              sx={{
                fontSize: '1rem',
                color: '#000000',
              }}
            >
              {ad.locationSet?.name} - {ad.locationSet?.locations.length}{' '}
              Locations
            </Typography>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: '#000000',
                fontStyle: `italic`,
              }}
            >
              {ad.locationSet?.locations.map((loc: any) => loc.name).join(', ')}
            </Typography>
          </>
        ) : null}

        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: DEFAULT_COLOR_THEME,
          }}
        >
          Keywords:
        </Typography>
        {template.keywords ? (
          <Box sx={{ paddingLeft: `10px` }}>
            {template.keywords.map((key: any) => (
              <Box>
                •{' '}
                <Typography
                  sx={{
                    fontWeight: '200',
                    display: 'inline',
                    fontStyle: 'italic',
                  }}
                >
                  {`${key.exact ? `EXACT | ` : ''}`}
                  {`${key.phrase ? `PHRASE | ` : ''}`}
                  {`${key.broad ? `BROAD | ` : ''}`}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '600',
                    display: 'inline',
                  }}
                >{`${key.keyword}`}</Typography>
              </Box>
            ))}
          </Box>
        ) : null}
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: DEFAULT_COLOR_THEME,
            marginBottom: '10px',
          }}
        >
          Phone Number:{` `}
          <Typography
            sx={{
              fontWeight: '200',
              display: 'inline',
            }}
          >
            {template.phone_number}
          </Typography>
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: DEFAULT_COLOR_THEME,
            marginBottom: '10px',
          }}
        >
          Final URL:{` `}
          <Typography
            sx={{
              fontWeight: '200',
              display: 'inline',
            }}
          >
            {template.final_url}
          </Typography>
        </Typography>
      </Box>

      <Box
        sx={{
          marginBottom: '10px',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
            }}
          >
            Headlines:
          </Typography>
        </Box>

        <Box sx={{ paddingLeft: `10px` }}>
          {template.headlines.map((h: string) => (
            <Box>• {h}</Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          marginBottom: '10px',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
            }}
          >
            Descriptions:
          </Typography>
        </Box>

        <Box sx={{ paddingLeft: `10px` }}>
          {template.descriptions.map((d: string) => (
            <Box>• {d}</Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          marginBottom: '10px',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
            }}
          >
            Callouts:
          </Typography>
        </Box>

        <Box sx={{ paddingLeft: `10px` }}>
          {template.callouts.map((c: string) => (
            <Box>• {c}</Box>
          ))}
        </Box>
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: DEFAULT_COLOR_THEME,
            marginBottom: '10px',
          }}
        >
          Site Links
          <Box
            sx={{
              fontWeight: '200',
              display: 'inline',
              paddingLeft: `10px`,
            }}
          >
            {template.sitelinks.map((s: any) => (
              <Box
                sx={{
                  fontSize: '1rem',
                  color: `#000000`,
                }}
              >
                • {s.headline} - {s.description1} - {s.description2} -{' '}
                {s.finalUrl}
              </Box>
            ))}
          </Box>
        </Typography>
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: DEFAULT_COLOR_THEME,
            marginBottom: '10px',
          }}
        >
          Business Logo
        </Typography>

        <Box
          sx={{
            display: `flex`,
            flexWrap: `wrap`,
            gap: `0.5rem`,
          }}
        >
          {template.business_logo?.url ? (
            <Box
              sx={{
                width: '60px',
                height: '60px',
                borderRadius: '100%',
                border: `1px solid #333333`,
                padding: 0,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={template.business_logo?.url}
                alt={template.business_logo?.url}
                style={{
                  width: '50px',
                  height: 'auto',
                }}
              />
            </Box>
          ) : (
            <Typography sx={{ paddingLeft: `10px`, fontWeight: 200 }}>
              No business logo provided
            </Typography>
          )}
        </Box>
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: DEFAULT_COLOR_THEME,
            marginBottom: '10px',
          }}
        >
          Images
        </Typography>

        <Box
          sx={{
            display: `flex`,
            flexWrap: `wrap`,
            gap: `0.5rem`,
          }}
        >
          {template.images.map((img: any) => (
            <Box
              sx={{
                width: '100px',
              }}
            >
              <img
                src={img.url}
                alt={img.url}
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default GoogleTemplateSummary;
