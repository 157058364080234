import { defaultProps } from '../../../utils/helpers/TableHelpers';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { numberShortener } from '../../../utils/numberFormatter';

export const lookalikeAudienceColumns = (isMobile: boolean, share: boolean) => {
  let columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 300),
      field: 'name',
      headerClassName: styles.header,
      headerName: 'Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { name } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{name}</Typography>}
            disableInteractive
          >
            <div className={styles.name}>{name}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'estimatedReach',
      headerClassName: styles.header,
      headerName: 'Estimated Reach',
      sortingOrder: ['desc', 'asc'],
      width: 150,
      valueGetter: (_, row) => row.approximate_count_lower_bound,
      renderCell: (params: any) => {
        const { approximate_count_lower_bound, approximate_count_upper_bound } =
          params.row;
        const lowerBound = numberShortener(approximate_count_lower_bound);
        const upperBound = numberShortener(approximate_count_upper_bound);

        return (
          <Tooltip
            title={
              <Typography variant="body2">{`${lowerBound}-${upperBound}`}</Typography>
            }
            disableInteractive
          >
            <div className={styles.name}>{`${lowerBound}-${upperBound}`}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'country',
      headerClassName: styles.header,
      headerName: 'Country',
      sortingOrder: ['desc', 'asc'],
      width: 80,
      valueGetter: (_, row) => row.lookalike_spec.country,
      renderCell: (params: any) => {
        const { lookalike_spec } = params.row;

        return (
          <Tooltip
            title={
              <Typography variant="body2">{lookalike_spec.country}</Typography>
            }
            disableInteractive
          >
            <div className={styles.name}>{lookalike_spec.country}</div>
          </Tooltip>
        );
      },
    },
  ];

  if (!share) {
    columns = [
      ...columns,
      {
        field: 'operationStatus',
        headerClassName: styles.header,
        headerName: 'Operation Status',
        sortingOrder: ['desc', 'asc'],
        width: 200,
        valueGetter: (_, row) => row.operation_status.description,
        renderCell: (params: any) => {
          const { operation_status } = params.row;

          return (
            <Tooltip
              title={
                <Typography variant="body2">
                  {operation_status.description}
                </Typography>
              }
              disableInteractive
            >
              <div className={styles.name}>{operation_status.description}</div>
            </Tooltip>
          );
        },
      },
      {
        ...defaultProps(isMobile, 300),
        field: 'deliveryStatus',
        headerClassName: styles.header,
        headerName: 'Delivery Status',
        sortingOrder: ['desc', 'asc'],
        valueGetter: (_, row) => row.delivery_status.description,
        renderCell: (params: any) => {
          const { delivery_status } = params.row;

          return (
            <Tooltip
              title={
                <Typography variant="body2">
                  {delivery_status.description}
                </Typography>
              }
              disableInteractive
            >
              <div className={styles.name}>{delivery_status.description}</div>
            </Tooltip>
          );
        },
      },
    ];
  }

  return columns;
};
