import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  ListItem,
  List,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';

interface GGLTemplatesDisplayKeywordsProps {
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
}

const GGLTemplatesDisplayKeywords: React.FC<
  GGLTemplatesDisplayKeywordsProps
> = ({ submitForm, templateForm, setIsDirty }) => {
  const [keywordsInput, setKeywordsInput] = useState('');
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    if (templateForm) {
      setKeywordsInput(templateForm.join('\n'));
      setKeywords(templateForm);
      submitForm(templateForm);
    }
  }, []);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: 4,
      }}
    >
      <TextField
        label="Keywords"
        variant="outlined"
        value={keywordsInput}
        sx={{
          width: '100%',
          maxWidth: 500,
          ...scrollBarGreen,
        }}
        onChange={(e) => {
          setKeywordsInput(e.target.value);
          setKeywords(
            e.target.value
              .split('\n')
              .map((keyword) => keyword.trim().toLowerCase())
              .filter((keyword) => keyword.trim() !== ''),
          );
          submitForm(
            e.target.value
              .split('\n')
              .map((keyword) => keyword.trim().toLowerCase())
              .filter((keyword) => keyword.trim() !== ''),
          );
        }}
        multiline
        rows={20}
      />
      <Box sx={{}}>
        {keywords.length > 0 ? (
          <>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: DEFAULT_COLOR_THEME }}
            >
              Total Keywords: [{keywords.length}]
            </Typography>
            <Box sx={{ columns: 2 }}>
              <List>
                {keywords.map((keyword, index) => {
                  return (
                    <ListItem
                      disableGutters
                      key={index}
                      sx={{
                        paddingLeft: 2,
                        paddingY: 0,
                      }}
                    >
                      • {keyword}
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default GGLTemplatesDisplayKeywords;
