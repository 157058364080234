import { Box, Typography, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import { IoMdCloseCircle } from 'react-icons/io';
import {
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { FaCheck } from 'react-icons/fa';
import AssetGoogleImages from '../../Assetz/Google/GGLAssetsImages';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ImageDropZone from '../../../Buttons/ImageDropZone';
import GoogleImageCard from '../../../Cards/Google/ImageCard';
import {
  fetchGoogleAccImages,
  fetchGoogleAssetsByBrand,
  uploadGoogleAssetImage,
} from '../../../../services/googleAds';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const blobToBase64 = (blob: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface GoogleTemplatesImagesProps {
  brandId: string;
  submitForm: any;
  templateForm: any;
  refetchImages: any;
  setIsDirty: any;
  templateType: 'search' | 'display';
}

const GoogleTemplatesImages: React.FC<GoogleTemplatesImagesProps> = ({
  brandId,
  refetchImages,
  submitForm,
  templateForm,
  setIsDirty,
  templateType,
}) => {
  const [images, setImages] = useState([]);
  const maxItems = googleResourceLimits.images[templateType];
  const [imagesCollection, setImagesCollection] = useState([]);
  const [collectionLoaded, setCollectionLoaded] = useState(false);
  let toselect: string[] = [];

  const fetchGoogleAssets = async (fromGoogle: []) => {
    const { data } = await fetchGoogleAssetsByBrand(brandId);
    const images = data.gacc?.images || [];
    const col = [...images, ...fromGoogle];
    //console.log(`col`, col);
    setImagesCollection(col);
    setCollectionLoaded(true);
    if (toselect) {
      console.log(`toselect`, toselect);
      col.forEach((img) => {
        console.log(`img`, img.public_id);
        if (img.public_id) {
          toselect.forEach((id) => {
            if (id === img.public_id) {
              selectThisImage(img);
            }
          });
        }
      });
    }
  };

  const addImages = async (newImages: any) => {
    if (newImages.length === 0) return;
    //check if newImages is an array
    if (!Array.isArray(newImages)) {
      newImages = Array.from(newImages);
    }
    console.log(`newImages`, newImages);
    //convert to base64
    const base64Images = await Promise.all(
      newImages.map(async (image: any) => {
        const base64 = await blobToBase64(image).then((res) => {
          //get image height and width
          let height = 0;
          let width = 0;
          const img = new Image();
          img.src = res as string;

          return new Promise((resolve) => {
            img.onload = () => {
              height = img.height;
              width = img.width;
              resolve({
                name: image.name,
                size: image.size,
                src: res,
                dimensions: {
                  height,
                  width,
                },

                // url: 'https://res.cloudinary.com/saleschatz/image/upload/v1734114182/user_up…',
                // height: 300,
                // width: 300,
                // asset_id: '77a9d0d6333c9f38bc886c6b7e1c4cde',
                // public_id:
                //   'user_uploaded/google_assets/668fc16ba30eecc71c14308c/Coffee.png_173411…',
                // resource_type: 'image',
              });
            };
          });
        });

        return base64;
      }),
    );

    const cloudinaryImages = base64Images.map(async (image: any) => {
      const res = await uploadGoogleAssetImage({
        brandId: brandId,
        resourceName: image.name + `_${Date.now()}`,
        image: image.src,
      });

      return res;
    });

    const newImagesCollection = await Promise.all(cloudinaryImages);
    console.log(`newImagesCollection`, newImagesCollection);
    toselect = newImagesCollection.map((img) => img.data.public_id);
    getImagesFromGoogle();
  };

  const getImagesFromGoogle = async () => {
    fetchGoogleAccImages({
      brandId,
    }).then((res) => {
      console.log(res);
      fetchGoogleAssets(
        res.results
          .map((r: any) => {
            return {
              url: r?.asset?.imageAsset?.fullSize?.url,
              height: parseInt(r?.asset?.imageAsset?.fullSize?.heightPixels),
              width: parseInt(r?.asset?.imageAsset?.fullSize?.widthPixels),
              resource_type: 'image',
              asset_id: r?.asset?.resourceName,
              resourceName: r?.asset?.resourceName,
            };
          })
          .filter((v: any) => v.url !== null),
      );
    });
  };

  useEffect(() => {
    if (templateForm) {
      console.log(`templateForm`, templateForm);
      setImages(templateForm);
    }

    getImagesFromGoogle();
  }, []);

  const selectThisImage = (image: any) => {
    if (images?.map((img) => img.asset_id).includes(image.asset_id)) {
      setImages(images.filter((img) => img.asset_id !== image.asset_id));
      submitForm(images.filter((img) => img.asset_id !== image.asset_id));
      return;
    }

    if (images.length < maxItems) {
      setImages([...images, image]);
      submitForm([...images, image]);
    } else {
      alert(`You can only select ${maxItems} images`);
    }
  };

  const isStillLoading = (
    <>
      <Box
        sx={{
          height: `150px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: DEFAULT_COLOR_THEME,
          fontSize: '1.2rem',
          fontWeight: 600,
        }}
      >
        <CircularProgress color="inherit" size={80} />
      </Box>
    </>
  );

  return (
    <Box
      sx={{
        justifyContent: `space-around`,
        alignItems: `start`,
        overflowY: `auto`,
        ...scrollBarGreen,
      }}
    >
      <Box sx={{ marginBottom: 2, paddingX: 2 }}>
        <Typography variant="h6" color={DEFAULT_COLOR_THEME} fontWeight={600}>
          Selected Images: {images.length} images
        </Typography>
      </Box>
      <Box
        sx={{
          border: `2px solid ${DEFAULT_COLOR_THEME}`,
          borderRadius: 4,
          minHeight: 270,
          marginX: 2,
          marginTop: 2,
          padding: 2,
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        {images?.map((image, i) => (
          <GoogleImageCard
            key={i}
            imgData={image}
            selected={true}
            onClick={() => {
              selectThisImage(image);
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          marginY: 2,
          display: 'flex',
          justifyContent: `space-between`,
          alignItems: `center`,
          paddingX: 2,
        }}
      >
        <Typography variant="h6" color={DEFAULT_COLOR_THEME} fontWeight={600}>
          Asset Library: {imagesCollection.length} images
        </Typography>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload files
          <VisuallyHiddenInput
            type="file"
            onChange={(event) => addImages(event.target.files)}
            accept="image/jpeg,image/jpg,image/png,image/gif"
            multiple
          />
        </Button>
      </Box>
      <Box
        sx={{
          border: `2px solid ${DEFAULT_COLOR_THEME}`,
          borderRadius: 4,
          minHeight: 200,
          marginX: 2,
          padding: 1,
          display: 'flex',
          gap: 1,
          flexWrap: 'wrap',
          marginBottom: 5,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {collectionLoaded ? (
          <Box sx={{ height: 275, width: 224, paddingTop: 1, paddingX: 1 }}>
            <ImageDropZone
              setImages={addImages}
              sendRejects={() => {}}
              validatorFunc={() => {}}
            />
          </Box>
        ) : (
          isStillLoading
        )}
        {imagesCollection.map((image, i) => (
          <GoogleImageCard
            key={i}
            imgData={image}
            selected={images.find((img) => img.asset_id === image.asset_id)}
            onClick={() => {
              selectThisImage(image);
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default GoogleTemplatesImages;
