import React, { useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import {
  Brand,
  GoogleAdsMetrics,
  BrandFacebookReport,
  BrandFacebookReportData,
  DashboardData,
} from '../../types';
import {
  getBrandCampaignAnalytics,
  getGoogleCampaignMetrics,
  getGoogleCampaignMetricsNew,
} from '../../services/report';
import styles from '../../assets/styles/pages/Reports.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setEndRange, setStartRange, toggleAlert } from '../../redux/actions';
import { adsProvider } from '../../utils/constants/facebookAds';
import PageHeader from '../../components/PageHeader';
import { AuthContext } from '../../context';
import { IS_DIY_ADZ } from '../../utils';
import { buildRefreshToken } from '../../utils/helpers/DefaultTokenBuilder';
import CircularLoading from '../../components/CircularLoading';
import DateRangePicker from '../../components/DateAndTimePickers/DateRangePicker';
import GoogleCampaignMetrics from '../../components/Reporting/GoogleCampaignMetrics';
import { BusinessType } from '../../utils/helpers/BrandHelpers';
import { DrawerMenu } from '../../components/Menu';
import FacebookAdDetails from '../../components/Details/Ads/FacebookAdDetails';
import ChatzBudgets from '../Budgets/ChatzBudgetReport';
//new
import GoogleDirectCampaignMetrics from '../../components/Reporting/GoogleDirectCampaignMetrics';
import FacebookCampaignReportTable from '../../components/Tables/ReportList/FacebookCampaignReportTable';
import { NoData } from '../NoContent';
import {
  TbHandClick,
  TbEyeDollar,
  TbUserDollar,
  TbUsersGroup,
  TbEye,
} from 'react-icons/tb';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import DashboardItem from '../../components/Cards/Billing/DashboardItem';
import { RiUserFollowLine } from 'react-icons/ri';

const Reports: React.FC = () => {
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const campaignProvider: string = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );
  const startRange = useSelector((state: any) => state.startRange.date);
  const endRange = useSelector((state: any) => state.endRange.date);
  const dispatch = useDispatch();
  const { state } = useContext(AuthContext);
  const refreshToken = state.authUser?.refreshToken;
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [analyticsLoading, setAnalyticsLoading] = useState<boolean>(false);
  const [googleCampaigns, setGoogleCampaigns] =
    useState<GoogleAdsMetrics>(null);
  const [selectedAd, setSelectedAd] = useState<string>(null);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [defaultRefreshToken, setDefaultRefreshToken] = useState<string>('');
  const isFacebook = campaignProvider === adsProvider.FACEBOOK;
  const isGoogle = campaignProvider === adsProvider.GOOGLE;
  const [facebookReports, setFacebookReports] =
    useState<BrandFacebookReport>(null);
  const [dashboardData, setDashboardData] = useState<DashboardData[]>([
    {
      value: 0,
      label: 'Clicks',
      icon: <TbHandClick size={30} />,
      isCurrency: false,
    },
    {
      value: 0,
      label: 'CPC',
      icon: <AdsClickIcon sx={{ fontSize: '30px' }} />,
      isCurrency: true,
    },
    {
      value: 0,
      label: 'Impressions',
      icon: <TbEye size={30} />,
      isCurrency: false,
    },
    {
      value: 0,
      label: 'Leads',
      icon: <TbUsersGroup size={30} />,
      isCurrency: false,
    },
    {
      value: 0,
      label: 'CPM',
      icon: <TbEyeDollar size={30} />,
      isCurrency: true,
    },
    {
      value: 0,
      label: 'CPL',
      icon: <TbUserDollar size={30} />,
      isCurrency: true,
    },
    {
      value: 0,
      label: 'Spend',
      icon: <AttachMoneyOutlinedIcon sx={{ fontSize: '30px' }} />,
      isCurrency: true,
    },
  ]);

  const isFrandev: boolean =
    (location || brand)?.businessType === BusinessType.FRANDEV;
  //new
  const [directData, setDirectData] = useState<any>(null);

  useEffect(() => {
    handleBuildProviderTokens();
  }, []);

  useEffect(() => {
    if (
      (location || brand) &&
      campaignProvider &&
      startRange &&
      endRange &&
      defaultRefreshToken
    ) {
      resetData();
      const delayDebounceFn = setTimeout(() => {
        fetchBrandAnalytics();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [
    startRange,
    endRange,
    location,
    brand,
    campaignProvider,
    defaultRefreshToken,
  ]);

  const handleBuildProviderTokens = () => {
    buildRefreshToken(refreshToken, setDefaultRefreshToken);
  };

  const resetData = () => {
    setGoogleCampaigns(null);
    setFacebookReports(null);
    setDirectData(null);
  };

  const resetGoogleReports = async () => {
    setAnalyticsLoading(true);
    setDirectData(null);
    await fetchGoogleReports();
    setAnalyticsLoading(false);
  };

  const fetchGoogleReports = async () => {
    const { data } = await getGoogleCampaignMetricsNew({
      brandId: (location || brand)._id,
      refreshToken: defaultRefreshToken,
      fromDate: startRange.toISOString(),
      toDate: endRange.toISOString(),
      campaignId: '',
    });

    setDirectData(data);

    return data;
  };

  const fetchBrandAnalytics = async () => {
    try {
      setAnalyticsLoading(true);

      if (isFacebook) {
        const response = await getBrandCampaignAnalytics(
          (location || brand)?._id,
          startRange,
          endRange,
        );

        setFacebookReports(response.data);
        buildFacebookDashboardItems(response.data);
      }

      if (isGoogle && !IS_DIY_ADZ) {
        const data = await getGoogleCampaignMetrics(
          (location || brand)?._id,
          defaultRefreshToken,
          startRange,
          endRange,
        );
        if (data?.errors?.length > 0) {
          let msg: string = '';
          data.errors.forEach((error: any) => {
            msg += error;
          });
          dispatch(
            toggleAlert({
              toggle: true,
              message: msg,
              type: 'error',
            }),
          );
        } else {
          setGoogleCampaigns(data);
        }
      }

      if (isGoogle && IS_DIY_ADZ) {
        await fetchGoogleReports();
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setAnalyticsLoading(false);
    }
  };

  const buildFacebookDashboardItems = (reports: BrandFacebookReport) => {
    let temp: DashboardData[] = [];
    let clicks = 0;
    let impressions = 0;
    let leadCount = 0;
    let spend = 0;
    let qLeads = 0;

    Object.keys(reports).forEach((key: any) => {
      reports[key].forEach((value: BrandFacebookReportData) => {
        if (value.level === 'objective') {
          clicks += value.clicks;
          impressions += value.impressions;
          leadCount += value.leads;
          spend += value.spend;
          qLeads += value.qualifiedLeads;
        }
      });
    });

    qLeads = qLeads > leadCount ? leadCount : qLeads;

    temp = [
      {
        value: clicks,
        label: 'Clicks',
        icon: <TbHandClick size={30} />,
        isCurrency: false,
      },
      {
        value: clicks > 0 ? spend / clicks : 0,
        label: 'CPC',
        icon: <AdsClickIcon sx={{ fontSize: '30px' }} />,
        isCurrency: true,
      },
      {
        value: impressions,
        label: 'Impressions',
        icon: <TbEye size={30} />,
        isCurrency: false,
      },
      {
        value: leadCount,
        label: 'Leads',
        icon: <TbUsersGroup size={30} />,
        isCurrency: false,
      },
      {
        value: qLeads > leadCount ? leadCount : qLeads,
        label: 'QLeads',
        icon: <RiUserFollowLine size={30} />,
        isCurrency: false,
      },
      {
        value: impressions > 0 ? (spend / impressions) * 1000 : 0,
        label: 'CPM',
        icon: <TbEyeDollar size={30} />,
        isCurrency: true,
      },
      {
        value: leadCount > 0 ? spend / leadCount : 0,
        label: 'CPL',
        icon: <TbUserDollar size={30} />,
        isCurrency: true,
      },
      {
        value: qLeads > 0 ? spend / qLeads : 0,
        label: 'CPQL',
        icon: <TbUserDollar size={30} />,
        isCurrency: true,
      },
      {
        value: spend,
        label: 'Spend',
        icon: <AttachMoneyOutlinedIcon sx={{ fontSize: '30px' }} />,
        isCurrency: true,
      },
    ];

    setDashboardData(temp);
  };

  const handleOpenPreview = async (adId: string) => {
    setSelectedAd(adId);
    handleOpenAdPreviewModal();
  };

  const handleChangeDateRange = (dates: Moment[] | []) => {
    dispatch(setStartRange(dates[0]));
    dispatch(setEndRange(dates[1]));
  };

  const handleOpenAdPreviewModal = () => {
    setOpenPreviewModal(true);
  };

  const handleCloseAdPreviewModal = () => {
    setOpenPreviewModal(false);
  };

  return (
    <>
      {IS_DIY_ADZ &&
      (location || brand)?.__type === 'brand' &&
      (location || brand)?.allowLocations ? (
        <ChatzBudgets />
      ) : (
        <div className={styles.report}>
          <CircularLoading loading={analyticsLoading} />

          <div className={styles.base}>
            <div className={styles.header}>
              <PageHeader title={IS_DIY_ADZ ? 'KPIz' : 'Reports'} />

              <div className={styles.controls}>
                <DateRangePicker
                  toDate={endRange}
                  fromDate={startRange}
                  onChange={handleChangeDateRange}
                  maxDate={moment()}
                  separator="-"
                />
              </div>
            </div>

            {isFacebook ? (
              <Grid
                container
                spacing={1}
                justifyContent="center"
                columns={14}
                mb={2}
              >
                {dashboardData.map((data: DashboardData) => {
                  return (
                    <Grid item xs={7} sm={'auto'} key={data.label}>
                      <DashboardItem
                        endingValue={data.value}
                        label={data.label}
                        isCurrency={data.isCurrency}
                        decimals={data.isCurrency ? 2 : 0}
                        icon={data.icon}
                        width="170px"
                        isMobile={xsOnly}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}

            {facebookReports?.leads?.length > 0 ||
            facebookReports?.traffic?.length > 0 ||
            googleCampaigns?.data.length > 0 ||
            directData ? (
              <>
                {facebookReports?.leads?.length > 0 && isFacebook ? (
                  <div>
                    <FacebookCampaignReportTable
                      reportData={facebookReports?.leads || []}
                      onOpenPreview={handleOpenPreview}
                      isLeads
                      isFranDev={isFrandev}
                    />
                  </div>
                ) : null}

                {facebookReports?.traffic?.length > 0 && isFacebook ? (
                  <div>
                    <FacebookCampaignReportTable
                      reportData={facebookReports?.traffic || []}
                      onOpenPreview={handleOpenPreview}
                    />
                  </div>
                ) : null}

                {isGoogle && !IS_DIY_ADZ && googleCampaigns?.data.length > 0 ? (
                  <GoogleCampaignMetrics
                    brand={location || brand}
                    fromDate={startRange}
                    toDate={endRange}
                    data={googleCampaigns?.data || []}
                    refreshToken={defaultRefreshToken}
                  />
                ) : isGoogle && IS_DIY_ADZ && !analyticsLoading ? (
                  <GoogleDirectCampaignMetrics
                    refreshToken={defaultRefreshToken}
                    allowCheckboxes={
                      state.role === 'admin' || state.role === 'super-admin'
                        ? true
                        : state.capabilities.createTemplate
                        ? true
                        : false
                    }
                    directData={directData}
                    brandId={(location || brand)?._id}
                    brand={(location || brand).brand}
                    fromDate={moment(startRange).format('MMM DD, YYYY')}
                    toDate={moment(endRange).format('MMM DD, YYYY')}
                    reload={resetGoogleReports}
                  />
                ) : null}
              </>
            ) : (
              <NoData />
            )}

            <DrawerMenu
              anchor="right"
              open={openPreviewModal}
              onClose={handleCloseAdPreviewModal}
            >
              <FacebookAdDetails
                adId={selectedAd}
                brand={location || brand}
                onClose={handleCloseAdPreviewModal}
                isMobile={xsOnly}
              />
            </DrawerMenu>
          </div>
        </div>
      )}
    </>
  );
};

export default Reports;
