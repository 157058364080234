import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import { scrollBarGreen } from '../../../../utils/constants/google';
import { IoMdCloseCircle, IoIosCheckmarkCircle } from 'react-icons/io';
import {
  addToAssetGAcc,
  fetchGoogleAccYoutubeVids,
  fetchGoogleAssetsByBrand,
  fetchYTCheck,
  removeFromAssetGAcc,
} from '../../../../services/googleAds';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';
import { FcGoogle } from 'react-icons/fc';

const s = {
  card: {
    border: `4px solid ${DEFAULT_COLOR_THEME}`,
    borderRadius: 4,
    paddingY: 1,
    paddingX: 2,
    width: '700px',
    overflow: 'hidden',
    position: 'relative',
  },
  floatingClose: {
    position: 'absolute',
    right: 0,
    top: 2,
    backgroundColor: 'transparent',
    border: 'none',
    minWidth: 0,
    borderRadius: `100%`,
  },
  input: {
    background: `#f0f0f0`,
    position: 'relative',
    paddingY: 1,
    paddingX: 2,
    borderRadius: 4,
    marginBottom: 1,
    whiteSpace: `nowrap`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
  },
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: 2,
  },
  img: {
    borderRadius: 4,
  },
  title: {
    fontWeight: 'bold',
    color: DEFAULT_COLOR_THEME,
    fontSize: '1.2rem',
    textAlign: 'justify',
    width: '400px',
    whiteSpace: `nowrap`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
  },
  url: {
    color: `#333333`,
    textDecoration: `underline`,
    cursor: `pointer`,
    fontStyle: 'italic',
    textAlign: 'justify',
    width: '400px',
    whiteSpace: `nowrap`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
  },
  description: {
    color: `#222222`,
    textAlign: 'left',
    width: '400px',

    display: `-webkit-box`,
    WebkitLineClamp: 3,
    WebkitBoxOrient: `vertical`,
    overflow: `hidden`,
  },
};

interface GoogleTemplatesVideosProps {
  brandId: string;
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
}

const GoogleTemplatesVideos: React.FC<GoogleTemplatesVideosProps> = ({
  brandId,
  submitForm,
  templateForm,
  setIsDirty,
}) => {
  const dispatch = useDispatch();
  const [videoInput, setVideoInput] = useState('');
  const [videolist, setVideolist] = useState([]);
  const [videoCollection, setVideoCollection] = useState([]);
  const [collectionLoading, setCollectionLoading] = useState(true);

  useEffect(() => {
    if (templateForm) {
      //setVideoInput(templateForm.join('\n'));

      setVideolist(templateForm);
    }
    fetchGoogleAssets();
    //checkLink(existingVideos);
  }, []);

  const fetchGoogleAssets = async () => {
    const { data } = await fetchGoogleAssetsByBrand(brandId);
    getImagesFromGoogle(data.gacc?.videos || []);
  };

  const getImagesFromGoogle = async (vids: string[]) => {
    fetchGoogleAccYoutubeVids({
      brandId,
    }).then((res) => {
      const vidcol = [
        ...vids,
        ...(res?.results?.map(
          (item: any) => item.asset.youtubeVideoAsset.youtubeVideoId,
        ) || []),
      ];
      checkLink(vidcol);
    });
  };

  const removeVideo = async (input: string) => {
    removeFromAssetGAcc({
      brandId,
      video: input,
    });
    setVideolist(videolist.filter((item) => item.input !== input));
    submitForm(videolist.filter((item) => item.input !== input));
    setVideoCollection(videoCollection.filter((item) => item.input !== input));
  };

  const getVidRss = async (link: string) => {
    const { data } = await fetchYTCheck(link);
    if (data && data.check) {
      const vidrss = {
        input: link.includes('.com') ? link : `https://youtu.be/${link}`,
        google: link.includes('.com') ? false : true,
        url: '',
        title: '',
        image: '',
        description: 'No Description Available',
        ...data.check
          .filter((item: any) =>
            ['og:url', 'og:title', 'og:image', 'og:description'].includes(
              item.property,
            ),
          )
          .reduce(
            (acc: any, meta: any) => ({
              ...acc,
              [meta.property.slice(3)]: meta.content,
            }),
            {},
          ),
      };
      if (!vidrss?.url || !vidrss?.title || !vidrss?.image) {
        return null;
      }
      return vidrss;
    }
    return null;
  };

  const checkLink = async (
    link: string | string[],
    select: boolean = true,
    isGoogle: boolean = false,
  ) => {
    if (typeof link === 'string') {
      const newlink = Array.isArray(link)
        ? link
        : link.includes(' ')
        ? link.split(' ').filter((item) => item.trim() !== '')[0]
        : link;
      console.log(`newlink`);
      console.log(newlink);
      const vidrss = await getVidRss(link);
      try {
        if (!vidrss || !vidrss?.url?.includes('youtube.com')) {
          throw new Error('Invalid Video');
        }
        const existing = videoCollection.find(
          (item) => item?.url === vidrss?.url,
        );
        if (!existing) {
          setVideoCollection([vidrss, ...videoCollection]);
          //function to add to gacc videos
          addToAssetGAcc({
            brandId,
            video: vidrss.input,
          });
        }
        const existingVid = videolist.find((item) => item?.url === vidrss?.url);
        if (select && !existingVid) {
          setVideolist([...videolist, { ...vidrss, google: isGoogle }]);
          submitForm([...videolist, { ...vidrss, google: isGoogle }]);
        }
        if (select && existingVid) {
          setVideolist(videolist.filter((item) => item?.url !== vidrss?.url));
          submitForm(videolist.filter((item) => item?.url !== vidrss?.url));
        }
        setVideoInput('');
      } catch (e) {
        dispatch(
          toggleAlert({
            toggle: true,
            message: 'Invalid Video, please recheck the link',
            type: 'error',
          }),
        );
      }
    } else {
      const vids = await Promise.all(
        link.map(async (link) => {
          const vidrss = await getVidRss(link);
          return vidrss;
        }),
      );
      setVideoCollection(vids);
      setCollectionLoading(false);
    }
  };

  const isStillLoading = (
    <>
      <Box
        sx={{
          height: `150px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: DEFAULT_COLOR_THEME,
          fontSize: '1.2rem',
          fontWeight: 600,
        }}
      >
        <CircularProgress color="inherit" size={80} />
      </Box>
    </>
  );

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          justifyContent: `space-around`,
          alignItems: `start`,
          overflowY: `auto`,
          ...scrollBarGreen,
        }}
      >
        <Box sx={{ marginB: 2, paddingX: 2 }}>
          <Typography variant="h6" color={DEFAULT_COLOR_THEME} fontWeight={600}>
            Selected Videos: {videolist.length} video
            {videolist.length > 1 ? 's' : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            border: `2px solid ${DEFAULT_COLOR_THEME}`,
            borderRadius: 4,
            minHeight: 241,
            marginX: 2,
            padding: 1,
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {videolist.map((w, index) => (
            <>
              <Box key={`details-${index}`} sx={s.card}>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 8,
                    left: 8,
                    display: w?.google ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    backgroundColor: '#f0f0f0',
                    zIndex: 10,
                  }}
                >
                  <FcGoogle size={24} />
                </Box>
                <Box key={`input-${index}`} sx={s.input}>
                  {w?.input}
                  <Button
                    sx={s.floatingClose}
                    onClick={() => {
                      setVideolist(
                        videolist.filter((item, idx) => idx !== index),
                      );
                      submitForm(
                        videolist.filter((item, idx) => idx !== index),
                      );
                    }}
                  >
                    <IoMdCloseCircle size={24} />
                  </Button>
                </Box>
                <Box sx={s.container}>
                  <Box>
                    <img
                      src={w?.image}
                      alt={w?.title}
                      width={'250px'}
                      height={`auto`}
                      style={s.img}
                    />
                  </Box>
                  <Box>
                    <Typography sx={s?.title}>{w?.title}</Typography>
                    <a href={w?.url} target="_blank" rel="noreferrer">
                      <Typography sx={s?.url}>{w?.url}</Typography>
                    </a>
                    <Typography sx={s?.description}>
                      {w?.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          ))}
        </Box>
        <Box
          sx={{
            marginY: 2,
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingX: 2,
          }}
        >
          <Typography variant="h6" color={DEFAULT_COLOR_THEME} fontWeight={600}>
            Asset Library: {videoCollection.length} video
            {videoCollection.length > 1 ? 's' : ''}
          </Typography>
          <Box
            sx={{
              flexShrink: 0,
              display: 'flex',
              gap: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TextField
              label="Youtube Link"
              variant="outlined"
              value={videoInput}
              sx={{
                width: 500,
                ...scrollBarGreen,
              }}
              onChange={(e) => {
                setVideoInput(e.target.value);
              }}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  checkLink(e.target.value);
                }
              }}
            />
            <Button
              variant="contained"
              onClick={() => {
                checkLink(videoInput);
              }}
            >
              Add Link
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            border: `2px solid ${DEFAULT_COLOR_THEME}`,
            borderRadius: 4,
            minHeight: 200,
            marginX: 2,
            padding: 2,
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {collectionLoading ? isStillLoading : null}
          {videoCollection?.map((w, index) => (
            <>
              <Box
                key={`details-${index}`}
                sx={{
                  ...s.card,
                  borderColor: videolist
                    .map((item) => item?.url)
                    .includes(w?.url)
                    ? DEFAULT_COLOR_THEME
                    : '#eeeeee',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '100%',
                    width: '100%',
                    zIndex: 20,
                    opacity: 0,
                    '&:hover': {
                      backgroundColor: videolist
                        .map((item) => item?.url)
                        .includes(w?.url)
                        ? '#751414'
                        : DEFAULT_COLOR_THEME,
                      color: 'white',
                      opacity: 0.8,
                    },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    sx={{
                      paddingX: 2,
                      paddingY: 1,
                      color: 'white',
                      fontWeight: 800,
                      fontSize: '1.2rem',
                    }}
                    onClick={() => {
                      checkLink(w?.url, true, w?.google);
                    }}
                  >
                    {videolist.map((item) => item?.url).includes(w?.url)
                      ? 'Des'
                      : 'S'}
                    elect Video
                  </Button>
                  <Button
                    sx={{
                      position: 'absolute',
                      right: -8,
                      top: 0,
                      color: 'white',
                      fontWeight: 800,
                      fontSize: '1.2rem',
                      display: w?.google ? 'none' : 'flex',
                    }}
                    onClick={() => {
                      removeVideo(w?.input);
                    }}
                  >
                    <IoMdCloseCircle size={24} />
                  </Button>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 8,
                    left: 8,
                    display: w?.google ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    backgroundColor: '#f0f0f0',
                    zIndex: 10,
                  }}
                >
                  <FcGoogle size={24} />
                </Box>
                <Box key={`input-${index}`} sx={s.input}>
                  {w?.input}
                  <Box
                    sx={{
                      position: 'absolute',
                      right: 4,
                      top: 6,
                      padding: 0,
                      margin: 0,
                      backgroundColor: 'white',
                      color: 'white',
                      borderRadius: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: videolist
                        .map((item) => item?.url)
                        .includes(w?.url)
                        ? 'flex'
                        : 'none',
                    }}
                  >
                    <IoIosCheckmarkCircle
                      size={25}
                      color={DEFAULT_COLOR_THEME}
                    />
                  </Box>
                </Box>
                <Box sx={s.container}>
                  <Box>
                    <img
                      src={w?.image}
                      alt={w?.title}
                      width={'250px'}
                      height={`auto`}
                      style={s.img}
                    />
                  </Box>
                  <Box>
                    <Typography sx={s.title}>{w?.title}</Typography>
                    <a href={w?.url} target="_blank" rel="noreferrer">
                      <Typography sx={s.url}>{w?.url}</Typography>
                    </a>
                    <Typography sx={s.description}>{w?.description}</Typography>
                  </Box>
                </Box>
              </Box>
            </>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleTemplatesVideos;
