import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Tooltip, Typography } from '@mui/material';
import { isQualifiedLead } from '../../../utils/helpers/LeadHelpers';
import { defaultProps } from '../../../utils/helpers/TableHelpers';
import { dayJsInstance } from '../../../utils/dayjsHelper';

export const leadColumns = (isFrandev: boolean, isMobile: boolean) => {
  let columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 200),
      field: 'name',
      headerClassName: styles.header,
      headerName: 'Full Name',
      sortingOrder: ['desc', 'asc'],
      valueGetter: (_, row) => `${row.firstName} ${row.lastName}`,
      renderCell: (params: any) => {
        const { firstName, lastName, lead, onOpen } = params.row;
        const fullName: any = `${firstName} ${lastName}`;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{fullName}</Typography>}
              disableInteractive
            >
              {fullName}
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 200),
      field: 'phoneNumber',
      headerClassName: styles.header,
      headerName: 'Phone Number',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { phoneNumber, lead, onOpen } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{phoneNumber}</Typography>}
              disableInteractive
            >
              {phoneNumber}
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 200),
      field: 'email',
      headerClassName: styles.header,
      headerName: 'Email',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { email, lead, onOpen } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{email}</Typography>}
              disableInteractive
            >
              {email}
            </Tooltip>
          </div>
        );
      },
    },
  ];

  if (isFrandev) {
    columns = [
      ...columns,
      {
        field: 'investmentQualified',
        headerClassName: styles.header,
        headerName: 'IQL',
        description: 'Investment Qualified Lead',
        width: 80,
        disableColumnMenu: true,
        sortingOrder: ['desc', 'asc'],
        valueGetter: (_, row) => (row.investmentQualified ? 'Yes' : 'No'),
        renderCell: (params: any) => {
          const { investmentQualifiedLead, lead, onOpen, investmentPresent } =
            params.row;
          const qualified: any = investmentQualifiedLead ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                investmentQualifiedLead
                  ? `${styles['-green']}`
                  : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={
                  <Typography variant="body2">
                    {investmentPresent ? qualified : ''}
                  </Typography>
                }
                disableInteractive
              >
                {investmentPresent ? qualified : ''}
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: 'backgroundQualified',
        headerClassName: styles.header,
        headerName: 'BQL',
        width: 80,
        sortingOrder: ['desc', 'asc'],
        disableColumnMenu: true,
        description: 'Background Qualified Lead',
        valueGetter: (_, row) => (row.backgroundQualified ? 'Yes' : 'No'),
        renderCell: (params: any) => {
          const { backgroundQualifiedLead, lead, onOpen, backgroundPresent } =
            params.row;
          const qualified: any = backgroundQualifiedLead ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                backgroundQualifiedLead
                  ? `${styles['-green']}`
                  : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={
                  <Typography variant="body2">
                    {backgroundPresent ? qualified : ''}
                  </Typography>
                }
                disableInteractive
              >
                {backgroundPresent ? qualified : ''}
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: 'experienceQualified',
        headerClassName: styles.header,
        headerName: 'EQL',
        description: 'Experience Qualified Lead',
        width: 80,
        disableColumnMenu: true,
        sortingOrder: ['desc', 'asc'],
        valueGetter: (_, row) => (row.experienceQualified ? 'Yes' : 'No'),
        renderCell: (params: any) => {
          const { experienceQualifiedLead, lead, onOpen, experiencePresent } =
            params.row;
          const qualified: any = experienceQualifiedLead ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                experienceQualifiedLead
                  ? `${styles['-green']}`
                  : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={
                  <Typography variant="body2">
                    {experiencePresent ? qualified : ''}
                  </Typography>
                }
                disableInteractive
              >
                {experiencePresent ? qualified : ''}
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: 'qualifiedLead',
        headerClassName: styles.header,
        headerName: 'QL',
        width: 80,
        disableColumnMenu: true,
        description: 'Qualified Lead',
        sortingOrder: ['desc', 'asc'],
        valueGetter: (_, row) => {
          const {
            investmentQualified,
            backgroundQualified,
            experienceQualified,
            investmentQualifiedLead,
            backgroundQualifiedLead,
            experienceQualifiedLead,
            investmentPresent,
            backgroundPresent,
            experiencePresent,
          } = row;

          const isQualified = isQualifiedLead({
            investmentQualified: investmentPresent && investmentQualified,
            backgroundQualified: backgroundPresent && backgroundQualified,
            experienceQualified: experiencePresent && experienceQualified,
            investmentQualifiedLead,
            backgroundQualifiedLead,
            experienceQualifiedLead,
          });

          return isQualified ? 'Yes' : 'No';
        },
        renderCell: (params: any) => {
          const {
            investmentQualified,
            backgroundQualified,
            experienceQualified,
            investmentQualifiedLead,
            backgroundQualifiedLead,
            experienceQualifiedLead,
            lead,
            onOpen,
            investmentPresent,
            backgroundPresent,
            experiencePresent,
          } = params.row;

          const isQualified = isQualifiedLead({
            investmentQualified: investmentPresent && investmentQualified,
            backgroundQualified: backgroundPresent && backgroundQualified,
            experienceQualified: experiencePresent && experienceQualified,
            investmentQualifiedLead,
            backgroundQualifiedLead,
            experienceQualifiedLead,
          });
          const qualified: any = isQualified ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                isQualified ? `${styles['-green']}` : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={<Typography variant="body2">{qualified}</Typography>}
                disableInteractive
              >
                {qualified}
              </Tooltip>
            </div>
          );
        },
      },
      {
        ...defaultProps(isMobile, 200),
        field: 'details',
        headerClassName: styles.header,
        headerName: 'Details',
        sortingOrder: ['desc', 'asc'],
        renderCell: (params: any) => {
          const { details, lead, onOpen } = params.row;

          return (
            <div className={styles.name} onClick={() => onOpen(lead)}>
              <Tooltip
                title={<Typography variant="body2">{details}</Typography>}
                disableInteractive
              >
                {details}
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }

  columns = [
    ...columns,
    {
      ...defaultProps(isMobile, 200),
      field: 'adName',
      headerClassName: styles.header,
      headerName: 'Ad Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { adName, lead, onOpen } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{adName}</Typography>}
              disableInteractive
            >
              {adName}
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 170),
      field: 'createdAt',
      headerClassName: styles.header,
      headerName: 'Created',
      sortingOrder: ['desc', 'asc'],
      valueGetter: (_, row) => row.createdAt,
      renderCell: (params: any) => {
        const { createdAt, lead, onOpen } = params.row;
        const formattedDate: any = dayJsInstance
          .unix(createdAt)
          .format('MM/DD/YYYY hh:mma');

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{formattedDate}</Typography>}
              disableInteractive
            >
              {formattedDate}
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return columns;
};
