import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';
import { googleConstants } from '../../../../utils/constants/google/googleconstants';

interface GoogleTemplatesDemographicsProps {
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
}

const AGES = googleConstants.ages.map((age) => {
  return {
    ...age,
  };
});

const GENDERS = googleConstants.genders.map((g) => {
  return {
    ...g,
  };
});

const INCOME_RANGES = googleConstants.income_ranges.map((income) => {
  return {
    ...income,
    label: income.Label,
  };
});

const PARENTS = googleConstants.parents.map((parent) => {
  return {
    ...parent,
  };
});

const GoogleTemplatesDemographics: React.FC<
  GoogleTemplatesDemographicsProps
> = ({ submitForm, templateForm, setIsDirty }) => {
  const [ages, setAges] = useState([]);
  const [genders, setGenders] = useState([]);
  const [incomeRanges, setIncomeRanges] = useState([]);
  const [parents, setParents] = useState([]);

  useEffect(() => {
    console.log(templateForm);
    submitForm({ ...templateForm });
    setAges(
      templateForm?.ages || googleConstants.ages.map((age) => age['value']),
    );
    setGenders(
      templateForm?.genders || googleConstants.genders.map((g) => g['value']),
    );
    setIncomeRanges(
      templateForm?.incomeRanges ||
        googleConstants.income_ranges.map((income) => income['value']),
    );
    setParents(
      templateForm?.parents ||
        googleConstants.parents.map((parent) => parent['value']),
    );
  }, []);

  const sform = (params: {
    ages: number[];
    genders: number[];
    incomeRanges: number[];
    parents: number[];
  }) => {
    const { ages, genders, incomeRanges, parents } = params;
    submitForm({
      ages,
      genders,
      incomeRanges,
      parents,
    });
  };

  useEffect(() => {
    setIsDirty(true);
    sform({
      ages,
      genders,
      incomeRanges,
      parents,
    });
  }, [ages, genders, incomeRanges, parents]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: `start`,
          alignItems: `start`,
          flexDirection: 'row',
          gap: `1rem`,
        }}
      >
        <Box bgcolor={'#f9f9f9'} borderRadius={2} padding={2}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: `1rem`,
            }}
          >
            Select Age Range:
          </Typography>
          <Box paddingLeft={2}>
            <FormGroup>
              {AGES.map((age) => (
                <FormControlLabel
                  key={`age-${age.value}`}
                  control={
                    <Checkbox
                      checked={ages?.includes(age.value)}
                      disabled={ages?.includes(age.value) && ages.length === 1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAges([...ages, age.value]);
                        } else {
                          setAges(ages.filter((a) => a !== age.value));
                        }
                      }}
                    />
                  }
                  label={age.label}
                />
              ))}
            </FormGroup>
          </Box>
        </Box>

        <Box bgcolor={'#f9f9f9'} borderRadius={2} padding={2}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: `1rem`,
            }}
          >
            Select Income Range:
          </Typography>
          <Box paddingLeft={2}>
            <FormGroup>
              {INCOME_RANGES.map((income) => (
                <FormControlLabel
                  key={`income-${income.value}`}
                  control={
                    <Checkbox
                      checked={incomeRanges?.includes(income.value)}
                      disabled={
                        incomeRanges?.includes(income.value) &&
                        incomeRanges?.length === 1
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setIncomeRanges([...incomeRanges, income.value]);
                        } else {
                          setIncomeRanges(
                            incomeRanges?.filter((i) => i !== income.value),
                          );
                        }
                      }}
                    />
                  }
                  label={income.label}
                />
              ))}
            </FormGroup>
          </Box>
        </Box>

        <Box bgcolor={'#f9f9f9'} borderRadius={2} padding={2}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: `1rem`,
            }}
          >
            Select target genders:
          </Typography>
          <Box paddingLeft={2}>
            <FormGroup>
              {GENDERS.map((g) => (
                <FormControlLabel
                  key={`gender-${g.value}`}
                  control={
                    <Checkbox
                      checked={genders?.includes(g.value)}
                      disabled={
                        genders?.includes(g.value) && genders?.length === 1
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setGenders([...genders, g.value]);
                        } else {
                          setGenders(genders?.filter((gen) => gen !== g.value));
                        }
                      }}
                    />
                  }
                  label={g.label}
                />
              ))}
            </FormGroup>
          </Box>
        </Box>

        <Box bgcolor={'#f9f9f9'} borderRadius={2} padding={2}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: `1rem`,
            }}
          >
            Parental Status:
          </Typography>
          <Box paddingLeft={2}>
            <FormGroup>
              {PARENTS.map((parent) => (
                <FormControlLabel
                  key={`parent-${parent.value}`}
                  control={
                    <Checkbox
                      checked={parents?.includes(parent.value)}
                      disabled={
                        parents?.includes(parent.value) && parents?.length === 1
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setParents([...parents, parent.value]);
                        } else {
                          setParents(
                            parents?.filter((p) => p !== parent.value),
                          );
                        }
                      }}
                    />
                  }
                  label={parent.label}
                />
              ))}
            </FormGroup>
          </Box>
        </Box>
      </Box>
      <Box display={`none`}>
        Ages: {ages?.join(', ')} <br />
        Genders: {genders?.join(', ')} <br />
        Income Ranges: {incomeRanges?.join(', ')} <br />
        Parents: {parents?.join(', ')} <br />
      </Box>
    </>
  );
};

export default GoogleTemplatesDemographics;
