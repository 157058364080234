import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  ActionPlan,
  Brand,
  ChangeEventType,
  LeadSource,
} from '../../../../types';
import { CrmSetting } from '../../../../types/ICrm';
import { HtmlTooltip } from '../../../Tooltip';
import { errorMessageParser } from '../../../../utils/helpers/ToastHelper';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';
import { createOrUpdateCrmSettings } from '../../../../services/crm';
import { FloatingButton, PrimaryButton } from '../../../Buttons';
import { useForm } from 'react-hook-form';
import clientTetherLogo from '../../../../assets/images/client-tether-logo.png';
import {
  fetchActionPlans,
  fetchLeadSource,
  syncClientTetherClients,
} from '../../../../services/clientTether';
import { ScrollStyle } from '../../../../utils';
import { MdSync } from 'react-icons/md';
import { constantStringToHumanized } from '../../../../utils/stringModifier';
import PopupModal from '../../../Modal';
import ClientTetherSalesCycleForm from './ClientTetherSalesCycleForm';
import CircularLoading from '../../../CircularLoading';
import { NumericCustomField } from '../../../TextField';

interface CrmPlatform {
  name: string;
  value: string;
  logo: any;
}

const CrmProvider = {
  ClientTether: 'client-tether',
};

const CRM_PLATFORM: CrmPlatform[] = [
  {
    name: 'Client Tether',
    value: CrmProvider.ClientTether,
    logo: clientTetherLogo,
  },
];

interface FacebookCrmSettingsFormProps {
  brand: Brand;
  crmSettings: CrmSetting;
  setCrmSettings: Dispatch<SetStateAction<CrmSetting>>;
}

const CrmSettingsForm: React.FC<FacebookCrmSettingsFormProps> = ({
  brand,
  crmSettings,
  setCrmSettings,
}) => {
  const dispatch = useDispatch();
  const {
    formState: { errors },
    register,
    handleSubmit,
    clearErrors,
  } = useForm({ mode: 'onSubmit' });
  const [formValues, setFormValues] = useState<CrmSetting>({ ...crmSettings });
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [leadSources, setLeadSources] = useState<LeadSource[]>([]);
  const [actionPlans, setActionPlans] = useState<ActionPlan[]>([]);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [showCTSalesCycleForm, setShowCTSalesCycleForm] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      formValues?.clientTetherSettings?.accessToken &&
      formValues?.clientTetherSettings?.webKey
    ) {
      getLeadSource();
      getActionPlans();
    }
  }, [
    formValues?.clientTetherSettings?.accessToken,
    formValues?.clientTetherSettings?.webKey,
  ]);

  const getLeadSource = async () => {
    try {
      setFetchLoading(true);

      const response = await fetchLeadSource(
        formValues?.clientTetherSettings?.accessToken,
        formValues?.clientTetherSettings?.webKey,
      );

      setLeadSources(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getActionPlans = async () => {
    try {
      setFetchLoading(true);

      const response = await fetchActionPlans(
        formValues?.clientTetherSettings?.accessToken,
        formValues?.clientTetherSettings?.webKey,
      );

      setActionPlans(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOnChangeCT = (e: ChangeEventType, provider: string) => {
    if (provider === CrmProvider.ClientTether) {
      clearErrors(e.target.name);
      setFormValues({
        ...formValues,
        clientTetherSettings: {
          ...formValues?.clientTetherSettings,
          [e.target.name]: e.target.value,
          leadSourceId: null,
          actionPlanId: null,
        },
      });
    }
  };

  const handleOnEnable = (e: any, provider: string) => {
    let providers: string[] = [];
    if (e.target.checked) {
      providers = [
        ...(formValues?.providers?.filter((p: string) => p !== provider) || []),
        provider,
      ];
    } else {
      providers = formValues.providers.filter((p: string) => p !== provider);
    }

    setFormValues({ ...formValues, providers });
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);

      const response = await createOrUpdateCrmSettings(brand?._id, formValues);

      setCrmSettings(response.data);
      setFormValues(response.data);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'CRM Setting updated successfully',
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleOnSyncClientTetherClients = async () => {
    try {
      setSyncLoading(true);

      const response = await syncClientTetherClients(
        brand?._id,
        formValues?.clientTetherSettings?.accessToken,
        formValues?.clientTetherSettings?.webKey,
      );

      setFormValues({
        ...formValues,
        clientTetherSettings: {
          ...formValues?.clientTetherSettings,
          enableSyncLeads: false,
        },
      });

      dispatch(
        toggleAlert({
          toggle: true,
          message: `Synced ${response.data.length} leads successfully`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setSyncLoading(false);
    }
  };

  const handleShowCtSalesCycleForm = () => {
    setShowCTSalesCycleForm((prev) => !prev);
  };

  const renderForm = (provider: string) => {
    if (
      formValues?.providers?.includes(provider) &&
      provider === CrmProvider.ClientTether
    ) {
      const isEnabled = formValues?.providers?.includes(provider);

      return (
        <Grid item xs={6}>
          <Grid container spacing={2}>
            {(formValues?.clientTetherSettings?.enableSyncLeads || true) &&
            formValues?.clientTetherSettings?.accessToken &&
            formValues?.clientTetherSettings?.webKey ? (
              <Grid item xs={12}>
                <PrimaryButton
                  title="Sync Leads"
                  type="button"
                  handleOnClick={handleOnSyncClientTetherClients}
                  loading={syncLoading}
                  startIcon={<MdSync />}
                />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <TextField
                {...(isEnabled
                  ? register('accessToken', { required: true })
                  : {})}
                autoFocus
                fullWidth
                variant="standard"
                type="text"
                id="clientTetherAccessToken"
                name="accessToken"
                label="Access Token"
                onChange={(e: ChangeEventType) => {
                  handleOnChangeCT(e, provider);
                }}
                value={formValues?.clientTetherSettings?.accessToken}
                placeholder="Access Token"
                InputLabelProps={{ shrink: true }}
                size="small"
                multiline
                error={!!errors?.accessToken}
                helperText={errors?.accessToken && 'Access token is required'}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...(isEnabled ? register('webKey', { required: true }) : {})}
                fullWidth
                variant="standard"
                type="text"
                id="clientTetherWebkey"
                name="webKey"
                label="Web Key"
                onChange={(e: ChangeEventType) => {
                  handleOnChangeCT(e, provider);
                }}
                value={formValues?.clientTetherSettings?.webKey}
                placeholder="Web Key"
                InputLabelProps={{ shrink: true }}
                size="small"
                multiline
                error={!!errors?.webKey}
                helperText={errors?.webKey && 'Web key is required'}
              />
            </Grid>

            <Grid item xs={12}>
              <HtmlTooltip
                disableInteractive
                title={
                  <Fragment>
                    The X-Access-Token is only available on the API tab in
                    Settings at the Enterprise Level. The X-Web-Key is available
                    in each Account and by an API call for Subaccounts.
                  </Fragment>
                }
              >
                <Typography
                  variant="caption"
                  sx={{
                    textDecoration: 'underline',
                    color: 'blue',
                    cursor: 'pointer',
                  }}
                >
                  Where to find your access token and web key?
                </Typography>
              </HtmlTooltip>
            </Grid>

            {formValues?.clientTetherSettings?.accessToken &&
            formValues?.clientTetherSettings?.webKey ? (
              <Grid item xs={12}>
                <Autocomplete
                  ListboxProps={{
                    sx: ScrollStyle(),
                  }}
                  id="leadSources"
                  renderOption={(props, option) => {
                    return (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={option.lead_source_id}
                      >
                        <Box component="div" sx={{ flexDirection: 'column' }}>
                          <Typography variant="body1">
                            {option.lead_source_name}
                          </Typography>

                          <Typography variant="caption">
                            {option.lead_source_id}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }}
                  options={leadSources}
                  value={
                    leadSources?.find(
                      (source: LeadSource) =>
                        source.lead_source_id ===
                        formValues?.clientTetherSettings?.leadSourceId,
                    ) || null
                  }
                  getOptionLabel={(option) => option.lead_source_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Lead Sources"
                      placeholder="Select your lead source"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {fetchLoading ? (
                              <CircularProgress
                                size={20}
                                sx={{ marginRight: '10px' }}
                              />
                            ) : null}
                          </>
                        ),
                      }}
                    />
                  )}
                  onChange={(_, value: LeadSource) => {
                    setFormValues({
                      ...formValues,
                      clientTetherSettings: {
                        ...formValues?.clientTetherSettings,
                        leadSourceId: value.lead_source_id,
                      },
                    });
                  }}
                />
              </Grid>
            ) : null}

            {formValues?.clientTetherSettings?.accessToken &&
            formValues?.clientTetherSettings?.webKey ? (
              <Grid item xs={12}>
                <Autocomplete
                  ListboxProps={{
                    sx: ScrollStyle(),
                  }}
                  id="actionPlans"
                  renderOption={(props, option) => {
                    return (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={option.action_plan_id}
                      >
                        <Box component="div" sx={{ flexDirection: 'column' }}>
                          <Typography variant="body1">
                            {option.action_plan_name}
                          </Typography>

                          <Typography variant="caption">
                            {option.action_plan_id}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }}
                  options={actionPlans}
                  value={
                    actionPlans?.find(
                      (actionPlan: ActionPlan) =>
                        actionPlan.action_plan_id ===
                        formValues?.clientTetherSettings?.actionPlanId,
                    ) || null
                  }
                  getOptionLabel={(option) => option.action_plan_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Action Plan"
                      placeholder="Select your action plan"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {fetchLoading ? (
                              <CircularProgress
                                size={20}
                                sx={{ marginRight: '10px' }}
                              />
                            ) : null}
                          </>
                        ),
                      }}
                    />
                  )}
                  onChange={(_, value: ActionPlan) => {
                    setFormValues({
                      ...formValues,
                      clientTetherSettings: {
                        ...formValues?.clientTetherSettings,
                        actionPlanId: value.action_plan_id,
                      },
                    });
                  }}
                  groupBy={(option) => constantStringToHumanized(option.type)}
                />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <PrimaryButton
                title="Setup Sales Cycle"
                type="button"
                handleOnClick={handleShowCtSalesCycleForm}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Box>
      <CircularLoading loading={fetchLoading} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NumericCustomField
                value={formValues?.averageLifetimeValue}
                onChangeValue={(values) =>
                  setFormValues({
                    ...formValues,
                    averageLifetimeValue: values.floatValue,
                  })
                }
                id="averageLifetimeValue"
                name="averageLifetimeValue"
                label="Average Lifetime Value"
                fullWidth
                fixedDecimal
                helperText={
                  <Fragment>
                    <HtmlTooltip
                      disableInteractive
                      title={
                        <Fragment>
                          <Box
                            component="div"
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            Formula
                          </Box>

                          <div>AUV x Royalty Fee Percentage x Years</div>

                          <Box
                            component="div"
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            Example
                          </Box>

                          <Box
                            component="div"
                            sx={{
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              sx={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                              }}
                            >
                              $1,000,000 (AUV)
                            </Box>

                            <Box
                              sx={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                              }}
                            >
                              0.05 (5% Royalty Fee)
                            </Box>

                            <Box
                              sx={{
                                justifyContent: 'space-between',
                                display: 'flex',
                              }}
                            >
                              <div>x</div>

                              <div>10 (Years)</div>
                            </Box>

                            <div>
                              <Divider />
                            </div>

                            <Box
                              sx={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                              }}
                            >
                              $500,000 (Average Lifetime Value)
                            </Box>
                          </Box>
                        </Fragment>
                      }
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          textDecoration: 'underline',
                          color: 'blue',
                          cursor: 'pointer',
                        }}
                      >
                        How to calculate your Average Lifetime Value?
                      </Typography>
                    </HtmlTooltip>
                  </Fragment>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <NumericCustomField
                value={formValues?.averageCommission}
                onChangeValue={(values) =>
                  setFormValues({
                    ...formValues,
                    averageCommission: values.floatValue,
                  })
                }
                id="averageCommission"
                name="averageCommission"
                label="Average Commission"
                fullWidth
                fixedDecimal
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                type="number"
                id="averageUnitSold"
                name="averageUnitSold"
                label="Average Unit Sold"
                onChange={(e: ChangeEventType) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: parseInt(e.target.value),
                  })
                }
                value={formValues?.averageUnitSold}
                placeholder="0"
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          Please choose the CRM you want to integrate:
        </Grid>

        <Grid item xs={12}>
          {CRM_PLATFORM.map((platform: CrmPlatform) => {
            return (
              <Box key={platform.value}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="status">
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          component="img"
                          src={clientTetherLogo}
                          sx={{
                            width: '20px',
                            marginRight: '10px',
                          }}
                        />

                        {platform.name}
                      </Box>
                    </FormLabel>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            formValues?.providers?.includes(platform.value) ||
                            false
                          }
                          onChange={(e: any) =>
                            handleOnEnable(e, platform.value)
                          }
                        />
                      }
                      label={
                        formValues?.providers?.includes(platform.value)
                          ? 'Enabled'
                          : 'Disabled'
                      }
                    />
                  </FormControl>
                </Grid>

                {renderForm(platform.value)}
              </Box>
            );
          })}
        </Grid>
      </Grid>

      <FloatingButton
        title="Save"
        onClick={handleSubmit(handleOnSubmit)}
        loading={loading}
        bottom="60px"
        fixed
      />

      <PopupModal
        open={showCTSalesCycleForm}
        handleClose={handleShowCtSalesCycleForm}
      >
        <ClientTetherSalesCycleForm
          formValues={formValues}
          setFormValues={setFormValues}
          setCrmSettings={setCrmSettings}
          brand={brand}
          onClose={handleShowCtSalesCycleForm}
        />
      </PopupModal>
    </Box>
  );
};

export default CrmSettingsForm;
