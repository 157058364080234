import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import {
  MdDeleteOutline,
  MdOutlineStarOutline,
  MdSearch,
} from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';
import { fetchUserInterestAndTopics } from '../../../../services/googleAds';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import CircularLoading from '../../../CircularLoading';
import { TabContext, TabList, TabPanel } from '@mui/lab';

interface GoogleTemplatesTopicsAndInterestsProps {
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
  brandId: string;
}

const GoogleTemplatesTopicsAndInterests: React.FC<
  GoogleTemplatesTopicsAndInterestsProps
> = ({ submitForm, templateForm, setIsDirty, brandId }) => {
  const [loading, setLoading] = useState(true);
  const [topicsAndInterestsInput, setTopicsAndInterestsInput] = useState('');
  const [customAffinityInput, setCustomAffinityInput] = useState('');
  const [listsOfTopicsAndInterests, setListsOfTopicsAndInterests] = useState(
    [],
  );
  const [
    listsOfTopicsAndInterestsResults,
    setListsOfTopicsAndInterestsResults,
  ] = useState([]);

  const [tabValue, setTabValue] = useState('Browse');
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectedTopicsAndInterests, setSelectedTopicsAndInterests] = useState(
    [],
  );
  const [customAffinity, setCustomAffinity] = useState([]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setLoading(true);
    setTabValue(newValue);
    setLoading(false);
  };

  useEffect(() => {
    submitForm({
      topics: selectedTopicsAndInterests.filter(
        (item) => item.type === 'Topics',
      ),
      interests: selectedTopicsAndInterests.filter(
        (item) => item.type === 'User Interests',
      ),
      lifeEvents: selectedTopicsAndInterests.filter(
        (item) => item.type === 'Life Events',
      ),
      customAffinity: customAffinity,
      customInterests: selectedTopicsAndInterests.filter(
        (item) => item.type === 'Custom Interests',
      ),
      customAudiences: selectedTopicsAndInterests.filter(
        (item) => item.type === 'Custom Audiences',
      ),
    });
  }, [selectedTopicsAndInterests, customAffinity]);

  useEffect(() => {
    setSelectedTopicsAndInterests(
      listsOfTopicsAndInterests
        .filter((item) => rowSelectionModel.includes(item.id))
        .sort((a: any, b: any) => a.name.localeCompare(b.name)),
    );
  }, [rowSelectionModel]);

  useEffect(() => {
    fetchUserInterestAndTopics({
      brandId: brandId,
    }).then((res) => {
      console.log('res', res);
      setListsOfTopicsAndInterests(
        [
          ...res.topics,
          ...res.interests,
          ...res.life_events,
          ...(res.custom_audiences || []),
          ...(res.custom_interests || []),
        ]
          .map((t: any, i: number) => ({
            ...t,
            id: i,
          }))
          .sort((a: any, b: any) => a.name.localeCompare(b.name)),
      );
      setLoading(false);
    });

    console.log(`templateForm`, templateForm);
    if (templateForm) {
      console.log(`templateForm`, templateForm);
      const newval = [
        ...(templateForm?.topics || []),
        ...(templateForm?.interests || []),
        ...(templateForm?.lifeEvents || []),
      ];
      setSelectedTopicsAndInterests(newval);
      setCustomAffinity(templateForm?.customAffinity || []);
    }
  }, []);

  const searchTopicsAndInterestsSection = (
    <Box>
      <TextField
        label="Enter Topics, Interests & Life Events"
        fullWidth
        multiline
        value={topicsAndInterestsInput}
        onChange={(e) => {
          setTopicsAndInterestsInput(e.target.value);
          setIsDirty(true);
        }}
        placeholder="Enter topics, interests, & life events separated by a new line."
        minRows={3}
        sx={{
          ':placeholder': {
            color: '#333333',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <MdSearch />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '0.5rem',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const lines = topicsAndInterestsInput.split('\n');
            const temp2 = [
              ...listsOfTopicsAndInterests
                .map((item) => {
                  let shouldReturn = {};
                  lines.forEach((line) => {
                    const words = line.trim().split(' ');
                    words.forEach((word) => {
                      if (
                        item.name.toLowerCase().includes(word.toLowerCase())
                      ) {
                        shouldReturn = { ...shouldReturn, ...item };
                      }
                    });

                    if (item.name.toLowerCase() === line.trim().toLowerCase()) {
                      shouldReturn = {
                        ...shouldReturn,
                        starred: true,
                      };
                    }
                  });

                  return shouldReturn;
                })
                .filter((item: any) => item.name)
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                .sort((a: any, b: any) => (a.starred ? -1 : 1)),
            ];

            setListsOfTopicsAndInterestsResults(temp2);
            //setTopicsAndInterestsInput('');
            setIsDirty(true);
          }}
        >
          Search Topics and Interests
        </Button>
      </Box>
      <Box>
        {listsOfTopicsAndInterestsResults.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0.5rem',
              borderBottom: '1px solid #e0e0e0',
              gap: 2,
            }}
          >
            <Box>
              <Typography>
                {item.name}{' '}
                {item.starred ? (
                  <MdOutlineStarOutline color="gold" size={16} />
                ) : (
                  ''
                )}
              </Typography>
              <Typography
                sx={{
                  color: '#333333',
                  fontSize: '0.7rem',
                }}
              >
                {item.type}
                {item.taxonomyType ? ` - ${item.taxonomyType}` : ''}
              </Typography>
            </Box>
            <Box
              sx={{
                flexShrink: 0,
              }}
            >
              <Button
                variant="outlined"
                color={
                  rowSelectionModel.includes(item.id) ? 'error' : 'primary'
                }
                sx={{
                  paddingY: '0.25rem',
                  paddingX: '0.5rem',
                }}
                onClick={() => {
                  if (rowSelectionModel.includes(item.id)) {
                    setRowSelectionModel(
                      rowSelectionModel.filter((id) => id !== item.id),
                    );
                  } else {
                    setRowSelectionModel([...rowSelectionModel, item.id]);
                  }
                }}
              >
                {rowSelectionModel.includes(item.id) ? 'Remove' : 'Add'}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );

  const topicsAndInterestsContainer = (
    <Box
      sx={{
        '& .green-header': {
          backgroundColor: DEFAULT_COLOR_THEME,
          color: 'white',
          fontWeight: 'bold',
        },
      }}
    >
      <DataGridPremium
        rows={listsOfTopicsAndInterests}
        columns={[]}
        checkboxSelection
        disableRowSelectionOnClick
        hideFooter
        treeData
        density="compact"
        groupingColDef={{
          headerName: 'Topics, Interests & Life Events',
          headerClassName: '',
          width: 300,
          flex: 1,
        }}
        autoHeight
        getTreeDataPath={(row) => {
          return row.path;
        }}
        isRowSelectable={(params) => {
          return (
            params.row.type === 'Topics' ||
            params.row.type === 'User Interests' ||
            params.row.type === 'Life Events' ||
            params.row.type === 'Custom Audiences' ||
            params.row.type === 'Custom Interests'
          );
        }}
        onRowSelectionModelChange={(params) => {
          console.log('onRowSelectionModelChange params', params);
          setRowSelectionModel(params);
        }}
        onRowGroupingModelChange={(params) => {
          console.log('onRowGroupingModelChange params', params);
        }}
        onRowModesModelChange={(params) => {
          console.log('onRowModesModelChange params', params);
        }}
        isGroupExpandedByDefault={(row: any) => {
          if (row.id === 34 || row.id === 31) {
            console.log(rowSelectionModel);
            console.log('isGroupExpandedByDefault row', row);
          }
          let isIncluded = false;

          rowSelectionModel.forEach((id: any) => {
            row.children.forEach((r: any) => {
              console.log(id);
              console.log(r);
              if (r === id) {
                isIncluded = true;
              }
            });
          });

          return isIncluded;
        }}
        onRowClick={(params) => {
          console.log('onRowClick params', params);
        }}
      />
    </Box>
  );

  const customAffinityContainer = (
    <Box>
      <TextField
        label="Enter custom affinity"
        fullWidth
        multiline
        value={customAffinityInput}
        onChange={(e) => {
          setCustomAffinityInput(e.target.value);
          setIsDirty(true);
          setCustomAffinity(
            e.target.value.split('\n').map((item) => item.trim()),
          );
        }}
        placeholder="Enter custom affinity, separated by a new line."
        minRows={3}
        sx={{
          ':placeholder': {
            color: '#333333',
          },
        }}
      />
    </Box>
  );

  return (
    <Box sx={{}}>
      <CircularLoading loading={loading} />
      <Box
        sx={{
          display: loading ? 'none' : 'flex',
          gap: 2,
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '50%',
            maxHeight: '70vh',
            overflowY: 'auto',
            ...scrollBarGreen,
          }}
        >
          <TabContext value={tabValue}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              sx={{
                backgroundColor: DEFAULT_COLOR_THEME,
                color: 'white',
                fontWeight: 'bold',
                '.Mui-selected': {
                  color: DEFAULT_COLOR_THEME,
                  backgroundColor: `#a1c8bb`,
                },
              }}
            >
              <Tab
                label="Browse"
                value="Browse"
                sx={{ color: 'white', fontWeight: 'bold' }}
              />
              <Tab
                label="Search"
                value="Search"
                sx={{ color: 'white', fontWeight: 'bold' }}
              />
              <Tab
                label="Custom"
                value="Custom"
                sx={{ color: 'white', fontWeight: 'bold' }}
              />
            </TabList>
            <TabPanel value="Browse">{topicsAndInterestsContainer}</TabPanel>
            <TabPanel value="Search">
              {searchTopicsAndInterestsSection}
            </TabPanel>
            <TabPanel value="Custom">{customAffinityContainer}</TabPanel>
          </TabContext>
        </Box>
        <Box
          sx={{
            width: '50%',
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: `1rem`,
              color: DEFAULT_COLOR_THEME,
              marginBottom: '1rem',
            }}
          >
            Selected Topics and Interests
          </Typography>
          <Box>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ marginTop: '1rem', fontWeight: 600 }}>
                  Topics [
                  {selectedTopicsAndInterests.filter(
                    (item) => item.type === 'Topics',
                  )?.length || 0}
                  ]
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {selectedTopicsAndInterests
                  .filter((item) => item.type === 'Topics')
                  .map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        paddingY: '0.2rem',
                        paddingX: '1.5rem',
                        borderBottom: '1px solid #e0e0e0',
                      }}
                    >
                      <Typography sx={{ fontSize: '0.85rem' }}>
                        {item.name}
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            float: 'right',
                            minWidth: '0px',
                          }}
                          onClick={() => {
                            setSelectedTopicsAndInterests(
                              selectedTopicsAndInterests.filter(
                                (i) => i.id !== item.id,
                              ),
                            );
                          }}
                        >
                          <MdDeleteOutline size={12} />
                        </Button>
                      </Typography>
                    </Box>
                  ))}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography sx={{ marginTop: '1rem', fontWeight: 600 }}>
                  User Interests [
                  {selectedTopicsAndInterests.filter(
                    (item) => item.type === 'User Interests',
                  )?.length || 0}
                  ]
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {selectedTopicsAndInterests
                  .filter((item) => item.type === 'User Interests')
                  .map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        paddingY: '0.2rem',
                        paddingX: '1.5rem',
                        borderBottom: '1px solid #e0e0e0',
                      }}
                    >
                      <Typography sx={{ fontSize: '0.85rem' }}>
                        {item.name}
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            float: 'right',
                            minWidth: '0px',
                          }}
                          onClick={() => {
                            setSelectedTopicsAndInterests(
                              selectedTopicsAndInterests.filter(
                                (i) => i.id !== item.id,
                              ),
                            );
                          }}
                        >
                          <MdDeleteOutline size={12} />
                        </Button>
                      </Typography>
                      <Typography
                        sx={{
                          color: '#333333',
                          fontSize: '0.7rem',
                        }}
                      >
                        {item.taxonomyType}
                      </Typography>
                    </Box>
                  ))}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography sx={{ marginTop: '1rem', fontWeight: 600 }}>
                  Life Events [
                  {selectedTopicsAndInterests.filter(
                    (item) => item.type === 'Life Events',
                  )?.length || 0}
                  ]
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {selectedTopicsAndInterests
                  .filter((item) => item.type === 'Life Events')
                  .map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        paddingY: '0.2rem',
                        paddingX: '1.5rem',
                        borderBottom: '1px solid #e0e0e0',
                      }}
                    >
                      <Typography sx={{ fontSize: '0.85rem' }}>
                        {item.name}
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            float: 'right',
                            minWidth: '0px',
                          }}
                          onClick={() => {
                            setSelectedTopicsAndInterests(
                              selectedTopicsAndInterests.filter(
                                (i) => i.id !== item.id,
                              ),
                            );
                          }}
                        >
                          <MdDeleteOutline size={12} />
                        </Button>
                      </Typography>
                    </Box>
                  ))}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography sx={{ marginTop: '1rem', fontWeight: 600 }}>
                  Custom Audiences [
                  {selectedTopicsAndInterests.filter(
                    (item) => item.type === 'Custom Audiences',
                  )?.length || 0}
                  ]
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {selectedTopicsAndInterests
                  .filter((item) => item.type === 'Custom Audiences')
                  .map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        paddingY: '0.2rem',
                        paddingX: '1.5rem',
                        borderBottom: '1px solid #e0e0e0',
                      }}
                    >
                      <Typography sx={{ fontSize: '0.85rem' }}>
                        {item.name}
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            float: 'right',
                            minWidth: '0px',
                          }}
                          onClick={() => {
                            setSelectedTopicsAndInterests(
                              selectedTopicsAndInterests.filter(
                                (i) => i.id !== item.id,
                              ),
                            );
                          }}
                        >
                          <MdDeleteOutline size={12} />
                        </Button>
                      </Typography>
                    </Box>
                  ))}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography sx={{ marginTop: '1rem', fontWeight: 600 }}>
                  Custom Interests [
                  {selectedTopicsAndInterests.filter(
                    (item) => item.type === 'Custom Interests',
                  )?.length || 0}
                  ]
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {selectedTopicsAndInterests
                  .filter((item) => item.type === 'Custom Interests')
                  .map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        paddingY: '0.2rem',
                        paddingX: '1.5rem',
                        borderBottom: '1px solid #e0e0e0',
                      }}
                    >
                      <Typography sx={{ fontSize: '0.85rem' }}>
                        {item.name}
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            float: 'right',
                            minWidth: '0px',
                          }}
                          onClick={() => {
                            setSelectedTopicsAndInterests(
                              selectedTopicsAndInterests.filter(
                                (i) => i.id !== item.id,
                              ),
                            );
                          }}
                        >
                          <MdDeleteOutline size={12} />
                        </Button>
                      </Typography>
                    </Box>
                  ))}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <Typography sx={{ marginTop: '1rem', fontWeight: 600 }}>
                  Custom [{customAffinity?.length || 0}]
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {customAffinity?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      paddingY: '0.2rem',
                      paddingX: '1.5rem',
                      borderBottom: '1px solid #e0e0e0',
                    }}
                  >
                    <Typography sx={{ fontSize: '0.85rem' }}>
                      {item}
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          float: 'right',
                          minWidth: '0px',
                        }}
                        onClick={() => {
                          setSelectedTopicsAndInterests(
                            selectedTopicsAndInterests.filter(
                              (i) => i.id !== item.id,
                            ),
                          );
                        }}
                      >
                        <MdDeleteOutline size={12} />
                      </Button>
                    </Typography>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleTemplatesTopicsAndInterests;
