import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, Fragment, SetStateAction } from 'react';
import {
  ChangeEventType,
  FBLeadGenForm,
  FBLeadGenQuestionForm,
  FBLeadQuestionOption,
} from '../../../../../types';
import { HtmlTooltip } from '../../../../Tooltip';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import {
  DEFAULT_COLOR_THEME,
  QUALIFIED_SHORT_ANSWER_TERMS,
} from '../../../../../utils';
import { leadFormCtas } from '../../../../../utils/helpers/LeadHelpers';

interface LeadFormQualifyingQuestionsProps {
  customQuestions: FBLeadGenQuestionForm[];
  prefilledQuestions: any[];
  formValues: FBLeadGenForm;
  setFormValues: Dispatch<SetStateAction<FBLeadGenForm>>;
}

const LeadFormQualifyingQuestions: React.FC<
  LeadFormQualifyingQuestionsProps
> = ({ formValues, customQuestions, prefilledQuestions, setFormValues }) => {
  const handleOnChangeQuestion = (e: ChangeEventType, index: number) => {
    let temp: FBLeadGenQuestionForm[] = [...customQuestions];

    if (e.target.name === `conditional-logic-${index}`) {
      temp[index].enableConditionalLogic = e.target.value === 'yes';
    } else if (e.target.name === `qualifying-question-${index}`) {
      temp[index].qualified = e.target.value === 'yes';

      if (e.target.value === 'no' && temp[index]?.options?.length > 0) {
        let optionsTemp: FBLeadQuestionOption[] = [];

        temp[index].options.forEach((option: FBLeadQuestionOption) => {
          option.qualified = false;

          optionsTemp = [...optionsTemp, option];
        });

        temp[index].options = [...optionsTemp];
      }

      if ((temp[index]?.options || [])?.length === 0) {
        if (e.target.value === 'yes') {
          // Add terms if the type of question is short answer
          // TODO: User can dynamically select the qualifying terms group
          temp[index].terms = QUALIFIED_SHORT_ANSWER_TERMS;
        } else {
          temp[index].terms = null;
        }
      }
    }

    setFormValues({
      ...formValues,
      questions: [...customQuestions, ...prefilledQuestions],
    });
  };

  const handleOnChangeOption = (
    e: ChangeEventType,
    questionIndex: number,
    optionIndex: number,
  ) => {
    let temp: FBLeadGenQuestionForm[] = [...customQuestions];

    temp[questionIndex].options[optionIndex].qualified =
      e.target.value === 'yes';

    setFormValues({
      ...formValues,
      questions: [...customQuestions, ...prefilledQuestions],
    });
  };

  const handleOnSelectCTA = (cta: string) => {
    setFormValues({ ...formValues, cta });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Grid container spacing={2}>
          {customQuestions?.map(
            (question: FBLeadGenQuestionForm, index: number) => {
              return (
                <Grid item xs={12} key={`question-${index}`}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} pb={0}>
                          <Typography variant="body2" fontWeight="bold">
                            {`Custom Question #${index + 1}`}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={8}
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          {question.label}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{ flexDirection: 'column', display: 'flex' }}
                        >
                          <FormControl>
                            <Typography
                              variant="caption"
                              component="div"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Box component="span" sx={{ marginRight: '5px' }}>
                                Qualifying Question
                              </Box>

                              {(question?.options || []).length === 0 ? (
                                <HtmlTooltip
                                  disableInteractive
                                  title={
                                    <Fragment>
                                      <Box
                                        component="div"
                                        sx={{
                                          fontWeight: 'bold',
                                          marginBottom: '10px',
                                        }}
                                      >
                                        Qualified Terms
                                      </Box>

                                      <Box
                                        component="div"
                                        sx={{
                                          marginBottom: '10px',
                                        }}
                                      >
                                        To qualify a lead we will evaluate the
                                        user's response to determine if they are
                                        a likely candidate for owning a
                                        franchise.
                                      </Box>
                                    </Fragment>
                                  }
                                >
                                  <InfoTwoToneIcon
                                    sx={{
                                      fontSize: '18px',
                                      color: DEFAULT_COLOR_THEME,
                                    }}
                                  />
                                </HtmlTooltip>
                              ) : null}
                            </Typography>

                            <RadioGroup
                              row
                              aria-labelledby="style"
                              name={`qualifying-question-${index}`}
                              value={question.qualified ? 'yes' : 'no'}
                              onChange={(e: ChangeEventType) =>
                                handleOnChangeQuestion(e, index)
                              }
                              defaultValue="no"
                              sx={{ flexDirection: 'row', display: 'flex' }}
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      '& .MuiSvgIcon-root': {
                                        height: 15,
                                        width: 15,
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography variant="body2">Yes</Typography>
                                }
                              />

                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      '& .MuiSvgIcon-root': {
                                        height: 15,
                                        width: 15,
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography variant="body2">No</Typography>
                                }
                              />
                            </RadioGroup>
                          </FormControl>

                          {(question?.options || [])?.length > 0 &&
                          formValues?.fromFacebook ? (
                            <FormControl>
                              <Typography
                                variant="caption"
                                component="div"
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  component="span"
                                  sx={{ marginRight: '5px' }}
                                >
                                  Conditional Logic
                                </Box>
                              </Typography>

                              <RadioGroup
                                row
                                aria-labelledby="style"
                                name={`conditional-logic-${index}`}
                                value={
                                  question.enableConditionalLogic ? 'yes' : 'no'
                                }
                                onChange={(e: ChangeEventType) =>
                                  handleOnChangeQuestion(e, index)
                                }
                                defaultValue="no"
                                sx={{ flexDirection: 'row', display: 'flex' }}
                              >
                                <FormControlLabel
                                  value="yes"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        '& .MuiSvgIcon-root': {
                                          height: 15,
                                          width: 15,
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography variant="body2">Yes</Typography>
                                  }
                                />

                                <FormControlLabel
                                  value="no"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        '& .MuiSvgIcon-root': {
                                          height: 15,
                                          width: 15,
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography variant="body2">No</Typography>
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          ) : null}
                        </Grid>

                        {question.options?.length > 0 ? (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>

                            {question?.options?.map(
                              (
                                option: FBLeadQuestionOption,
                                optionIndex: number,
                              ) => {
                                return (
                                  <Fragment key={`option-${optionIndex}`}>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body2"
                                        fontWeight="bold"
                                      >
                                        {`Option #${optionIndex + 1}`}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      md={8}
                                      sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                      }}
                                    >
                                      {option.value}
                                    </Grid>

                                    {question.qualified ? (
                                      <Grid item xs={12} md={4}>
                                        <FormControl>
                                          <Typography variant="caption">
                                            Qualified
                                          </Typography>

                                          <RadioGroup
                                            row
                                            aria-labelledby="style"
                                            name={`qualifying-option-${index}`}
                                            value={
                                              option.qualified ? 'yes' : 'no'
                                            }
                                            onChange={(e: ChangeEventType) =>
                                              handleOnChangeOption(
                                                e,
                                                index,
                                                optionIndex,
                                              )
                                            }
                                            defaultValue="no"
                                            sx={{
                                              flexDirection: 'row',
                                              display: 'flex',
                                            }}
                                          >
                                            <FormControlLabel
                                              value="yes"
                                              control={
                                                <Radio
                                                  size="small"
                                                  sx={{
                                                    '& .MuiSvgIcon-root': {
                                                      height: 15,
                                                      width: 15,
                                                    },
                                                  }}
                                                />
                                              }
                                              label={
                                                <Typography variant="body2">
                                                  Yes
                                                </Typography>
                                              }
                                            />

                                            <FormControlLabel
                                              value="no"
                                              control={
                                                <Radio
                                                  size="small"
                                                  sx={{
                                                    '& .MuiSvgIcon-root': {
                                                      height: 15,
                                                      width: 15,
                                                    },
                                                  }}
                                                />
                                              }
                                              label={
                                                <Typography variant="body2">
                                                  No
                                                </Typography>
                                              }
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                    ) : null}

                                    {optionIndex !==
                                    question?.options?.length - 1 ? (
                                      <Grid item xs={12}>
                                        <Divider />
                                      </Grid>
                                    ) : null}
                                  </Fragment>
                                );
                              },
                            )}
                          </>
                        ) : null}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            },
          )}
        </Grid>

        {formValues?.fromFacebook ? (
          <Grid container spacing={2} my={2}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Set your Call-to-Action Type
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                id="cta"
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.type}>
                      {option.label}
                    </li>
                  );
                }}
                options={leadFormCtas}
                value={
                  leadFormCtas?.find(
                    (cta: any) => cta.type === formValues?.cta,
                  ) || null
                }
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Call-to-Action Type"
                    placeholder="Select a Call-to-Action type"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                    //error={errors?.users && i === 0 ? true : false}
                    //helperText={errors?.users && i === 0 ? errors?.users : ''}
                  />
                )}
                disableClearable
                onChange={async (e: any, value: any) =>
                  handleOnSelectCTA(value.type)
                }
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default LeadFormQualifyingQuestions;
