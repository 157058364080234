import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import { AuthContext, ExpressThemeContext } from '../../context';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  ADMIN,
  AGENCY,
  AGENCY_SESSION,
  BRAND_FILTER_SESSION,
  BRAND_MANAGER,
  BRAND_SESSION,
  BUDGET_PROVIDER_SESSION,
  CAMPAIGN_PROVIDER_SESSION,
  IS_DIY_ADZ,
  LOCATION_SESSION,
  LOGGED_IN_USER,
  MARKETING_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import {
  Autocomplete,
  Avatar,
  Button,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import styles from '../../assets/styles/components/Navbar/Navbar.module.scss';
import MenuIcon from '@mui/icons-material/Menu';
import { Agency, Brand, ChangeEventType } from '../../types';
import { humanizeString } from '../../utils/stringModifier';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAgencies,
  setAgency,
  setBrand,
  setBrandFilter,
  setBrands,
  setBrandTimezone,
  setBudgetReportProvider,
  setCampaignProvider,
  setDemoMode,
  setLocation,
  setLocations,
  setShowAllBrand,
} from '../../redux/actions';
import { resetSession } from '../../utils/tokenValidator';
import AddIcon from '@mui/icons-material/Add';
import {
  getBrands,
  getBrandsAssociatedLocations,
  getBrandsAssociatedWithAgency,
  getBrandsAssociatedWithManager,
  getBrandsAssociatedWithMarketingManager,
  getBrandsAssociatedWithSalesperson,
} from '../../services/brand';
import { getAgencies } from '../../services/agency';
import { adsProvider } from '../../utils/constants/facebookAds';
import { MdAndDown } from '../../utils/breakpoints';
import TuneIcon from '@mui/icons-material/Tune';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';
import { getSingleDiySetting } from '../../services/diy';
import momentTz from 'moment-timezone';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { IoSettingsOutline, IoLogOutOutline } from 'react-icons/io5';
import { CgProfile } from 'react-icons/cg';
import { PiChalkboardTeacher } from 'react-icons/pi';
import { TbHeartRateMonitor } from 'react-icons/tb';

interface TopNavBarProps {
  drawerWidth: number;
  onOpenDrawer: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onOpenMobileOptions: () => void;
}

const TopNavBar: React.FC<TopNavBarProps> = ({
  drawerWidth,
  onOpenDrawer,
  setLoading,
  onOpenMobileOptions,
}) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [searchParams] = useSearchParams();
  const brandId: string = searchParams.get('brandId');
  const locationId: string = searchParams.get('locationId');
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const pathLocation = useLocation();
  const pathNames = pathLocation.pathname.split('/');
  const isBudget = pathNames.includes('budgets');
  const isBudgetz = pathNames.includes('budgetz');
  const isCampaign =
    pathNames.includes('campaigns') || pathNames.includes('campaignz');
  const isInsightz = pathNames.includes('insightz');
  const { state, dispatch } = useContext(AuthContext);
  const {
    state: { expressTheme },
  } = useContext(ExpressThemeContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAgency = state.role === AGENCY;
  const isSalesperson = state.role === SALESPERSON;
  const isBrandManager = state.role === BRAND_MANAGER;
  const isMarketingManager = state.role === MARKETING_MANAGER;
  const locationBrand = state.authUser?.brand;
  const isFranchisee = locationBrand !== null && locationBrand !== undefined;
  const fullName = state.authUser?.name;
  const capabilities = state.capabilities;
  const withAgencyAccess = state.withAgencyAccess;

  const budgetProvider =
    useSelector((state: any) => state?.budgetReportProvider?.provider) ||
    'facebook';
  const brands: Brand[] = useSelector((state: any) => state.brands?.brands);
  const brand: Brand = useSelector((state: any) => state.brand?.brand);
  const locations: Brand[] = useSelector(
    (state: any) => state.locations?.locations,
  );
  const location: Brand = useSelector((state: any) => state.location?.location);
  const campaignProvider: string = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );
  const agencies: Agency[] =
    useSelector((state: any) => state.agencies?.agencies) || [];
  const agency: Agency = useSelector((state: any) => state.agency?.agency);
  const brandFilter = useSelector(
    (state: any) => state?.brandFilter?.filter?.filter,
  );
  const showAllBrands: boolean = useSelector(
    (state: any) => state?.showAllBrands,
  );
  const demoMode: boolean = useSelector((state: any) => state?.demoMode);
  const budgetProviderSession = localStorage.getItem(BUDGET_PROVIDER_SESSION);
  const sessionFilter = localStorage.getItem(BRAND_FILTER_SESSION);
  const canFetchAgencies =
    isSuperAdmin || isAdmin || (!(isSuperAdmin || isAdmin) && withAgencyAccess);

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [selectedBrand, setSelectedBrand] = useState<Brand>(null);
  const [selectedLocation, setSelectedLocation] = useState<Brand>(null);
  const [selectedAgency, setSelectedAgency] = useState<Agency>(null);
  const [campaignProviders, setCampaignProviders] = useState<string[]>([]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    if (!isInsightz) reduxDispatch(setShowAllBrand(false));
  }, [isInsightz, reduxDispatch]);

  useEffect(() => {
    if (budgetProviderSession) {
      reduxDispatch(setBudgetReportProvider(budgetProviderSession));
    } else {
      reduxDispatch(setBudgetReportProvider('facebook'));
    }
  }, [budgetProviderSession]);

  useEffect(() => {
    if (canFetchAgencies) {
      handleSelectFilter(sessionFilter ? sessionFilter : 'active');
      getAllAgencies(sessionFilter ? sessionFilter : 'active');
    }
  }, [canFetchAgencies]);

  useEffect(() => {
    if (agency) {
      setSelectedAgency(agency);
    }
  }, [agency, brandFilter]);

  useEffect(() => {
    if (!(isSuperAdmin || isAdmin)) {
      getAllBrands();
    }
  }, [isSuperAdmin, isAdmin]);

  useEffect(() => {
    if (brand) {
      setSelectedBrand(brand);
      setProviders(brand);
      if (brand?.allowLocations) {
        getAllLocations(brand);
      }
    }
  }, [brand, isCampaign]);

  useEffect(() => {
    if (location) {
      setSelectedLocation(location);
      setProviders(location);
    } else {
      reduxDispatch(setLocation(null));
      setSelectedLocation(null);
    }
  }, [location]);

  const getAllBrands = async (agency?: Agency, brandFilter?: string) => {
    try {
      setLoading(true);

      let data: Brand[] = [];
      if (IS_DIY_ADZ && !(isSuperAdmin || isAdmin)) {
        let response: any;
        if (withAgencyAccess) {
          if (agency) {
            response = await getBrandsAssociatedWithAgency(
              agency?._id,
              1,
              1000,
              brandFilter ? brandFilter : 'active',
              undefined,
              undefined,
              IS_DIY_ADZ,
            );
          } else {
            response = await getBrands(
              1,
              1000,
              brandFilter ? brandFilter : 'active',
              undefined,
              undefined,
              IS_DIY_ADZ,
            );
          }
        } else {
          response = await getBrandsAssociatedWithSalesperson(
            state.roleBasedId,
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            undefined,
            undefined,
            undefined,
            IS_DIY_ADZ,
          );
        }

        let data = response?.data?.filter(
          (item: Brand) => item?.__type === 'brand',
        );
        const hasAccessBrands = data?.length > 0;

        if (!hasAccessBrands) {
          data = response?.data?.filter(
            (item: Brand) => item?.__type === 'location',
          );
        }

        reduxDispatch(setBrands(data));

        const sessionBrand = localStorage.getItem(BRAND_SESSION);

        if (brandId) {
          const brand = data?.find((item: Brand) => {
            return item?._id === brandId;
          });

          if (agency) {
            setSelectedAgency(null);
            reduxDispatch(setAgency(null));
            reduxDispatch(setLocations([]));
            reduxDispatch(setLocation(null));
            localStorage.removeItem(AGENCY_SESSION);
            localStorage.removeItem(BRAND_SESSION);
            localStorage.removeItem(LOCATION_SESSION);
            await getAllBrands(null, brandFilter);
          }

          if (brand) {
            reduxDispatch(setBrand(brand));
            if (brand) setTimezone(brand);
            localStorage.setItem(BRAND_SESSION, brandId);
          } else {
            reduxDispatch(setBrand(data?.[0]));
            if (data?.[0]) setTimezone(data?.[0]);
            localStorage.setItem(BRAND_SESSION, data?.[0]?._id);
          }
        } else if (sessionBrand) {
          const brand = data?.find((item: Brand) => {
            return item?._id === sessionBrand;
          });

          reduxDispatch(setBrand(brand));
          if (brand) setTimezone(brand);
        } else {
          reduxDispatch(setBrand(data[0]));
          if (data[0]) setTimezone(data[0]);
          localStorage.setItem(BRAND_SESSION, data[0]?._id);
        }
      } else {
        if (isAgency) {
          const response = await getBrandsAssociatedWithAgency(
            state.roleBasedId,
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            undefined,
            undefined,
            IS_DIY_ADZ,
          );

          data = [...response.data];
        } else if (isBrandManager) {
          const response = await getBrandsAssociatedWithManager(
            state.roleBasedId,
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            isFranchisee,
            undefined,
            undefined,
            IS_DIY_ADZ,
          );

          data = [...response.data];
        } else if (isSalesperson) {
          const response = await getBrandsAssociatedWithSalesperson(
            state.roleBasedId,
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            isFranchisee,
            undefined,
            undefined,
            IS_DIY_ADZ,
          );

          data = [...response.data];
        } else if (isMarketingManager) {
          const response = await getBrandsAssociatedWithMarketingManager(
            state.roleBasedId,
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            isFranchisee,
            undefined,
            undefined,
            IS_DIY_ADZ,
          );

          data = [...response.data];
        } else {
          if (agency) {
            const response = await getBrandsAssociatedWithAgency(
              agency?._id,
              1,
              1000,
              brandFilter ? brandFilter : 'active',
              undefined,
              undefined,
              IS_DIY_ADZ,
            );

            data = [...response.data];
          } else {
            const response = await getBrands(
              1,
              1000,
              brandFilter ? brandFilter : 'active',
              undefined,
              undefined,
              IS_DIY_ADZ,
            );

            data = [...response.data];
          }
        }

        reduxDispatch(setBrands(data));
        const sessionBrand = localStorage.getItem(BRAND_SESSION);

        if (brandId) {
          const brand = data.find((item: Brand) => {
            return item?._id === brandId;
          });

          if (agency) {
            setSelectedAgency(null);
            reduxDispatch(setAgency(null));
            reduxDispatch(setLocations([]));
            reduxDispatch(setLocation(null));
            localStorage.removeItem(AGENCY_SESSION);
            localStorage.removeItem(BRAND_SESSION);
            localStorage.removeItem(LOCATION_SESSION);
            await getAllBrands(null, brandFilter);
          }

          if (brand) {
            reduxDispatch(setBrand(brand));
            if (brand) setTimezone(brand);
            localStorage.setItem(BRAND_SESSION, brandId);
          } else {
            reduxDispatch(setBrand(data[0]));
            if (data[0]) setTimezone(data[0]);
            localStorage.setItem(BRAND_SESSION, data[0]?._id);
          }
        } else if (sessionBrand) {
          const brand = data.find((brand: Brand) => {
            return brand?._id === sessionBrand;
          });

          reduxDispatch(setBrand(brand));
          if (brand) setTimezone(brand);
        } else {
          reduxDispatch(setBrand(data[0]));
          if (data[0]) setTimezone(data[0]);
          localStorage.setItem(BRAND_SESSION, data[0]?._id);
        }
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllAgencies = async (brandFilter: string) => {
    try {
      setLoading(true);
      const response = await getAgencies(
        1,
        1000,
        undefined,
        undefined,
        undefined,
        IS_DIY_ADZ,
      );
      reduxDispatch(setAgencies(response.data));

      if (response?.data?.length > 0) {
        const agencySession = localStorage.getItem(AGENCY_SESSION);

        if (agencySession) {
          const agencyData = response.data.find((agency: Agency) => {
            return agency._id === agencySession;
          });

          reduxDispatch(setAgency(agencyData));
          getAllBrands(agencyData, brandFilter);
        } else {
          reduxDispatch(setAgency(null));
          getAllBrands(null, brandFilter);
        }
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setProviders = async (brand: Brand) => {
    try {
      setLoading(true);

      const providers = brand?.platform;
      const providerSession = localStorage.getItem(CAMPAIGN_PROVIDER_SESSION);

      if (isCampaign) {
        if (providerSession) {
          reduxDispatch(setCampaignProvider(providerSession));
        } else {
          reduxDispatch(setCampaignProvider(adsProvider.FACEBOOK));
          localStorage.setItem(CAMPAIGN_PROVIDER_SESSION, adsProvider.FACEBOOK);
        }
      } else {
        setCampaignProviders(providers);

        if (providers?.length > 1) {
          if (providerSession) {
            reduxDispatch(setCampaignProvider(providerSession));
          } else {
            reduxDispatch(setCampaignProvider(adsProvider.FACEBOOK));
            localStorage.setItem(
              CAMPAIGN_PROVIDER_SESSION,
              adsProvider.FACEBOOK,
            );
          }
        } else if (providers?.length > 0) {
          reduxDispatch(setCampaignProvider(providers[0]));
          localStorage.setItem(CAMPAIGN_PROVIDER_SESSION, providers[0]);
        } else {
          localStorage.removeItem(CAMPAIGN_PROVIDER_SESSION);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getAllLocations = async (brand?: Brand, brandFilter?: string) => {
    try {
      setLoading(true);

      const response = await getBrandsAssociatedLocations(
        brand?._id,
        1,
        1000,
        brandFilter ? brandFilter : 'active',
      );

      reduxDispatch(setLocations(response.data));
      const sessionLocation = localStorage.getItem(LOCATION_SESSION);

      if (locationId) {
        const location = response.data.find((item: Brand) => {
          return item?._id === locationId;
        });

        if (location) {
          reduxDispatch(setLocation(location));
          if (location) setTimezone(location);
          localStorage.setItem(BRAND_SESSION, brandId);
        }
      } else if (sessionLocation) {
        const locationData = response.data.find((location: Brand) => {
          return location._id === sessionLocation;
        });

        reduxDispatch(setLocation(locationData));
        if (locationData) setTimezone(locationData);
      } else {
        reduxDispatch(setLocation(null));
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setTimezone = async (brand: Brand) => {
    try {
      let timezone: string = momentTz.tz.guess();

      if (IS_DIY_ADZ) {
        const response = await getSingleDiySetting(brand?._id);

        timezone = response?.data?.fbAdAccount?.timezone || timezone;
      }

      reduxDispatch(setBrandTimezone(timezone));
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSelectProvider = (value: string) => {
    reduxDispatch(setCampaignProvider(value));
    localStorage.setItem(CAMPAIGN_PROVIDER_SESSION, value);
  };

  const handleOnSelectBudgetProvider = (value: string) => {
    reduxDispatch(setBudgetReportProvider(value));
    localStorage.setItem(BUDGET_PROVIDER_SESSION, value);
  };

  const handleOnSelectBrandFilter = (value: string) => {
    reduxDispatch(setBrandFilter({ filter: value }));
    localStorage.setItem(BRAND_FILTER_SESSION, value);
    localStorage.removeItem(BRAND_SESSION);
    getAllBrands(selectedAgency, value);
  };

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const resetReduxSession = () => {
    dispatch({
      type: LOGGED_IN_USER,
      payload: {
        token: null,
        role: null,
        roleBasedId: null,
        user: null,
        capabilities: null,
        withBrandAccess: null,
        withAgencyAccess: null,
        isAuthenticated: false,
      },
    });
    reduxDispatch(setBrands([]));
    reduxDispatch(setBrand(null));
    reduxDispatch(setBrandTimezone(null));
    reduxDispatch(setBrandTimezone(null));
    reduxDispatch(setAgencies([]));
    reduxDispatch(setAgency(null));
    reduxDispatch(setBrandFilter(null));
    reduxDispatch(setCampaignProvider(null));
    reduxDispatch(setBudgetReportProvider(null));
    reduxDispatch(setLocations([]));
    reduxDispatch(setLocation(null));
  };

  const handleSelectBrand = (brand: Brand) => {
    reduxDispatch(setLocation(null));
    reduxDispatch(setLocations([]));
    reduxDispatch(setBrand(brand));
    setTimezone(brand);
    localStorage.setItem(BRAND_SESSION, brand?._id);
  };

  const handleSelectAgency = (agency: Agency, reason: string) => {
    if (reason === 'clear') {
      setSelectedAgency(null);
      reduxDispatch(setAgency(null));
      reduxDispatch(setLocations([]));
      reduxDispatch(setLocation(null));
      localStorage.removeItem(AGENCY_SESSION);
      localStorage.removeItem(BRAND_SESSION);
      localStorage.removeItem(LOCATION_SESSION);
      getAllBrands(null, brandFilter);
    } else {
      setSelectedAgency(agency);
      reduxDispatch(setAgency(agency));
      reduxDispatch(setLocations([]));
      reduxDispatch(setLocation(null));
      localStorage.setItem(AGENCY_SESSION, agency?._id);
      localStorage.removeItem(BRAND_SESSION);
      localStorage.removeItem(LOCATION_SESSION);
      getAllBrands(agency, brandFilter);
    }
  };

  const handleSelectLocation = (location: Brand, reason: string) => {
    if (reason === 'clear') {
      setSelectedLocation(null);
      reduxDispatch(setLocation(null));
      localStorage.removeItem(LOCATION_SESSION);
    } else {
      setSelectedLocation(location);
      reduxDispatch(setLocation(location));
      setTimezone(location);
      localStorage.setItem(LOCATION_SESSION, location?._id);
    }
  };

  const handleSelectFilter = (filter: string) => {
    reduxDispatch(setBrandFilter({ filter }));
  };

  const logout = () => {
    resetSession();
    resetReduxSession();
    navigate('/login');
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigate('/profile');
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <CgProfile size={25} />
        </IconButton>

        <p>Profile</p>
      </MenuItem>

      {isAdmin || isSuperAdmin ? (
        <MenuItem
          onClick={() => {
            navigate(IS_DIY_ADZ ? '/settingz' : '/settings');
            handleMobileMenuClose();
          }}
        >
          <IconButton
            size="large"
            aria-label="settings user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <IoSettingsOutline size={25} />
          </IconButton>
          <p>{IS_DIY_ADZ ? 'Settingz' : 'Settings'}</p>
        </MenuItem>
      ) : null}

      {(isAdmin || isSuperAdmin) && IS_DIY_ADZ ? (
        <MenuItem
          onClick={() => {
            navigate('monitoring', { replace: true });
            handleMobileMenuClose();
          }}
        >
          <IconButton
            size="large"
            aria-label="settings user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <TbHeartRateMonitor size={25} />
          </IconButton>
          <p>Monitoring</p>
        </MenuItem>
      ) : null}

      {isAdmin || isSuperAdmin ? (
        <MenuItem onClick={() => reduxDispatch(setDemoMode(!demoMode))}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <PiChalkboardTeacher size={25} />
          </IconButton>

          <p>
            Demo:{' '}
            <Box
              component="span"
              sx={{
                fontWeight: 'bold',
                color: demoMode ? DEFAULT_COLOR_THEME : 'red',
              }}
            >{`${demoMode ? 'On' : 'Off'}`}</Box>
          </p>
        </MenuItem>
      ) : null}

      <MenuItem onClick={logout}>
        <IconButton
          size="large"
          aria-label="logout"
          aria-controls="logout"
          aria-haspopup="true"
          color="inherit"
        >
          <IoLogOutOutline size={25} />
        </IconButton>

        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate('/profile');
          handleMobileMenuClose();
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <CgProfile size={25} />
        </IconButton>

        <p>Profile</p>
      </MenuItem>

      {(isAdmin || isSuperAdmin) && IS_DIY_ADZ ? (
        <MenuItem
          onClick={() => {
            navigate('monitoring', { replace: true });
            handleMobileMenuClose();
          }}
        >
          <IconButton
            size="large"
            aria-label="settings user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <TbHeartRateMonitor size={25} />
          </IconButton>
          <p>Monitoring</p>
        </MenuItem>
      ) : null}

      {isAdmin || isSuperAdmin ? (
        <MenuItem onClick={() => reduxDispatch(setDemoMode(!demoMode))}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <PiChalkboardTeacher size={25} />
          </IconButton>

          <p>
            Demo:{' '}
            <Box
              component="span"
              sx={{
                fontWeight: 'bold',
                color: demoMode ? DEFAULT_COLOR_THEME : 'red',
              }}
            >{`${demoMode ? 'On' : 'Off'}`}</Box>
          </p>
        </MenuItem>
      ) : null}

      <MenuItem onClick={logout}>
        <IconButton
          size="large"
          aria-label="logout"
          aria-controls="logout"
          aria-haspopup="true"
          color="inherit"
        >
          <IoLogOutOutline size={25} />
        </IconButton>

        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          boxShadow: 'none',
          backgroundColor: '#f5f5f5',
          color: 'red',
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
        className={styles.navbar}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onOpenDrawer}
            sx={{
              mr: 2,
              display: { md: 'none' },
              color: expressTheme.properties.bgColor,
            }}
          >
            <MenuIcon />
          </IconButton>

          {MdAndDown() ? (
            <Typography
              variant="body1"
              color="#484848"
              fontWeight="bold"
              sx={{ marginRight: '5px' }}
            >
              {demoMode
                ? 'Demo'
                : showAllBrands
                ? 'All Brands'
                : location
                ? location?.brand
                : brand?.brand}
            </Typography>
          ) : (
            <>
              {isAdmin ||
              isSuperAdmin ||
              (brands?.length > 1 && !(isBudgetz || isBudget)) ||
              (!(isBudgetz || isBudget) &&
                withAgencyAccess &&
                capabilities.createBrands) ? (
                <Autocomplete
                  id="brands"
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={(option as any)._id}>
                        {demoMode
                          ? 'Demo'
                          : showAllBrands
                          ? 'All Brands'
                          : (option as any).brand}
                      </li>
                    );
                  }}
                  options={
                    demoMode
                      ? ['Demo']
                      : showAllBrands
                      ? ['All Brands']
                      : brands?.sort(
                          (a, b) => -b.brand?.localeCompare(a.brand),
                        ) || []
                  }
                  value={
                    demoMode
                      ? 'Demo'
                      : showAllBrands
                      ? 'All Brands'
                      : selectedBrand
                  }
                  getOptionLabel={(option) =>
                    demoMode
                      ? 'Demo'
                      : showAllBrands
                      ? 'All Brands'
                      : (option as any)?.brand
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Brands"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '50px',
                          backgroundColor: 'white',
                          legend: {
                            marginLeft: '10px',
                          },
                        },
                        '& .MuiAutocomplete-inputRoot': {
                          paddingLeft: '20px !important',
                          borderRadius: '50px',
                        },
                        '& .MuiInputLabel-outlined': {
                          backgroundColor: 'none',
                        },
                        '& .MuiInputLabel-shrink': {
                          paddingRight: 0,
                          marginLeft: '10px',
                        },
                        input: {
                          '&::placeholder': {
                            textOverflow: 'ellipsis !important',
                            color: 'black',
                            fontWeight: 'bold',
                          },
                        },
                      }}
                    />
                  )}
                  disableClearable={!showAllBrands}
                  sx={{
                    sm: { width: '100%' },
                    width: '300px',
                    marginRight: '5px',
                  }}
                  onChange={async (e: any, value: any, reason) => {
                    if (!showAllBrands) {
                      handleSelectBrand(value);
                      navigate(IS_DIY_ADZ ? '/reportz/kpiz' : '/reports');
                    } else {
                      if (reason === 'clear') {
                        reduxDispatch(setShowAllBrand(false));
                      }
                    }
                  }}
                  PaperComponent={({ children }) => {
                    return (
                      <Paper>
                        {children}

                        {isInsightz && !showAllBrands ? (
                          <Box component="div" sx={{ textAlign: 'center' }}>
                            <Button
                              type="button"
                              startIcon={
                                <ListOutlinedIcon sx={{ fontWeight: 'bold' }} />
                              }
                              onMouseDown={() =>
                                reduxDispatch(setShowAllBrand(true))
                              }
                              variant="text"
                              sx={{
                                color: DEFAULT_COLOR_THEME,
                                fontWeight: 'bold',
                              }}
                            >
                              Show All Brands
                            </Button>
                          </Box>
                        ) : null}

                        {!(isSuperAdmin || isAdmin) &&
                        !capabilities?.createBrands ? null : (
                          <Box component="div" sx={{ textAlign: 'center' }}>
                            <Button
                              type="button"
                              startIcon={
                                <AddIcon sx={{ fontWeight: 'bold' }} />
                              }
                              onMouseDown={() =>
                                navigate(
                                  `/${IS_DIY_ADZ ? 'brandz' : 'brands'}/create`,
                                )
                              }
                              variant="text"
                              sx={{
                                color: DEFAULT_COLOR_THEME,
                                fontWeight: 'bold',
                              }}
                            >
                              Add New Brand
                            </Button>
                          </Box>
                        )}
                      </Paper>
                    );
                  }}
                />
              ) : brands?.length === 1 && !(isBudgetz || isBudget) ? (
                <Typography
                  variant="h6"
                  color="#484848"
                  fontWeight="bold"
                  sx={{ marginRight: '5px' }}
                >
                  {brands[0].brand}
                </Typography>
              ) : null}

              {brand?.allowLocations && !(isBudgetz || isBudget) ? (
                <Autocomplete
                  id="locations"
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option?._id}>
                        {option.brand}
                      </li>
                    );
                  }}
                  options={locations?.sort(
                    (a, b) => -b.brand?.localeCompare(a.brand),
                  )}
                  value={selectedLocation}
                  getOptionLabel={(option) => option?.brand}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={`Select ${humanizeString(
                        `${brand?.locationLabel || 'location'}`,
                      )}`}
                      label={humanizeString(
                        `${brand?.locationLabel || 'location'}s`,
                      )}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '50px',
                          backgroundColor: 'white',
                          legend: {
                            marginLeft: '10px',
                          },
                        },
                        '& .MuiAutocomplete-inputRoot': {
                          paddingLeft: '20px !important',
                          borderRadius: '50px',
                        },
                        '& .MuiInputLabel-outlined': {
                          backgroundColor: 'none',
                        },
                        '& .MuiInputLabel-shrink': {
                          paddingRight: 0,
                          marginLeft: '10px',
                        },
                        input: {
                          '&::placeholder': {
                            textOverflow: 'ellipsis !important',
                            color: 'black',
                            fontWeight: 'bold',
                          },
                        },
                      }}
                    />
                  )}
                  sx={{
                    sm: { width: '100%' },
                    width: '300px',
                    marginRight: '5px',
                  }}
                  onChange={async (e: any, value: any, reason, detail) => {
                    handleSelectLocation(value, reason);
                    navigate(IS_DIY_ADZ ? '/reportz/kpiz' : '/reports');
                  }}
                  PaperComponent={({ children }) => {
                    return (
                      <Paper>
                        {children}

                        {isSuperAdmin ||
                        isAdmin ||
                        capabilities?.addLocations ? (
                          <Box component="div" sx={{ textAlign: 'center' }}>
                            <Button
                              type="button"
                              startIcon={
                                <AddIcon sx={{ fontWeight: 'bold' }} />
                              }
                              onMouseDown={() =>
                                navigate(
                                  `/${
                                    IS_DIY_ADZ ? 'locationz' : 'locations'
                                  }/create`,
                                )
                              }
                              variant="text"
                              sx={{
                                color: DEFAULT_COLOR_THEME,
                                fontWeight: 'bold',
                              }}
                            >
                              {`Add New ${humanizeString(
                                brand?.locationLabel || 'location',
                              )}`}
                            </Button>
                          </Box>
                        ) : null}
                      </Paper>
                    );
                  }}
                />
              ) : locations?.length === 1 && !(isBudgetz || isBudget) ? (
                <Typography
                  variant="h6"
                  color="#484848"
                  fontWeight="bold"
                  sx={{ marginRight: '5px' }}
                >
                  {locations[0].brand}
                </Typography>
              ) : null}

              {(campaignProviders?.length > 1 && campaignProvider) ||
              isCampaign ||
              ((isBudgetz || isBudget) &&
                (isSuperAdmin ||
                  isAdmin ||
                  (!(isSuperAdmin || isAdmin) && capabilities.viewBudgets))) ? (
                <TextField
                  id="campaignProviders"
                  select
                  sx={{
                    sm: { width: '100%' },
                    width: '150px',
                    marginRight: '5px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '50px',
                      backgroundColor: 'white',
                      legend: {
                        marginLeft: '10px',
                      },
                    },
                    '& .MuiSelect-select': {
                      marginLeft: '10px',
                    },
                    '& .MuiInputLabel-outlined': {
                      backgroundColor: 'none',
                    },
                    '& .MuiInputLabel-shrink': {
                      paddingRight: 0,
                      marginLeft: '10px',
                    },
                    input: {
                      '&::placeholder': {
                        textOverflow: 'ellipsis !important',
                        color: 'black',
                        fontWeight: 'bold',
                      },
                    },
                  }}
                  label="Provider"
                  value={
                    isBudgetz || isBudget ? budgetProvider : campaignProvider
                  }
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  onChange={(e: ChangeEventType) => {
                    isBudgetz || isBudget
                      ? handleOnSelectBudgetProvider(e.target.value)
                      : handleOnSelectProvider(e.target.value);
                  }}
                >
                  {['facebook', 'google'].map((option: string) => (
                    <MenuItem key={option} value={option}>
                      {humanizeString(option)}
                    </MenuItem>
                  ))}
                </TextField>
              ) : null}

              {canFetchAgencies ? (
                <>
                  {((isAdmin || isSuperAdmin) && agencies?.length > 0) ||
                  (!(isAdmin || isSuperAdmin) &&
                    withAgencyAccess &&
                    agencies?.length > 1) ? (
                    <Autocomplete
                      id="agencies"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option._id}>
                            {option.agency}
                          </li>
                        );
                      }}
                      options={agencies?.sort(
                        (a, b) => -b.agency?.localeCompare(a.agency),
                      )}
                      value={selectedAgency}
                      getOptionLabel={(option) => option?.agency}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="All Agencies"
                          label="Agencies"
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '50px',
                              backgroundColor: 'white',
                              legend: {
                                marginLeft: '10px',
                              },
                            },
                            '& .MuiAutocomplete-inputRoot': {
                              paddingLeft: '20px !important',
                              borderRadius: '50px',
                            },
                            '& .MuiInputLabel-outlined': {
                              backgroundColor: 'none',
                            },
                            '& .MuiInputLabel-shrink': {
                              paddingRight: 0,
                              marginLeft: '10px',
                            },
                            input: {
                              '&::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: 'black',
                                fontWeight: 'bold',
                              },
                            },
                          }}
                        />
                      )}
                      sx={{
                        sm: { width: '100%' },
                        width: '300px',
                        marginRight: '5px',
                      }}
                      onChange={async (e: any, value: any, reason, detail) => {
                        handleSelectAgency(value, reason);

                        if (!(isBudgetz || isBudget)) {
                          navigate(IS_DIY_ADZ ? '/reportz/kpiz' : '/reports');
                        }
                      }}
                    />
                  ) : !(isSuperAdmin || isAdmin) &&
                    withAgencyAccess &&
                    agencies?.length === 1 ? (
                    <Typography
                      variant="h6"
                      color="#484848"
                      fontWeight="bold"
                      sx={{ marginRight: '5px' }}
                    >
                      {`${
                        brands?.length === 1
                          ? ` / ${agencies[0].agency}`
                          : agencies[0].agency
                      }`}
                    </Typography>
                  ) : null}

                  {brandFilter && !(isBudgetz || isBudget) ? (
                    <TextField
                      id="filters"
                      select
                      sx={{
                        sm: { width: '100%' },
                        width: '150px',
                        marginRight: '5px',
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '50px',
                          backgroundColor: 'white',
                          legend: {
                            marginLeft: '10px',
                          },
                        },
                        '& .MuiInputLabel-outlined': {
                          backgroundColor: 'none',
                        },
                        '& .MuiInputLabel-shrink': {
                          paddingRight: 0,
                          marginLeft: '10px',
                        },
                        '& .MuiSelect-select': {
                          marginLeft: '10px',
                        },
                        input: {
                          '&::placeholder': {
                            textOverflow: 'ellipsis !important',
                            color: 'black',
                            fontWeight: 'bold',
                          },
                        },
                      }}
                      label="Brand Filter"
                      value={brandFilter}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      onChange={(e: ChangeEventType) => {
                        handleOnSelectBrandFilter(e.target.value);
                      }}
                    >
                      {['active', 'inactive'].map((option: string) => (
                        <MenuItem key={option} value={option}>
                          {humanizeString(option)}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : null}
                </>
              ) : null}
            </>
          )}

          <Box sx={{ flexGrow: 1 }} />

          {xsOnly ? (
            <Box>
              <IconButton
                size="large"
                edge="end"
                onClick={onOpenMobileOptions}
                sx={{ color: expressTheme.properties.bgColor }}
              >
                <TuneIcon />
              </IconButton>
            </Box>
          ) : null}

          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              sx={{ color: expressTheme.properties.bgColor }}
            >
              <Avatar
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                sx={{
                  bgcolor: expressTheme.properties.bgColor,
                  width: 30,
                  height: 30,
                }}
                src={`${state.authUser?.imageUrl}`}
              >
                {state.authUser?.imageUrl === null ? <AccountCircle /> : null}
              </Avatar>

              <Typography
                variant="body2"
                color="#484848"
                fontWeight="bold"
                ml={1}
              >
                {fullName}
              </Typography>
            </IconButton>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              sx={{
                color: expressTheme.properties.bgColor,
                paddingRight: '0px',
              }}
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}

      {renderMenu}
    </Box>
  );
};

export default TopNavBar;
