import {
  DataGridPremium,
  DataGridPremiumProps,
  GridGroupNode,
} from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { CustomLoadingOverlay } from '../../Loading';
import {
  lookalikeAudienceReportColumns,
  lookalikeAudienceReportGroupingColumn,
} from './LookalikeAudienceReportTableColumns';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LookalikeAudienceReport } from '../../../types/IAudience';
import PopupModal from '../../Modal';
import ModalHeader from '../../Modal/ModalHeader';
import { PrimaryButton } from '../../Buttons';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';
import { updateFbAdset } from '../../../services/ads';

interface LookalikeAudienceReportTableProps {
  loading: boolean;
  data: LookalikeAudienceReport[];
}

const LookalikeAudienceReportTable: React.FC<
  LookalikeAudienceReportTableProps
> = ({ loading, data }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) =>
    row.path;

  const [stopLoading, setStopLoading] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [openConfirmStop, setOpenConfirmStop] = useState<boolean>(false);
  const [selectedAdset, setSelectedAdset] =
    useState<LookalikeAudienceReport>(null);

  const handleOnOpenConfirmStop = (data: LookalikeAudienceReport) => {
    setOpenConfirmStop(true);
    setSelectedAdset(data);
  };

  const handleOnCloseConfirmStop = () => {
    setOpenConfirmStop(false);
  };

  const rows = data.map((x: LookalikeAudienceReport) => {
    return { ...x, openConfirmStop: handleOnOpenConfirmStop, data: x };
  });

  const stopAdset = async () => {
    try {
      setStopLoading(true);

      const response = await updateFbAdset(
        selectedAdset?.brandId,
        selectedAdset?.adsetId,
        {
          status: 'PAUSED',
        },
      );

      dispatch(toggleAlert({ toggle: true, message: response.message }));
      handleOnCloseConfirmStop();
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setStopLoading(false);
    }
  };

  return (
    <Box
      sx={{
        '& .campaign': {
          backgroundColor: '#B6c61a',
        },
        '& .adset': {
          backgroundColor: '#b9d870',
        },
      }}
    >
      <DataGridPremium
        treeData
        groupingColDef={lookalikeAudienceReportGroupingColumn(
          smAndDown,
          expandedKeys,
          setExpandedKeys,
          rows,
        )}
        getTreeDataPath={getTreeDataPath}
        rows={rows}
        columns={lookalikeAudienceReportColumns()}
        autoHeight
        density="compact"
        hideFooterSelectedRowCount
        loading={loading}
        className={styles.table}
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        isGroupExpandedByDefault={(row: GridGroupNode) =>
          expandedKeys.includes(row.groupingKey as string)
        }
        hideFooterRowCount
        hideFooter
        /*
      getCellClassName={(params: any) => {
        return cellBgFormatter(params);
      }}
        */
      />

      <PopupModal open={openConfirmStop} handleClose={handleOnCloseConfirmStop}>
        <Grid container spacing={2} sx={{ width: '400px' }}>
          <Grid item xs={12}>
            <ModalHeader title="Confirm Stop" />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <Typography variant="body1">
              Are you sure you want to stop this Ad Set?
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }} mt={1}>
              {`${selectedAdset?.adsetName} (${selectedAdset?.adsetId})`}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <PrimaryButton
              title="Confirm"
              type="button"
              handleOnClick={stopAdset}
              loading={stopLoading}
            />
          </Grid>
        </Grid>
      </PopupModal>
    </Box>
  );
};

export default LookalikeAudienceReportTable;
