import { Box, Grid } from '@mui/material';
import React from 'react';
import { GoogleAssetsContainer } from '../../../../utils/constants/google';
import { GoogleLogin } from '@react-oauth/google';
import GoogleAdsAccountForm from '../../GoogleAdsAccountForm';
import { adsProvider } from '../../../../utils/constants/facebookAds';

interface AssetGoogleAccountProps {
  brand: any;
  googleAdAccounts: any;
  adAccounts: any;
  handleSyncGoogleCustomerIds: any;
  onSelectAdsAccount: any;
  onRemoveAdAccount: any;
  connectedAccountsIds: any;
}

const AssetGoogleAccount: React.FC<AssetGoogleAccountProps> = ({
  brand,
  googleAdAccounts,
  adAccounts,
  handleSyncGoogleCustomerIds,
  onSelectAdsAccount,
  onRemoveAdAccount,
  connectedAccountsIds,
}) => {
  return (
    <Box sx={GoogleAssetsContainer}>
      <Grid item xs={12}>
        <GoogleAdsAccountForm
          brand={brand}
          provider={adsProvider.GOOGLE}
          handleSync={handleSyncGoogleCustomerIds}
          googleAdAccounts={googleAdAccounts}
          adAccounts={adAccounts}
          onSelectAdsAccount={onSelectAdsAccount}
          onRemoveAdAccount={onRemoveAdAccount}
          connectedAccountsIds={connectedAccountsIds}
        />
      </Grid>
      <Box sx={{ maxWidth: 180, display: `none` }}>
        <GoogleLogin
          auto_select
          cancel_on_tap_outside
          onSuccess={(credentialResponse) => {}}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </Box>
    </Box>
  );
};

export default AssetGoogleAccount;
