import { GoogleAdsMetricsData, GoogleAdsMetricsFields } from '../../types';
import {
  isLeadAds,
  isMessengerAds,
  isSalesAds,
  isTrafficAds,
  leadDescription,
} from '../constants/facebookAds';

// We set the Max Date Range based on Facebook
// Facebook - 90 days
// Google - 93 days
// https://www.facebook.com/business/help/507659076985205?id=419087378825961
// https://support.google.com/analytics/answer/3123951?hl=en#:~:text=Date%20ranges,days%20from%20the%20start%20date.
export const REPORTS_MAX_DATE_RANGE: number = 90;

export const buildRowData = (
  isCampaign: boolean,
  lead: number,
  isAds: boolean,
  isSummary: boolean,
  isAdset: boolean,
  campaigns?: any[],
  ads?: any[],
  adsets?: any[],
  leadAds?: any,
  messengerAds?: any,
  trafficAds?: any,
  salesAds?: any,
) => {
  if (isAdset || isAds || isCampaign) {
    const data = () => {
      if (isCampaign) return campaigns;

      if (isAdset) return adsets;

      return ads;
    };

    const rows = data().map((row: any) => {
      const {
        ad_id,
        ad_name,
        adset_id,
        adset_name,
        campaign_id,
        campaign_name,
        clicks,
        impressions,
        spend,
        cpm,
        objective,
        cpc,
        qualifiedLead,
        status,
      } = row;

      let leadAction: any;

      if (row.actions) {
        leadAction = row?.actions.find((action: any) => {
          return action?.action_type === `${leadDescription(objective)}`;
        });
      }

      const leadCount = () => {
        return leadAction?.value || 0;
      };

      return {
        id: isCampaign ? campaign_id : isAdset ? adset_id : ad_id,
        name: isCampaign ? campaign_name : isAdset ? adset_name : ad_name,
        clicks,
        impressions,
        cpm: Number(cpm).toFixed(2),
        // Named Opt-Ins on the Table column if Messenger Ads
        lead: leadCount(),
        // Named CPOI (Cost per Opt-Ins) on the Table column if Messenger Ads
        cpl: Number(leadCount() > 0 ? spend / leadCount() : 0).toFixed(2),
        spend,
        cpc: cpc || 0,
        ql: qualifiedLead,
        cpql: Number(qualifiedLead > 0 ? spend / qualifiedLead : 0).toFixed(2),
        objective,
        status,
      };
    });

    return rows.sort((a, b) => {
      return b.lead - a.lead;
    });
  }

  if (isSummary) {
    let clicks = 0;
    let impressions = 0;
    let leadCount = 0;
    let spend = 0;
    let qualifiedLead = 0;
    let objective: string = '';
    if (leadAds && !!leadAds?.campaigns) {
      leadAds.campaigns.forEach((ad: any) => {
        clicks += parseInt(ad?.clicks || 0);
        impressions += parseInt(ad?.impressions || 0);
        spend += parseFloat(ad?.spend || 0);
        qualifiedLead += ad?.qualifiedLead;
        objective = ad?.objective;
        if (isLeadAds(ad?.objective)) {
          if (ad?.actions) {
            const leadAction = ad?.actions.find((action: any) => {
              return action.action_type === leadDescription(ad?.objective);
            });

            leadCount += parseInt(leadAction?.value || 0);
          }
        }
      });
    }

    if (messengerAds && !!messengerAds?.campaigns) {
      messengerAds.campaigns.forEach((ad: any) => {
        clicks += parseInt(ad?.clicks || 0);
        impressions += parseInt(ad?.impressions || 0);
        spend += parseFloat(ad?.spend || 0);
        if (isMessengerAds(ad?.objective)) {
          if (ad?.actions) {
            const leadAction = ad?.actions.find((action: any) => {
              return action.action_type === leadDescription(ad?.objective);
            });

            leadCount += parseInt(leadAction?.value || 0);
          }
        }
      });
    }

    if (trafficAds && !!trafficAds?.campaigns) {
      trafficAds.campaigns.forEach((ad: any) => {
        clicks += parseInt(ad?.clicks || 0);
        impressions += parseInt(ad?.impressions || 0);
        spend += parseFloat(ad?.spend || 0);
        if (isTrafficAds(ad?.objective)) {
          if (ad?.actions) {
            const leadAction = ad?.actions.find((action: any) => {
              return action.action_type === leadDescription(ad?.objective);
            });

            leadCount += parseInt(leadAction?.value || 0);
          }
        }
      });
    }

    if (salesAds && !!salesAds?.campaigns) {
      salesAds.campaigns.forEach((ad: any) => {
        clicks += parseInt(ad?.clicks || 0);
        impressions += parseInt(ad?.impressions || 0);
        spend += parseFloat(ad?.spend || 0);
        if (isSalesAds(ad?.objective)) {
          if (ad?.actions) {
            const leadAction = ad?.actions.find((action: any) => {
              return action.action_type === leadDescription(ad?.objective);
            });

            leadCount += parseInt(leadAction?.value || 0);
          }
        }
      });
    }

    return [
      {
        clicks,
        impressions,
        cpm: impressions > 0 ? (spend / impressions) * 1000 : 0,
        lead: leadCount + lead,
        cpl: Number(
          leadCount + lead > 0 ? spend / (leadCount + lead) : 0,
        ).toFixed(2),
        spend: Number(spend).toFixed(2),
        cpc: clicks > 0 ? spend / clicks : 0,
        ql: qualifiedLead,
        cpql: Number(qualifiedLead > 0 ? spend / qualifiedLead : 0).toFixed(2),
        objective,
      },
    ];
  }

  return [];
};

export const buildLeadTableRows = (showQL: boolean) => {
  let columns = ['Clicks', 'CPC', 'Impressions', 'CPM', 'Leads', 'CPL'];

  if (showQL) {
    columns = [...columns, 'QLeads', 'CPQL'];
  }

  columns = [...columns, 'Cost', ''];

  return columns;
};

export const buildMessengerLeadsTableRows = () => {
  return ['Clicks', 'CPC', 'Impressions', 'CPM', 'Opt-Ins', 'CPOI', 'Cost', ''];
};

export const buildTrafficTableRows = () => {
  return ['Clicks', 'CPC', 'Impressions', 'CPM', 'Results', 'CPL', 'Cost', ''];
};

export const buildSalesTableRows = () => {
  return ['Clicks', 'CPC', 'Impressions', 'CPM', 'Results', 'CPS', 'Cost', ''];
};

export const buildGoogleTableRows = () => {
  return ['Clicks', 'CPC', 'Impressions', 'CPM', 'Leads', 'CPL', 'Cost'];
};

export const buildCampaignOverviewRows = (
  type: string,
  campaignType: string,
  showQL?: boolean,
) => {
  const isLeadAds = campaignType === 'lead';
  const isTrafficAds = campaignType === 'traffic';

  if (isLeadAds) {
    return [`${type} Name`, ...buildLeadTableRows(showQL)];
  }

  if (isTrafficAds) {
    return [`${type} Name`, ...buildTrafficTableRows()];
  }

  if (isSalesAds) {
    return [`${type} Name`, ...buildSalesTableRows()];
  }

  return [`${type} Name`, ...buildMessengerLeadsTableRows()];
};

export const buildGoogleMetricsData = (
  metrics: GoogleAdsMetricsData[],
  isSummary: boolean,
  isCampaign: boolean,
  isAdGroups: boolean,
  isAds: boolean,
) => {
  if (isSummary) {
    let clicks = 0;
    let impressions = 0;
    let conversions = 0;
    let cost = 0;

    metrics.forEach((data: GoogleAdsMetricsData) => {
      const { campaigns } = data;
      clicks += campaigns.clicks;
      impressions += campaigns.impressions;
      conversions += campaigns.conversions;
      cost += campaigns.spend;
    });

    return [
      {
        clicks,
        impressions,
        cpm: impressions > 0 ? (cost / impressions) * 1000 : 0,
        conversions,
        cpl: conversions > 0 ? cost / conversions : 0,
        spend: cost,
        cpc: clicks > 0 ? cost / clicks : 0,
      },
    ];
  }

  if (isCampaign) {
    return metrics.map((data: GoogleAdsMetricsData) => {
      const { campaigns } = data;

      return {
        name: campaigns.name,
        clicks: campaigns.clicks,
        impressions: campaigns.impressions,
        cpm: campaigns.cpm,
        conversions: campaigns.conversions,
        cpc: campaigns.cpc,
        spend: campaigns.spend,
        cpl: campaigns.cpl,
      };
    });
  }

  if (isAdGroups || isAds) {
    let rows: any[] = [];
    let data: GoogleAdsMetricsFields[] = [];

    metrics.forEach((metric: GoogleAdsMetricsData) => {
      const { adGroups, ads } = metric;

      if (isAdGroups) data = adGroups;

      if (isAds) data = ads;

      data.forEach((ads: GoogleAdsMetricsFields) => {
        const obj: any = {
          name: ads.name,
          clicks: ads.clicks,
          impressions: ads.impressions,
          cpm: ads.cpm,
          conversions: ads.conversions,
          cpc: ads.cpc,
          spend: ads.spend,
          cpl: ads.cpl,
        };

        rows = [...rows, obj];
      });
    });

    return rows;
  }
};

export const computeCpm = (spend: number, impressions: number) => {
  return impressions > 0 ? (spend / impressions) * 1000 : 0;
};

export const computeCpl = (spend: number, leadCount: number) => {
  return leadCount > 0 ? spend / leadCount : 0;
};

export const computeCpc = (spend: number, clicks: number) => {
  return clicks > 0 ? spend / clicks : 0;
};

export const computeCpql = (spend: number, qleads: number) => {
  return qleads > 0 ? spend / qleads : 0;
};
