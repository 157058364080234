import axios from 'axios';
import { apiBaseUrl } from './api';
import { Alert } from '../types/IAlert';

export const getAlerts = async (brandId: string, active?: boolean) => {
  let params: any = { brandId };

  if (active) {
    params = { ...params, active };
  }
  const data = await axios.get(`${apiBaseUrl}/alerts`, { params });

  return data?.data;
};

export const updateAlert = async (alertId: string, body: Alert) => {
  const data = await axios.put(`${apiBaseUrl}/alerts/${alertId}`, body);

  return data?.data;
};
