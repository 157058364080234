import React, { useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Dashboard.module.scss';
import PageHeader from '../../components/PageHeader';
import {
  Brand,
  TrendzAverageReport,
  TrendzAverageWeeklyReport,
  TrendzIndustryReport,
} from '../../types';
import { Box, Card, CardContent, Grid } from '@mui/material';
import BarChart from '../../components/Charts/BarChart';
import {
  AIADZ_LIGHT_GREEN_HEX,
  AIADZ_YELLOW_HEX,
  DEFAULT_COLOR_THEME,
} from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTrendzReport } from '../../services/report';
import CircularLoading from '../../components/CircularLoading';
import DateRangePicker from '../../components/DateAndTimePickers/DateRangePicker';
import moment, { Moment } from 'moment';
import { setEndRange, setStartRange } from '../../redux/actions';
import LineChart from '../../components/Charts/LineChart';
import {
  computeCpc,
  computeCpl,
  computeCpm,
  computeCpql,
} from '../../utils/helpers/BrandAnalyticsHelper';
import { orderArrayOfObject } from '../../utils/arrayFormatter';
import Spinner from '../../components/Spinner';

const Trendz: React.FC = () => {
  const dispatch = useDispatch();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const startRange = useSelector((state: any) => state.startRange.date);
  const endRange = useSelector((state: any) => state.endRange.date);
  const [report, setReport] = useState<TrendzAverageReport>({
    weeklyAverageCpl: {
      brandCpl: [],
      otherBrandCpl: [],
    },
    weeklyAverageCpc: {
      brandCpc: [],
      otherBrandCpc: [],
    },
    weeklyAverageCpm: {
      brandCpm: [],
      otherBrandCpm: [],
    },
    weeklyAverageCpql: {
      brandCpql: [],
      otherBrandCpql: [],
    },
  });
  const [industryReport, setIndustryReport] = useState<TrendzIndustryReport>({
    brandWeeklyCpl: [],
    restaurantWeeklyCpl: [],
    fitnessWeeklyCpl: [],
    homeServicesWeeklyCpl: [],
    retailWeeklyCpl: [],
    businessServicesWeeklyCpl: [],
    educationWeeklyCpl: [],
    seniorCareWeeklyCpl: [],
    otherWeeklyCpl: [],
  });
  const [leadsReport, setLeadsReport] = useState<TrendzAverageWeeklyReport[]>(
    [],
  );
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [trendzIndustryLoading, setTrendzIndustryLoading] =
    useState<boolean>(false);
  const [trendzLeadsLoading, setTrendzLeadsLoading] = useState<boolean>(false);

  useEffect(() => {
    if ((location || brand) && startRange && endRange) {
      getTrendz();
      getIndustyTrendz();
      getLeadsTrendz();
    }
  }, [location, brand, startRange, endRange]);

  const getTrendz = async () => {
    try {
      setFetchLoading(true);
      const response = await getTrendzReport(
        (location || brand)?._id,
        startRange,
        endRange,
        'weekly_average',
      );

      setReport(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getIndustyTrendz = async () => {
    try {
      setTrendzIndustryLoading(true);
      const response = await getTrendzReport(
        (location || brand)?._id,
        startRange,
        endRange,
        'industries',
      );

      setIndustryReport(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setTrendzIndustryLoading(false);
    }
  };

  const getLeadsTrendz = async () => {
    try {
      setTrendzLeadsLoading(true);
      const response = await getTrendzReport(
        (location || brand)?._id,
        startRange,
        endRange,
        'leads',
      );

      setLeadsReport(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setTrendzLeadsLoading(false);
    }
  };

  const handleChangeDateRange = (dates: Moment[] | []) => {
    dispatch(setStartRange(dates[0]));
    dispatch(setEndRange(dates[1]));
  };

  const buildLineChartData = (params: {
    weeklyData: TrendzAverageWeeklyReport[];
    insightKey: 'leads' | 'impressions' | 'clicks' | 'qualifiedLeads';
    isAverage?: boolean;
  }) => {
    const { weeklyData, insightKey, isAverage } = params;
    const orderedData = orderArrayOfObject(weeklyData, 'dateStart', 'asc');

    return orderedData.map((data: TrendzAverageWeeklyReport) => {
      if (isAverage) {
        if (insightKey === 'impressions') {
          return computeCpm(data.spend, data[insightKey]);
        }

        if (insightKey === 'clicks') {
          return computeCpc(data.spend, data[insightKey]);
        }

        if (insightKey === 'qualifiedLeads') {
          const qLeadsCount =
            data[insightKey] > data.leads ? data.leads : data[insightKey];
          return computeCpql(data.spend, qLeadsCount);
        }

        return computeCpl(data.spend, data[insightKey]);
      }

      if (insightKey === 'qualifiedLeads') {
        return data.qualifiedLeads > data.leads
          ? data.leads
          : data.qualifiedLeads;
      }

      return data.leads;
    });
  };

  const buildLineChartLabel = (
    weeklyData: TrendzAverageWeeklyReport[],
    isLeads?: boolean,
  ) => {
    const orderedData = orderArrayOfObject(weeklyData, 'dateStart', 'asc');

    return orderedData.map((data: TrendzAverageWeeklyReport) =>
      isLeads ? data.dateStart : `${data.dateStart} - ${data.dateEnd}`,
    );
  };

  const getBrandName = () => {
    if ((location || brand)?.__type === 'location') {
      return `${(location || brand)?.franchisor?.brand} - ${
        (location || brand)?.brand
      }`;
    }

    return (location || brand)?.brand;
  };

  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 100px)',
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#F1F0F0',
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: DEFAULT_COLOR_THEME,
          borderRadius: '18px',

          '&:hover': {
            background: DEFAULT_COLOR_THEME,
          },
        },
      }}
    >
      <CircularLoading loading={fetchLoading} />

      <div className={styles.Dashboard}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <DateRangePicker
              toDate={endRange}
              fromDate={startRange}
              onChange={handleChangeDateRange}
              maxDate={moment()}
              separator="-"
            />
          </Grid>

          <Grid item xs={12}>
            {(location || brand)?.industry ? (
              <PageHeader title={`${(location || brand)?.industry} Category`} />
            ) : (
              <></>
            )}
          </Grid>

          <Grid item xs={12}>
            {trendzLeadsLoading ? (
              <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                <Spinner />
              </Box>
            ) : (
              <Card>
                <CardContent>
                  <BarChart
                    chartData={{
                      labels: [...buildLineChartLabel(leadsReport, true)],
                      datasets: [
                        {
                          label: `Leads`,
                          data: [
                            ...buildLineChartData({
                              weeklyData: leadsReport,
                              insightKey: 'leads',
                            }),
                          ],
                          backgroundColor: AIADZ_YELLOW_HEX,
                        },
                        {
                          label: `QLeads`,
                          data: [
                            ...buildLineChartData({
                              weeklyData: leadsReport,
                              insightKey: 'qualifiedLeads',
                            }),
                          ],
                          backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                        },
                      ],
                    }}
                    title={['Lead Volume (Daily)']}
                    subtitle={[
                      `${startRange?.format(
                        'MMM, D, YYYY',
                      )} - ${endRange?.format('MMM, D, YYYY')}`,
                    ]}
                    titleFontSize={20}
                    subtitleFontSize={14}
                  />
                </CardContent>
              </Card>
            )}
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent
                sx={{
                  padding: '20px',
                  minHeight: '400px',
                  marginBottom: '20px',
                  minWidth: '100%',
                  overflowY: 'auto',
                }}
              >
                <LineChart
                  chartData={{
                    labels: [
                      ...buildLineChartLabel(report?.weeklyAverageCpl.brandCpl),
                    ],
                    datasets: [
                      {
                        label: `${getBrandName()}`,
                        data: [
                          ...buildLineChartData({
                            weeklyData: report.weeklyAverageCpl.brandCpl,
                            insightKey: 'leads',
                            isAverage: true,
                          }),
                        ],
                        backgroundColor: AIADZ_YELLOW_HEX,
                        borderColor: AIADZ_YELLOW_HEX,
                      },
                      {
                        label: 'Other Brands',
                        data: [
                          ...buildLineChartData({
                            weeklyData: report.weeklyAverageCpl.otherBrandCpl,
                            insightKey: 'leads',
                            isAverage: true,
                          }),
                        ],
                        backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                        borderColor: AIADZ_LIGHT_GREEN_HEX,
                      },
                    ],
                  }}
                  title={[`CPL Industry Trend (Weekly)`]}
                  subtitle={[
                    `${startRange?.format('MMM, D, YYYY')} - ${endRange?.format(
                      'MMM, D, YYYY',
                    )}`,
                  ]}
                  titleFontSize={20}
                  subtitleFontSize={14}
                  isCurrency
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent
                sx={{
                  padding: '20px',
                  minHeight: '400px',
                  marginBottom: '20px',
                  minWidth: '100%',
                  overflowY: 'auto',
                }}
              >
                <LineChart
                  chartData={{
                    labels: [
                      ...buildLineChartLabel(
                        report.weeklyAverageCpql.brandCpql,
                      ),
                    ],
                    datasets: [
                      {
                        label: `${getBrandName()}`,
                        data: [
                          ...buildLineChartData({
                            weeklyData: report.weeklyAverageCpql.brandCpql,
                            insightKey: 'qualifiedLeads',
                            isAverage: true,
                          }),
                        ],
                        backgroundColor: AIADZ_YELLOW_HEX,
                        borderColor: AIADZ_YELLOW_HEX,
                      },
                      {
                        label: 'Other Brands',
                        data: [
                          ...buildLineChartData({
                            weeklyData: report.weeklyAverageCpql.otherBrandCpql,
                            insightKey: 'qualifiedLeads',
                            isAverage: true,
                          }),
                        ],
                        backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                        borderColor: AIADZ_LIGHT_GREEN_HEX,
                      },
                    ],
                  }}
                  title={[`CPQL Industry Trend (Weekly)`]}
                  subtitle={[
                    `${startRange?.format('MMM, D, YYYY')} - ${endRange?.format(
                      'MMM, D, YYYY',
                    )}`,
                  ]}
                  titleFontSize={20}
                  subtitleFontSize={14}
                  isCurrency
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent
                sx={{
                  padding: '20px',
                  minHeight: '400px',
                  marginBottom: '20px',
                  minWidth: '100%',
                  overflowY: 'auto',
                }}
              >
                <LineChart
                  chartData={{
                    labels: [
                      ...buildLineChartLabel(report.weeklyAverageCpc.brandCpc),
                    ],
                    datasets: [
                      {
                        label: `${getBrandName()}`,
                        data: [
                          ...buildLineChartData({
                            weeklyData: report.weeklyAverageCpc.brandCpc,
                            insightKey: 'clicks',
                            isAverage: true,
                          }),
                        ],
                        backgroundColor: AIADZ_YELLOW_HEX,
                        borderColor: AIADZ_YELLOW_HEX,
                      },
                      {
                        label: 'Other Brands',
                        data: [
                          ...buildLineChartData({
                            weeklyData: report.weeklyAverageCpc.otherBrandCpc,
                            insightKey: 'clicks',
                            isAverage: true,
                          }),
                        ],
                        backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                        borderColor: AIADZ_LIGHT_GREEN_HEX,
                      },
                    ],
                  }}
                  title={[`CPC Industry Trend (Weekly)`]}
                  subtitle={[
                    `${startRange?.format('MMM, D, YYYY')} - ${endRange?.format(
                      'MMM, D, YYYY',
                    )}`,
                  ]}
                  titleFontSize={20}
                  subtitleFontSize={14}
                  isCurrency
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent
                sx={{
                  padding: '20px',
                  minHeight: '400px',
                  marginBottom: '20px',
                  minWidth: '100%',
                  overflowY: 'auto',
                }}
              >
                <LineChart
                  chartData={{
                    labels: [
                      ...buildLineChartLabel(report.weeklyAverageCpm.brandCpm),
                    ],
                    datasets: [
                      {
                        label: `${getBrandName()}`,
                        data: [
                          ...buildLineChartData({
                            weeklyData: report.weeklyAverageCpm.brandCpm,
                            insightKey: 'impressions',
                            isAverage: true,
                          }),
                        ],
                        backgroundColor: AIADZ_YELLOW_HEX,
                        borderColor: AIADZ_YELLOW_HEX,
                      },
                      {
                        label: 'Other Brands',
                        data: [
                          ...buildLineChartData({
                            weeklyData: report.weeklyAverageCpm.otherBrandCpm,
                            insightKey: 'impressions',
                            isAverage: true,
                          }),
                        ],
                        backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                        borderColor: AIADZ_LIGHT_GREEN_HEX,
                      },
                    ],
                  }}
                  title={[`CPM Industry Trend (Weekly)`]}
                  subtitle={[
                    `${startRange?.format('MMM, D, YYYY')} - ${endRange?.format(
                      'MMM, D, YYYY',
                    )}`,
                  ]}
                  titleFontSize={20}
                  subtitleFontSize={14}
                  isCurrency
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            {trendzIndustryLoading ? (
              <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                <Spinner />
              </Box>
            ) : (
              <Card>
                <CardContent
                  sx={{
                    padding: '20px',
                    minHeight: '400px',
                    marginBottom: '20px',
                    minWidth: '100%',
                    overflowY: 'auto',
                  }}
                >
                  <LineChart
                    chartData={{
                      labels: [
                        ...buildLineChartLabel(industryReport.brandWeeklyCpl),
                      ],
                      datasets: [
                        {
                          label: `${getBrandName()} CPL`,
                          data: [
                            ...buildLineChartData({
                              weeklyData: industryReport.brandWeeklyCpl,
                              insightKey: 'leads',
                              isAverage: true,
                            }),
                          ],
                          backgroundColor: AIADZ_YELLOW_HEX,
                          borderColor: AIADZ_YELLOW_HEX,
                        },
                        {
                          label: 'Restaurant Industry CPL',
                          data: [
                            ...buildLineChartData({
                              weeklyData: industryReport.restaurantWeeklyCpl,
                              insightKey: 'leads',
                              isAverage: true,
                            }),
                          ],
                          backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                          borderColor: AIADZ_LIGHT_GREEN_HEX,
                        },
                        {
                          label: 'Fitness Industry CPL',
                          data: [
                            ...buildLineChartData({
                              weeklyData: industryReport.fitnessWeeklyCpl,
                              insightKey: 'leads',
                              isAverage: true,
                            }),
                          ],
                          backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                          borderColor: AIADZ_LIGHT_GREEN_HEX,
                        },
                        {
                          label: 'Home Services Industry CPL',
                          data: [
                            ...buildLineChartData({
                              weeklyData: industryReport.homeServicesWeeklyCpl,
                              insightKey: 'leads',
                              isAverage: true,
                            }),
                          ],
                          backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                          borderColor: AIADZ_LIGHT_GREEN_HEX,
                        },
                        {
                          label: 'Retail Industry CPL',
                          data: [
                            ...buildLineChartData({
                              weeklyData: industryReport.retailWeeklyCpl,
                              insightKey: 'leads',
                              isAverage: true,
                            }),
                          ],
                          backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                          borderColor: AIADZ_LIGHT_GREEN_HEX,
                        },
                        {
                          label: 'Business Services Industry CPL',
                          data: [
                            ...buildLineChartData({
                              weeklyData:
                                industryReport.businessServicesWeeklyCpl,
                              insightKey: 'leads',
                              isAverage: true,
                            }),
                          ],
                          backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                          borderColor: AIADZ_LIGHT_GREEN_HEX,
                        },
                        {
                          label: 'Education Industry CPL',
                          data: [
                            ...buildLineChartData({
                              weeklyData: industryReport.educationWeeklyCpl,
                              insightKey: 'leads',
                              isAverage: true,
                            }),
                          ],
                          backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                          borderColor: AIADZ_LIGHT_GREEN_HEX,
                        },
                        {
                          label: 'Senior Care Industry CPL',
                          data: [
                            ...buildLineChartData({
                              weeklyData: industryReport.seniorCareWeeklyCpl,
                              insightKey: 'leads',
                              isAverage: true,
                            }),
                          ],
                          backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                          borderColor: AIADZ_LIGHT_GREEN_HEX,
                        },
                        {
                          label: 'Other Industry CPL',
                          data: [
                            ...buildLineChartData({
                              weeklyData: industryReport.otherWeeklyCpl,
                              insightKey: 'leads',
                              isAverage: true,
                            }),
                          ],
                          backgroundColor: AIADZ_LIGHT_GREEN_HEX,
                          borderColor: AIADZ_LIGHT_GREEN_HEX,
                        },
                      ],
                    }}
                    title={[`All Industries Average CPL`]}
                    subtitle={[
                      `${startRange?.format(
                        'MMM, D, YYYY',
                      )} - ${endRange?.format('MMM, D, YYYY')}`,
                    ]}
                    titleFontSize={20}
                    subtitleFontSize={14}
                    isCurrency
                  />
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default Trendz;
