import axios from 'axios';
import { apiBaseUrl } from './api';
import { Moment } from 'moment';
import {
  AdLead,
  FBLeadGenData,
  FBLeadGenForm,
  FBLeadGenQuestionForm,
  LeadFormData,
  LeadNotification,
} from '../types';
import { dayJsInstance } from '../utils/dayjsHelper';

export const getLeadsByBrand = async (
  brandId: string,
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const timezone = dayJsInstance.tz.guess();
  const data = await axios.get(
    `${apiBaseUrl}/leads?startDate=${startDate}&endDate=${endDate}`,
    {
      params: { brandId, timezone },
    },
  );

  return data?.data;
};

export const getLeadsByAdId = async (
  brandId: string,
  adId: string,
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const timezone = dayJsInstance.tz.guess();
  const data = await axios.get(
    `${apiBaseUrl}/leads?startDate=${startDate}&endDate=${endDate}`,
    {
      params: { brandId, adId, timezone },
    },
  );

  return data?.data;
};

export const syncFbLeads = async (brandId: string, adId?: string) => {
  let params: any = { brandId };

  if (adId) {
    params = { ...params, adId };
  }

  const data = await axios.post(
    `${apiBaseUrl}/leads/facebook/sync`,
    {},
    { params },
  );

  return data?.data;
};

export const getBrandLeadForms = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/leads/facebook/forms/${brandId}`);

  return data?.data;
};

export const getSingleLeadForms = async (
  leadFormId?: string,
  formId?: string,
  brandId?: string,
) => {
  let params = {};

  if (leadFormId) {
    params = { ...params, leadFormId };
  }

  if (formId) {
    params = { ...params, formId };
  }

  if (brandId) {
    params = { ...params, brandId };
  }
  const data = await axios.get(`${apiBaseUrl}/leads/facebook/forms/single`, {
    params,
  });

  return data?.data;
};

export const createLeadForms = async (
  leadForm: FBLeadGenForm,
  leadNotification: LeadNotification,
) => {
  const data = await axios.post(`${apiBaseUrl}/leads/facebook/lead-form`, {
    leadForm,
    leadNotification,
  });

  return data?.data;
};

export const syncLeadForms = async (
  forms: FBLeadGenData[],
  brandId: string,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/leads/facebook/lead-form/sync?brandId=${brandId}`,
    forms,
  );

  return data?.data;
};

export const archiveLeadForm = async (leadFormId: string) => {
  const data = await axios.delete(
    `${apiBaseUrl}/leads/facebook/lead-form/archive/${leadFormId}`,
  );

  return data?.data;
};

export const createOrUpdateLeadNotification = async (
  params: LeadNotification,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/leads/facebook/lead-form/notification`,
    params,
  );

  return data?.data;
};

export const getFormLeadNotification = async (
  leadFormId: string,
  brandId: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/leads/facebook/lead-form/notification`,
    {
      params: {
        leadFormId,
        brandId,
      },
    },
  );

  return data?.data;
};

export const getDefaultLeadNotification = async (brandId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/leads/facebook/lead-form/notification`,
    {
      params: {
        getDefault: true,
        brandId,
      },
    },
  );

  return data?.data;
};

export const sendTestLeadEmail = async (
  brandId: string,
  params: LeadNotification,
  questions: FBLeadGenQuestionForm[],
) => {
  const data = await axios.post(
    `${apiBaseUrl}/leads/facebook/lead-form/notification/test/email`,
    { leadNotification: params, questions },
    {
      params: {
        brandId,
      },
    },
  );

  return data?.data;
};

export const sendTestLeadZapier = async (
  brandId: string,
  params: LeadNotification,
  questions: FBLeadGenQuestionForm[],
) => {
  const data = await axios.post(
    `${apiBaseUrl}/leads/facebook/lead-form/notification/test/zapier`,
    { leadNotification: params, questions },
    {
      params: {
        brandId,
      },
    },
  );

  return data?.data;
};

export const sendFacebookLeads = async (brandId: string, leads: AdLead[]) => {
  const data = await axios.post(`${apiBaseUrl}/leads/facebook/send`, {
    brandId,
    leads,
  });

  return data?.data;
};

export const editLeadForm = async (params: LeadFormData, formId: string) => {
  const data = await axios.put(
    `${apiBaseUrl}/leads/facebook/lead-form/edit/${formId}`,
    params,
  );

  return data?.data;
};

export const editLeads = async (
  leadId: string,
  brandId: string,
  params: AdLead,
) => {
  const data = await axios.put(`${apiBaseUrl}/leads/${leadId}`, params, {
    params: { brandId },
  });

  return data?.data;
};
