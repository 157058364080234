import { RiContactsBook2Line, RiBriefcase2Line } from 'react-icons/ri';
import { PiUsersThreeBold } from 'react-icons/pi';
import { FaRegUser } from 'react-icons/fa';
import { MdRadioButtonChecked } from 'react-icons/md';
import { IoTextOutline } from 'react-icons/io5';
import { faker } from '@faker-js/faker/locale/en_US';
import {
  AdLead,
  Brand,
  FBLeadGenQuestionForm,
  LeadNotificationBodyField,
} from '../../types';
import { constantStringToHumanized, humanizeString } from '../stringModifier';
import { BrandIndustry } from './BrandHelpers';

export const ThankYouPageButtonType = {
  VIEW_WEBSITE: 'VIEW_WEBSITE',
  CALL_BUSINESS: 'CALL_BUSINESS',
  MESSAGE_BUSINESS: 'MESSAGE_BUSINESS',
  DOWNLOAD: 'DOWNLOAD',
  SCHEDULE_APPOINTMENT: 'SCHEDULE_APPOINTMENT',
  VIEW_ON_FACEBOOK: 'VIEW_ON_FACEBOOK',
  NONE: 'NONE',
  // Custom CTA Type
  SCHEDULE_CALL: 'SCHEDULE_CALL',
};

export const prefilledQuestionIcons = (questionType: string) => {
  let icon: any;
  switch (questionType) {
    case 'contact_information':
      icon = <RiContactsBook2Line size={20} />;
      break;
    case 'demographics':
      icon = <PiUsersThreeBold size={20} />;
      break;
    case 'work_information':
      icon = <RiBriefcase2Line size={20} />;
      break;
    default:
      icon = <FaRegUser size={20} />;
      break;
  }
  return icon;
};

export const prefilledQuestions: any = {
  contact_information: [
    {
      type: 'PHONE',
      label: 'Phone Number',
    },
    { type: 'EMAIL', label: 'Email' },
    {
      type: 'STREET_ADDRESS',
      label: 'Street Address',
    },
    { type: 'CITY', label: 'City' },
    { type: 'STATE', label: 'State' },
    { type: 'PROVINCE', label: 'Province' },
    { type: 'COUNTRY', label: 'Country' },
    { type: 'POST_CODE', label: 'Post Code' },
    { type: 'ZIP', label: 'Zip Code' },
  ],
  user_information: [
    { type: 'FULL_NAME', label: 'Full Name' },
    { type: 'FIRST_NAME', label: 'First Name' },
    { type: 'LAST_NAME', label: 'Last Name' },
  ],
  demographics: [
    { type: 'DOB', label: 'Date of Birth' },
    { type: 'GENDER', label: 'Gender' },
    { type: 'MARITIAL_STATUS', label: 'Maritial Status' },
    { type: 'RELATIONSHIP_STATUS', label: 'Relationship Status' },
    { type: 'MILITARY_STATUS', label: 'Military Status' },
  ],
  work_information: [
    { type: 'JOB_TITLE', label: 'Job Title' },
    { type: 'WORK_PHONE_NUMBER', label: 'Work Phone Number' },
    { type: 'WORK_EMAIL', label: 'Work Email' },
    { type: 'COMPANY_NAME', label: 'Company Name' },
  ],
};

export const leadFormCustomQuestions: any[] = [
  {
    type: 'multiple_choice',
    description: 'Use answer choices to categorize, sort and filter leads.',
    label: 'Multiple Choice',
    icon: <MdRadioButtonChecked />,
  },
  {
    type: 'short_answer',
    description: 'Get additional info in a sentence or two.',
    label: 'Short Answer',
    icon: <IoTextOutline />,
  },
  /**
   * TODO: Re-enable if necessary
   *
  {
    type: 'conditional',
    description:
      'Show only answer choices that apply. Change based on previous answers.',
    label: 'Conditional',
    icon: null,
  },
  {
    type: 'appointment',
    description: 'Let people request a time to meet with you.',
    label: 'Appointment Request',
    icon: null,
  },
  */
];

export const leadFormCtas: any[] = [
  {
    type: 'VIEW_WEBSITE',
    label: 'View Website',
  },
  {
    type: 'CALL_BUSINESS',
    label: 'Call Business',
  },
  {
    type: 'SCHEDULE_CALL',
    label: 'Schedule a Call',
  },
];

export const DEFAULT_LEAD_SEND_FIELDS = [
  'FIRST_NAME',
  'LAST_NAME',
  'EMAIL',
  'PHONE',
  'CITY',
  'STATE',
  'COUNTRY',
  'SOURCE',
];

export const DEFAULT_LEAD_SEND_CUSTOM_FIELDS = [
  'Investment',
  'Background',
  'Experience',
];

export const getPlaceholderValue = (field: string) => {
  if (field === '<<first_name>>') {
    return faker.person.firstName();
  }

  if (field === '<<last_name>>') {
    return faker.person.lastName();
  }

  if (field === '<<email>>') {
    return faker.internet.email();
  }

  if (field === '<<phone>>') {
    return faker.phone.number('(###) ###-####');
  }

  if (field === '<<city>>') {
    return faker.location.city();
  }

  if (field === '<<state>>') {
    return faker.location.state();
  }

  if (field === '<<country>>') {
    return 'USA';
  }

  if (field.includes('<<Investment>>')) {
    return `Yes`;
  }

  if (field === '<<Background>>') {
    return faker.person.jobTitle();
  }

  if (field === '<<Experience>>') {
    return faker.person.jobTitle();
  }

  if (field === '<<BestNumber>>') {
    return faker.phone.number('(###) ###-####');
  }

  if (field === '<<AD_NAME>>') {
    return 'Test-Ad-1';
  }

  if (field === '<<PLATFORM>>') {
    return 'Facebook';
  }

  return faker.lorem.sentence();
};

/**
 * Build Default Body Fields
 * @description Build the lead notification email body fields
 * @author Angelo David <angelod@codev.com>
 */
export const buildNotificationBodyFields = (
  questions: FBLeadGenQuestionForm[],
  provider: string,
) => {
  let temp: any[] = [];
  const prefilledQuestions = questions.filter(
    (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
  );
  const customQuestions = questions?.filter(
    (question: FBLeadGenQuestionForm) =>
      question.type === 'CUSTOM' &&
      DEFAULT_LEAD_SEND_CUSTOM_FIELDS.includes(question.key),
  );
  const customQuestions2 = questions?.filter(
    (question: FBLeadGenQuestionForm) =>
      question.type === 'CUSTOM' &&
      !DEFAULT_LEAD_SEND_CUSTOM_FIELDS.includes(question.key),
  );

  const prefilledFields = DEFAULT_LEAD_SEND_FIELDS.flatMap((field: string) => {
    if (field === 'SOURCE') {
      return [
        {
          label: 'Source Details',
          value: `${humanizeString(provider)} Ad`,
        },
      ];
    }

    const data = prefilledQuestions?.find(
      (question: FBLeadGenQuestionForm) => question.type === field,
    );

    if (field === 'COUNTRY') {
      return [
        {
          label: constantStringToHumanized(data?.type || 'COUNTRY'),
          value: data ? `<<${data?.type.toLowerCase()}>>` : 'USA',
        },
      ];
    }

    if (!data) {
      return [];
    }

    const type =
      data.type === 'PHONE'
        ? 'Phone Number'
        : constantStringToHumanized(data.type);

    return [{ label: type, value: `<<${data.type.toLowerCase()}>>` }];
  });

  const customFields = DEFAULT_LEAD_SEND_CUSTOM_FIELDS.flatMap(
    (field: string) => {
      const data = customQuestions?.find(
        (question: FBLeadGenQuestionForm) => question.key === field,
      );

      if (!data) {
        return [];
      }

      const value = (data: FBLeadGenQuestionForm) => {
        let amount: string = '';
        if (data.key === 'Investment') {
          amount = getAmount(data.label);

          return `${amount}`;
        }

        return;
      };

      const label = (data: FBLeadGenQuestionForm) => {
        if (data.key === 'Investment') {
          return 'Cash Available for Investment';
        }

        if (data.key === 'Background') {
          return 'Comments';
        }

        return 'Experience';
      };

      return [
        {
          label: label(data),
          value: `${value(data) ? `${value(data)}-` : ''}<<${data.key}>>`,
        },
      ];
    },
  );

  const customFields2 = customQuestions2.flatMap(
    (question: FBLeadGenQuestionForm) => {
      const label = (str: string) => {
        str = str?.replace(/[_-]/g, ' ');

        // Insert a space before all capital letters
        str = str?.replace(/([A-Z])/g, ' $1');

        // Remove any leading or trailing spaces
        str = str?.trim();

        // Capitalize the first letter of each word
        str = str?.toLowerCase().replace(/(^| )(\w)/g, function (x) {
          return x.toUpperCase();
        });

        return str || '';
      };

      return [
        { label: label(question?.key || ''), value: `<<${question.key}>>` },
      ];
    },
  );

  temp = [
    ...(prefilledFields || []),
    ...(customFields || []),
    ...(customFields2 || []),
    { label: 'Ad Name', value: '<<AD_NAME>>' },
    { label: 'Platform', value: '<<PLATFORM>>' },
    { label: 'END', value: ' ' },
  ];

  return temp;
};

const getAmount = (label: string) => {
  const match = label.match(/(\$[0-9,.]+ ?[kK]?)/);

  return match ? match[0] : '';
};

/**
 * Question Keys Builder
 * @description Create a list of the body fields use for lead notification mapping
 * @author Angelo David <angelod@codev.com>
 * @param questions
 * @returns <String[]> List of question keys enclosed with <<>>
 */
export const getQuestionKeys = (questions: FBLeadGenQuestionForm[]) => {
  let keys = questions?.map((question: FBLeadGenQuestionForm) => {
    if (question.type === 'CUSTOM') {
      return `<<${question.key}>>`;
    }

    return `<<${question.type.toLowerCase()}>>`;
  }, []);

  // Add default keys
  return [...keys, '<<AD_NAME>>', '<<PLATFORM>>'];
};

/**
 * Default Notification Fields Builder
 * @description Build the list of default notification body fields that will
 *              automatically set on the lead notification form values
 * @author Angelo David <angelod@codev.com>
 * @param fields
 * @param questionKeys
 * @param setValue
 * @returns <Object> bodyFields - List of lead notification body fields
 *                   errors - List of form field name and error message
 */
export const buildDefaultNotificationFields = (
  fields: LeadNotificationBodyField[],
  questionKeys: string[],
  setValue: any,
) => {
  let bodyFields: LeadNotificationBodyField[] = [];
  let errors: any[] = [];

  fields?.forEach((field: LeadNotificationBodyField, index: number) => {
    let params: LeadNotificationBodyField = {
      label: field.label,
      value: field.value,
    };
    const placeholders = field.value.match(/<<[^>]+>>/g) || [];
    // Check if all question keys is existing on the default notification body fields
    const allExist = placeholders.every((placeholder) =>
      questionKeys.includes(placeholder),
    );
    // Get the unmatch body fields
    const missingPlaceholders = placeholders.filter(
      (placeholder) => !questionKeys.includes(placeholder),
    );

    // Manually set the validation value
    setValue(`body-label-${index}`, field.label);

    if (allExist) {
      // Manually set the validation value
      setValue(`body-value-${index}`, field.value);
    } else {
      // Set the value to null
      params = { ...params, value: null };
      // Manually set value to null that will cause validation error
      setValue(`body-value-${index}`, null);
      // Set the error fields
      errors = [
        ...errors,
        {
          name: `body-value-${index}`,
          field: missingPlaceholders[0],
        },
      ];
    }

    bodyFields = [...bodyFields, params];
  });

  return { bodyFields, errors };
};

export const getBackground = (lead: AdLead) => {
  const data = lead?.fieldData?.find((item: any) => item.name === 'Background');

  return data?.values[0] || '';
};

export const getInvestment = (lead: AdLead) => {
  const data = lead?.fieldData?.find((item: any) => item.name === 'Investment');

  return data?.values[0] || '';
};

export const getExperience = (lead: AdLead) => {
  const data = lead?.fieldData?.find((item: any) => item.name === 'Experience');

  return data?.values[0] || '';
};

export const buildDefaultFormQuestions = (brand: Brand) => {
  let questions: FBLeadGenQuestionForm[] = [];

  if (brand?.industry === BrandIndustry.RESTAURANT) {
    questions = [
      {
        key: 'Investment',
        label:
          'Do you have $250K of Liquid Capital to invest? (Cash, Retirement, HELOC, Family/Friends, etc.)',
        type: 'CUSTOM',
        qualified: true,
        enableConditionalLogic: false,
        options: [
          { value: 'Yes', key: 'yes', qualified: true },
          { value: 'No', key: 'no' },
        ],
      },
      {
        key: 'Experience',
        label: 'Do you have business/restaurant ownership experience:',
        type: 'CUSTOM',
        qualified: true,
        enableConditionalLogic: false,
        options: [
          { value: 'Yes', key: 'yes', qualified: true },
          { value: 'No', key: 'no' },
        ],
      },
    ];
  } else {
    questions = [
      {
        key: 'Investment',
        label:
          'Do you have $100K of Liquid Capital to invest? (Cash, Retirement, HELOC, Family/Friends, etc.)',
        type: 'CUSTOM',
        qualified: true,
        enableConditionalLogic: false,
        options: [
          { value: 'Yes', key: 'yes', qualified: true },
          { value: 'No', key: 'no' },
        ],
      },
      {
        key: 'Experience',
        label: 'Do you have experience in:',
        type: 'CUSTOM',
        qualified: true,
        enableConditionalLogic: false,
        options: [
          {
            value: 'Senior Management / Operations',
            key: 'senior_management_/_operations',
            qualified: true,
          },
          {
            value: 'Sales',
            key: 'sales',
            qualified: true,
          },
          {
            value: 'Business Ownership',
            key: 'business_ownership',
            qualified: true,
          },
          {
            value: 'Military Service',
            key: 'military_service',
            qualified: true,
          },
          {
            value: 'None of the Above',
            key: 'none_of_the_above',
          },
        ],
      },
    ];
  }

  /**
   * TODO: Re-enable as defaults if necessary
  questions = [
    ...questions,
    {
      key: 'Background',
      label: 'Tell us about your professional background:',
      type: 'CUSTOM',
      qualified: true,
      terms: QUALIFIED_SHORT_ANSWER_TERMS,
    },
  ];
  */

  return questions;
};

export const isQualifiedLead = (params: {
  investmentQualified: boolean;
  backgroundQualified: boolean;
  experienceQualified: boolean;
  investmentQualifiedLead: boolean;
  backgroundQualifiedLead: boolean;
  experienceQualifiedLead: boolean;
}): boolean => {
  const {
    investmentQualified,
    investmentQualifiedLead,
    backgroundQualified,
    backgroundQualifiedLead,
    experienceQualified,
    experienceQualifiedLead,
  } = params;

  let conditions: boolean[] = [];

  if (investmentQualified) {
    conditions = [...conditions, investmentQualifiedLead];
  }

  if (experienceQualified) {
    conditions = [...conditions, experienceQualifiedLead];
  }

  if (backgroundQualified) {
    conditions = [...conditions, backgroundQualifiedLead];
  }

  if (conditions.length > 0) {
    return conditions.every((condition) => condition === true);
  }

  return false;
};
