import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Skeleton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { FiCircle } from 'react-icons/fi';
import { HiGlobeAmericas } from 'react-icons/hi2';
import { MdOutlinePhoneAndroid } from 'react-icons/md';
import { FaLaptop } from 'react-icons/fa';
import { IoRefresh } from 'react-icons/io5';

interface GoogleTemplatesPreviewProps {
  category: string;
  headlines: string[];
  descriptions: string[];
  sitelinks: {
    headline: string;
    description1: string;
    description2: string;
    finalUrl: string;
  }[];
  url: string;
  showToolbar?: boolean;
  center?: boolean;
  images?: string[];
  business_logo?: any;
}

const GoogleTemplatesPreview: React.FC<GoogleTemplatesPreviewProps> = ({
  category = 'search',
  headlines,
  descriptions,
  sitelinks,
  url,
  showToolbar = true,
  center = false,
  images = [],
  business_logo = '',
}) => {
  const [displayHeadline1, setDisplayHeadline1] = useState('');
  const [displayHeadline2, setDisplayHeadline2] = useState('');
  const [displayDescription, setDisplayDescription] = useState('');
  const [displayImage, setDisplayImage] = useState('');
  const [viewMode, setViewMode] = useState('mobile');
  const [displayUrl, setDisplayUrl] = useState('');
  const businessLogoUrl =
    typeof business_logo === 'string'
      ? business_logo
      : business_logo?.url || '';

  const setDisplays = () => {
    if (url) {
      const u = url[url.length - 1] !== '/' ? `${url}/` : url;
      setDisplayUrl(u);
    }

    if (headlines) {
      const h = headlines.sort(() => Math.random() - 0.5);
      setDisplayHeadline1(h[0]);
      setDisplayHeadline2(h[1]);
    }
    if (descriptions) {
      const d =
        descriptions.sort().join(' ').length < 200
          ? [...descriptions, ...headlines]
          : descriptions;
      setDisplayDescription(d.sort(() => Math.random() - 0.5).join(' '));
    }
    if (images) {
      const i = images.sort(() => Math.random() - 0.5);
      setDisplayImage(i[0]);
    }
  };

  useEffect(() => {
    setDisplays();
    const interval = setInterval(() => setDisplays(), 10000);
    return () => clearInterval(interval);
  }, [headlines, descriptions]);

  return (
    <Box bgcolor={showToolbar ? `transparent` : `#f1f3f4`} sx={{}}>
      {showToolbar ? (
        <Box
          sx={{
            display: `flex`,
            justifyContent: `space-between`,
            marginBottom: 1,
            gap: 2,
          }}
        >
          <Typography variant="h6" color="primary" fontWeight={800}>
            Preview: {viewMode === 'desktop' ? 'Desktop' : 'Mobile'} View
          </Typography>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `flex-end`,
              marginBottom: 1,
              gap: 2,
            }}
          >
            <Button
              onClick={setDisplays}
              sx={{
                backgroundColor: `white`,
                '&hover:backgroundColor': `#e6ffd4`,
                padding: 1,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                border: `1px solid #e8eaeb`,
                borderRadius: 2,
              }}
            >
              <IoRefresh size={24} />
            </Button>

            <Box
              sx={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <Button
                onClick={() => {
                  setViewMode('mobile');
                }}
                sx={{
                  backgroundColor: viewMode === 'mobile' ? '#e6ffd4' : `white`,
                  '&hover:backgroundColor': `#e6ffd4`,
                  paddingY: 1,
                  paddingLeft: 2,
                  paddingRight: 1,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  border: `2px solid #e8eaeb`,
                  borderTopLeftRadius: '999px',
                  borderBottomLeftRadius: '999px',
                }}
              >
                <MdOutlinePhoneAndroid size={24} />
              </Button>
              <Button
                onClick={() => {
                  setViewMode('desktop');
                }}
                sx={{
                  backgroundColor: viewMode === 'desktop' ? '#e6ffd4' : `white`,
                  '&hover:backgroundColor': `#e6ffd4`,
                  paddingY: 1,
                  paddingLeft: 1,
                  paddingRight: 2,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  border: `2px solid #e8eaeb`,
                  borderTopRightRadius: '999px',
                  borderBottomRightRadius: '999px',
                }}
              >
                <FaLaptop size={24} />
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
      {category === 'search' ? (
        <Box>
          {viewMode === 'mobile' ? (
            <Box
              bgcolor={`#f1f3f4`}
              sx={{
                paddingTop: 2,
                paddingX: 2,
                maxWidth: 420,
                marginLeft: `auto`,
                marginRight: center ? `auto` : 0,
              }}
            >
              <Box
                bgcolor={`#ffffff`}
                sx={{
                  borderTopLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderTop: `4px solid #e8eaeb`,
                  borderRight: `4px solid #e8eaeb`,
                  borderLeft: `4px solid #e8eaeb`,
                  paddingTop: 1,
                  paddingX: 2,
                  maxWidth: 420,
                  marginLeft: `auto`,
                }}
              >
                <Box
                  sx={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    paddingBottom: 1,
                  }}
                >
                  <FiCircle color="#d5d8da" />
                </Box>
                <Box
                  bgcolor={`#f1f3f4`}
                  sx={{
                    paddingTop: 2,
                    paddingX: 2,
                    paddingBottom: 1,
                    maxWidth: 420,
                    marginLeft: `auto`,
                  }}
                >
                  <Box
                    bgcolor={`white`}
                    sx={{
                      borderRadius: 2,
                      padding: 2,
                      boxShadow: 1,
                    }}
                  >
                    <Typography
                      fontFamily={`sans-serif`}
                      fontSize={12}
                      fontWeight={600}
                    >
                      Sponsored
                    </Typography>
                    <Box
                      sx={{
                        marginTop: 1,
                        marginBottom: 2,
                        display: `flex`,
                        gap: 1,
                        alignItems: `center`,
                        justifyContent: `start`,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#e2eeff',
                          width: `fit-content`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          padding: `4px`,
                          borderRadius: `100%`,
                        }}
                      >
                        {businessLogoUrl ? (
                          <>
                            <img
                              src={businessLogoUrl}
                              alt={business_logo.url}
                              height={`auto`}
                              width={18}
                            />
                          </>
                        ) : (
                          <HiGlobeAmericas color="#0060f0" size={20} />
                        )}
                      </Box>
                      <Typography fontFamily={`sans-serif`} fontSize={12}>
                        {displayUrl}
                      </Typography>
                    </Box>
                    <Typography
                      fontFamily={`sans-serif`}
                      fontSize={16}
                      color={`#1558d6`}
                      fontWeight={500}
                      sx={{
                        overflow: `hidden`,
                        display: `-webkit-box`,
                        WebkitLineClamp: 2,
                        lineClamp: 2,
                        WebkitBoxOrient: `vertical`,
                      }}
                    >
                      {displayHeadline1} - {displayHeadline2}
                    </Typography>

                    <Box>
                      {displayImage ? (
                        <Box
                          sx={{
                            display: `flex`,
                            justifyContent: `space-between`,
                            alignItems: `start`,
                            gap: 1,
                          }}
                        >
                          <Typography
                            fontFamily={`sans-serif`}
                            fontSize={14}
                            minWidth={100}
                            sx={{
                              overflow: `hidden`,
                              display: `-webkit-box`,
                              WebkitLineClamp: 4,
                              lineClamp: 4,
                              WebkitBoxOrient: `vertical`,
                            }}
                          >
                            {displayDescription}
                          </Typography>
                          <Box
                            sx={{
                              flexShrink: 0,
                            }}
                          >
                            {displayImage ? (
                              <>
                                <img
                                  src={displayImage}
                                  alt={displayImage}
                                  height={`auto`}
                                  width={`85px`}
                                ></img>
                              </>
                            ) : null}
                          </Box>
                        </Box>
                      ) : (
                        <Typography
                          fontFamily={`sans-serif`}
                          fontSize={14}
                          sx={{
                            overflow: `hidden`,
                            display: `-webkit-box`,
                            WebkitLineClamp: 4,
                            lineClamp: 4,
                            WebkitBoxOrient: `vertical`,
                          }}
                        >
                          {displayDescription}
                        </Typography>
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: `flex`,
                        flexDirection: `row`,
                        justifyContent: `start`,
                        alignItems: `center`,
                        marginTop: 1,
                        gap: `10px`,
                        overflow: `hidden`,
                      }}
                    >
                      {sitelinks.map((sitelink, index) => (
                        <Typography
                          sx={{
                            flexShrink: 0,
                            border: `1px solid #e8eaeb`,
                            borderRadius: 2,
                            color: `#1558d6`,
                            paddingX: `8px`,
                            paddingY: `4px`,
                            fontSize: 14,
                          }}
                        >
                          {sitelink.headline}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
          {viewMode === 'desktop' ? (
            <Box
              bgcolor={`white`}
              sx={{
                borderRadius: 2,
                padding: 2,
                boxShadow: 1,

                maxWidth: 680,
                marginLeft: `auto`,
              }}
            >
              <Typography
                fontFamily={`sans-serif`}
                fontSize={12}
                fontWeight={600}
              >
                Sponsored
              </Typography>
              <Box
                sx={{
                  marginTop: `5px`,
                  marginBottom: `5px`,
                  display: `flex`,
                  gap: 1,
                  alignItems: `center`,
                  justifyContent: `start`,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#e2eeff',
                    width: `fit-content`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    padding: `4px`,
                    borderRadius: `100%`,
                  }}
                >
                  {businessLogoUrl ? (
                    <>
                      <img
                        src={businessLogoUrl}
                        alt={business_logo.url}
                        height={`auto`}
                        width={18}
                      />
                    </>
                  ) : (
                    <HiGlobeAmericas color="#0060f0" size={20} />
                  )}
                </Box>
                <Typography fontFamily={`sans-serif`} fontSize={12}>
                  {displayUrl}
                </Typography>
              </Box>
              <Typography
                fontFamily={`sans-serif`}
                fontSize={16}
                color={`#1558d6`}
                fontWeight={500}
                sx={{
                  overflow: `hidden`,
                  display: `-webkit-box`,
                  WebkitLineClamp: 1,
                  lineClamp: 1,
                  WebkitBoxOrient: `vertical`,
                }}
              >
                {displayHeadline1} - {displayHeadline2}
              </Typography>

              <Typography
                fontFamily={`sans-serif`}
                fontSize={14}
                color={`#696969`}
                sx={{
                  overflow: `hidden`,
                  display: `-webkit-box`,
                  WebkitLineClamp: 4,
                  lineClamp: 4,
                  WebkitBoxOrient: `vertical`,
                }}
              >
                {displayDescription}
              </Typography>
              <Typography
                fontFamily={`sans-serif`}
                fontSize={14}
                color={`#1558d6`}
                sx={{
                  overflow: `hidden`,
                  display: `-webkit-box`,
                  WebkitLineClamp: 1,
                  lineClamp: 1,
                  WebkitBoxOrient: `vertical`,
                }}
              >
                {sitelinks
                  .map((sitelink, index) => sitelink.headline)
                  .join(' • ')}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}
      {category === 'display' ? (
        <Box
          sx={{
            paddingTop: 2,
            paddingX: 2,
            maxWidth: 420,
            marginLeft: `auto`,
            marginRight: center ? `auto` : 0,
            backgroundColor: '#ffffff',
          }}
        >
          <Box
            sx={{
              display: `flex`,
              gap: 1,
              alignItems: `center`,
              marginBottom: 1,
            }}
          >
            <Skeleton
              variant="circular"
              width={60}
              height={60}
              sx={{ flexShrink: 0 }}
            />
            <div style={{ width: `100%` }}>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="rounded" height={40} width={`100%`} />
            </div>
          </Box>
          <Skeleton variant="rectangular" height={300} />
          <div style={{ display: `none`, gap: 1 }}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rounded" height={400} />
            <Skeleton variant="rectangular" width={210} height={60} />
          </div>
        </Box>
      ) : null}
    </Box>
  );
};

export default GoogleTemplatesPreview;
