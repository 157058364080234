import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import { googleResourceLimits } from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';

interface GoogleTemplatesSiteLinksProps {
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
}

const GoogleTemplatesSiteLinks: React.FC<GoogleTemplatesSiteLinksProps> = ({
  submitForm,
  templateForm,
  setIsDirty,
}) => {
  const [sitelinks, setSitelinks] = React.useState([]);
  const [sitelinksArea, setSitelinksArea] = React.useState('');
  const maxItems = googleResourceLimits.sitelinks.items;
  const maxLength = googleResourceLimits.sitelinks.length;

  useEffect(() => {
    if (templateForm.length > 0) {
      setSitelinks(templateForm);
      setSitelinksArea(
        templateForm
          .map(
            (item: any) =>
              `${item.headline}\\${item.description1}\\${item.description2}\\${item.finalUrl}`,
          )
          .join('\n'),
      );
    }
  }, [templateForm]);

  useEffect(() => {
    submitForm(sitelinks);
  }, [sitelinks]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: `space-between`,
        alignItems: `start`,
      }}
    >
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          padding: `1rem 2rem`,
          maxWidth: 500,
          width: `100%`,
          borderRadius: 2,
          position: `sticky`,
          top: 0,
          left: 0,
        }}
      >
        <TextField
          multiline
          rows={17}
          sx={{
            backgroundColor: 'white',
            border: `2px solid ${DEFAULT_COLOR_THEME}`,
            borderRadius: 2,
          }}
          value={sitelinksArea}
          onChange={(e) => {
            setSitelinksArea(e.target.value);
            setIsDirty(true);
            const temp = e.target.value.split('\n');
            const temp2 = temp.map((item) => {
              const tempItem = item.split('\\');
              return {
                headline: tempItem[0].trim(),
                description1: tempItem[1] ? tempItem[1].trim() : '',
                description2: tempItem[2] ? tempItem[2].trim() : '',
                finalUrl: tempItem[3] ? tempItem[3].trim() : '',
              };
            });
            if (temp2) setSitelinks(temp2);
          }}
          placeholder="Enter or paste headlines separated by new lines"
          fullWidth
        />
      </Box>
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          padding: `1rem 2rem`,
          maxWidth: 500,
          width: `100%`,
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h6"
          fontWeight={800}
          mb={2}
          color={DEFAULT_COLOR_THEME}
        >
          Sitelinks [{sitelinks.length} of {maxItems}]
        </Typography>
        {sitelinks.map((sitelink, index) => (
          <Box
            sx={{
              textAlign: 'right',
              marginBottom: '1rem',
              maxWidth: 600,
              backgroundColor: `#ebebeb`,
              padding: '1rem',
              borderRadius: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '0.25rem',
              }}
            >
              <Typography
                variant="h6"
                fontWeight={800}
                fontSize={16}
                color={DEFAULT_COLOR_THEME}
              >
                Sitelinks #{index + 1}
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: '#ffcccc',
                  },
                }}
                onClick={() => {
                  const temp = [...sitelinks];
                  temp.splice(index, 1);
                  setSitelinks(temp);
                  setIsDirty(true);
                }}
              >
                <MdDeleteOutline size={24} color="#ababab" />
              </Button>
            </Box>

            <TextField
              key={`headline-${index}`}
              id={`headline-${index}`}
              label={`Headline ${index + 1}`}
              variant="outlined"
              fullWidth
              value={sitelink.headline}
              onChange={(e) => {
                setIsDirty(true);
                const temp = [...sitelinks];
                temp[index].headline = e.target.value;
                setSitelinks(temp);
                const textarea = sitelinks
                  .map(
                    (item) =>
                      `${item.headline}\\${item.description1}\\${item.description2}\\${item.finalUrl}`,
                  )
                  .join('\n');
                setSitelinksArea(textarea);
              }}
              error={sitelink.headline.length > maxLength.headline}
              sx={{ backgroundColor: 'white' }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginBottom: 2,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color:
                    sitelink.headline.length > maxLength.headline
                      ? 'red'
                      : 'black',
                }}
              >
                {`${sitelink.headline.length}/${maxLength.headline}`}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color:
                    sitelink.headline.length > maxLength.headline
                      ? 'red'
                      : 'black',
                }}
              >
                {sitelink.headline.length > maxLength.headline
                  ? 'Max length reached'
                  : ''}
              </Typography>
            </Box>
            <TextField
              key={`description1-${index}`}
              id={`description1-${index}`}
              label={`Description 1`}
              variant="outlined"
              fullWidth
              value={sitelink.description1}
              onChange={(e) => {
                setIsDirty(true);
                const temp = [...sitelinks];
                temp[index].description1 = e.target.value;
                setSitelinks(temp);
                const textarea = sitelinks
                  .map(
                    (item) =>
                      `${item.headline}\\${item.description1}\\${item.description2}\\${item.finalUrl}`,
                  )
                  .join('\n');
                setSitelinksArea(textarea);
              }}
              error={sitelink.description1.length > maxLength.description1}
              sx={{ backgroundColor: 'white' }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginBottom: 2,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color:
                    sitelink.description1.length > maxLength.description1
                      ? 'red'
                      : 'black',
                }}
              >
                {`${sitelink.description1.length}/${maxLength.description1}`}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color:
                    sitelink.description1.length > maxLength.description1
                      ? 'red'
                      : 'black',
                }}
              >
                {sitelink.description1.length > maxLength.description1
                  ? 'Max length reached'
                  : ''}
              </Typography>
            </Box>

            <TextField
              key={`description2-${index}`}
              id={`description2-${index}`}
              label={`Description 2`}
              variant="outlined"
              fullWidth
              value={sitelink.description2}
              onChange={(e) => {
                setIsDirty(true);
                const temp = [...sitelinks];
                temp[index].description2 = e.target.value;
                setSitelinks(temp);
                const textarea = sitelinks
                  .map(
                    (item) =>
                      `${item.headline}\\${item.description1}\\${item.description2}\\${item.finalUrl}`,
                  )
                  .join('\n');
                setSitelinksArea(textarea);
              }}
              error={sitelink.description2.length > maxLength.description2}
              sx={{ backgroundColor: 'white' }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginBottom: 2,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color:
                    sitelink.description2.length > maxLength.description2
                      ? 'red'
                      : 'black',
                }}
              >
                {`${sitelink.description2.length}/${maxLength.description2}`}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color:
                    sitelink.description2.length > maxLength.description2
                      ? 'red'
                      : 'black',
                }}
              >
                {sitelink.description2.length > maxLength.description2
                  ? 'Max length reached'
                  : ''}
              </Typography>
            </Box>

            <TextField
              key={`finalUrl-${index}`}
              id={`finalUrl-${index}`}
              label={`Final URL`}
              variant="outlined"
              fullWidth
              value={sitelink.finalUrl}
              onChange={(e) => {
                setIsDirty(true);
                const temp = [...sitelinks];
                temp[index].finalUrl = e.target.value;
                setSitelinks(temp);
                const textarea = sitelinks
                  .map(
                    (item) =>
                      `${item.headline}\\${item.description1}\\${item.description2}\\${item.finalUrl}`,
                  )
                  .join('\n');
                setSitelinksArea(textarea);
              }}
              error={sitelink.finalUrl.length > maxLength.finalUrl}
              sx={{ backgroundColor: 'white' }}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginBottom: 2,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color:
                    sitelink.finalUrl.length > maxLength.finalUrl
                      ? 'red'
                      : 'black',
                }}
              >
                {`${sitelink.finalUrl.length}/${maxLength.finalUrl}`}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color:
                    sitelink.finalUrl.length > maxLength.finalUrl
                      ? 'red'
                      : 'black',
                }}
              >
                {sitelink.finalUrl.length > maxLength.finalUrl
                  ? 'Max length reached'
                  : ''}
              </Typography>
            </Box>
          </Box>
        ))}
        {sitelinks.length < maxItems ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSitelinks([
                ...sitelinks,
                {
                  headline: '',
                  description1: '',
                  description2: '',
                  finalUrl: '',
                },
              ]);
            }}
          >
            Add Sitelink
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default GoogleTemplatesSiteLinks;
