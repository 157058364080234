import axios from 'axios';
import { apiBaseUrl } from './api';
import { AdsTemplate, AudienceTemplate, DiySettings } from '../types/IDiy';
import { CustomAudience } from '../types';

export const fetchCampaignTemplates = async (
  brandId: string,
  provider?: string,
  active?: boolean,
) => {
  let params: any = {};

  if (provider) {
    params['provider'] = provider;
  }

  if (active) {
    params['active'] = active;
  }

  const data = await axios.get(
    `${apiBaseUrl}/diy/templates/campaigns/${brandId}`,
    { params },
  );

  return data?.data;
};

export const fetchSingleCampaignTemplate = async (templateId: string) => {
  const data = await axios.get(`${apiBaseUrl}/diy/templates/${templateId}`);

  return data?.data;
};

export const createCampaignTemplate = async (
  brandId: string,
  params: AdsTemplate,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/diy/templates/campaign/${brandId}`,
    params,
  );

  return data?.data;
};

export const duplicateCampaignTemplate = async (templateId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/diy/templates/duplicate/${templateId}`,
  );

  return data?.data;
};

export const updateCampaignTemplate = async (
  templateId: string,
  params: AdsTemplate,
) => {
  const data = await axios.put(
    `${apiBaseUrl}/diy/templates/campaign/${templateId}`,
    params,
  );

  return data?.data;
};

export const archiveCampaignTemplate = async (templateId: string) => {
  const data = await axios.delete(
    `${apiBaseUrl}/diy/templates/archive/${templateId}`,
  );

  return data?.data;
};

export const fetchAudienceTemplates = async (
  brandId: string,
  provider?: string,
  active?: boolean,
) => {
  let params: any = {};

  if (provider) {
    params['provider'] = provider;
  }

  if (active) {
    params['active'] = active;
  }

  const data = await axios.get(
    `${apiBaseUrl}/diy/templates/audience/${brandId}`,
    { params },
  );

  return data?.data;
};

export const createAudienceTemplate = async (
  brandId: string,
  params: AudienceTemplate,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/diy/templates/audience/${brandId}`,
    params,
  );

  return data?.data;
};

export const updateAudienceTemplate = async (
  audienceId: string,
  params: AudienceTemplate,
) => {
  const data = await axios.put(
    `${apiBaseUrl}/diy/templates/audience/${audienceId}`,
    params,
  );

  return data?.data;
};

export const createDiyCampaign = async (
  brandId: string,
  params: AdsTemplate,
) => {
  const data = await axios.post(`${apiBaseUrl}/diy/campaign`, params, {
    params: {
      brandId,
    },
  });

  return data?.data;
};

export const getCustomAudiences = async (brandId?: string) => {
  let params: any = {};

  if (brandId) {
    params = { ...params, brandId };
  }
  const data = await axios.get(`${apiBaseUrl}/ads/facebook/audience/custom`, {
    params,
  });

  return data?.data;
};

export const shareCustomAudiences = async (
  brandId: string,
  customAudiences: CustomAudience[],
) => {
  const data = await axios.post(
    `${apiBaseUrl}/ads/facebook/audience/custom/share`,
    {
      customAudiences,
      brandId,
    },
  );

  return data?.data;
};

export const createOrUpdateDiySettings = async (
  brandId: string,
  params: DiySettings,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/diy/settings/${brandId}`,
    params,
  );

  return data?.data;
};

export const getSingleDiySetting = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/diy/settings/${brandId}`);

  return data?.data;
};

export const stopFbAds = async (
  adId: string,
  brandId: string,
  refund?: boolean,
) => {
  let params: any = { brandId };

  if (refund) {
    params = { ...params, type: 'refund' };
  }

  const data = await axios.post(
    `${apiBaseUrl}/diy/campaign/stop/${adId}`,
    {},
    { params },
  );

  return data?.data;
};

export const createAdVariant = async (
  params: AdsTemplate,
  brandId: string,
  campaignId: string,
  adsetId: string,
) => {
  const data = await axios.post(`${apiBaseUrl}/diy/campaign/variant`, params, {
    params: { brandId, campaignId, adsetId },
  });

  return data?.data;
};

export const rebuildTemplate = async (adId: string) => {
  const data = await axios.get(`${apiBaseUrl}/diy/templates/rebuild`, {
    params: {
      adId,
    },
  });

  return data?.data;
};

export const editCampaign = async (adId: string, template: AdsTemplate) => {
  const data = await axios.put(
    `${apiBaseUrl}/diy/campaign/edit/${adId}`,
    template,
  );

  return data?.data;
};
