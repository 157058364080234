import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, {
  FormEvent,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import styles from '../../../assets/styles/components/Forms/Form.module.scss';
import { XsOnly } from '../../../utils/breakpoints';
import PageHeader from '../../PageHeader';
import { AdsTemplate, DiyError, DiySettings } from '../../../types/IDiy';
import {
  Brand,
  Campaign,
  ChangeEventType,
  FBLeadGenData,
  FBLeadGenForm,
  FBLeadGenQuestionForm,
  FbAssetFeedSpecMedia,
  FbTargetSearch,
  StripeCreateSetupIntentForm,
  StripeCustomerBalanceCredit,
  StripeCustomerForm,
} from '../../../types';
import {
  buildAdNamingConvention,
  diySteps,
} from '../../../utils/helpers/DiyHelpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDiyCampaign,
  fetchCampaignTemplates,
  getSingleDiySetting,
} from '../../../services/diy';
import { Objectives, adsProvider } from '../../../utils/constants/facebookAds';
import AdsTemplateListTable from '../../Tables/TemplateList/AdsTemplateListTable';
import { DrawerMenu } from '../../Menu';
import { AuthContext } from '../../../context';
import { ADMIN, IS_PRODUCTION, SUPER_ADMIN } from '../../../utils';
import { PrimaryButton } from '../../Buttons';
import LinearProgressBarWithLabel from '../../ProgressBar/LinearProgressBarWithLabel';
import { humanizeString } from '../../../utils/stringModifier';
import {
  FBDestinationType,
  FbAdStatus,
} from '../../../utils/helpers/facebookAdsHelper';
import { setBrand, setLocation, toggleAlert } from '../../../redux/actions';
import DiyBudgetForm from './DiyBudgetForm';
import { useNavigate } from 'react-router-dom';
import footer from '../../../assets/images/audience.png';
import {
  createFacebookGenForms,
  duplicateFbAdAccountImages,
  fetchBrandCampaigns,
  fetchSpecificFacebookGenForms,
  getFbPageDefaultIgAccount,
  getFbTargetingReachEstimate,
} from '../../../services/ads';
import CircularLoading from '../../CircularLoading';
import DiyAudienceSize from './DiyAudienceSize';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { toCurrency } from '../../../utils/numberFormatter';
import {
  createOrUpdateStripeCustomer,
  fetchSingleStripeCustomer,
  getCustomerCreditBalances,
  getCustomerPaymentMethods,
} from '../../../services/stripe/customer';
import { MuiTelInput } from 'mui-tel-input';
import CountrySelectMenu from '../../Select/CountrySelectMenu';
import AddCardIcon from '@mui/icons-material/AddCard';
import StripePaymentTermsModal from '../../Modal/StripePaymentTermsModal';
import { createStripeSetupIntent } from '../../../services/stripe/paymentIntent';
import StripePaymentUIModal from '../../Modal/StripePaymentUIModal';
import { IconContext } from 'react-icons';
import {
  buildCardBrand,
  renderCCIcon,
} from '../../Cards/Billing/PaymentMethodHelpers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { buildBrandAddress } from '../../../utils/helpers/BrandHelpers';
import PopupModal from '../../Modal';
import AdMediaForm from '../Ads/AdMediaForm';
import DiyTargetMarketForm from './DiyTargetMarketForm';
import DiyAdPreview from './DiyAdPreview';
import momentTz, { Moment } from 'moment-timezone';
import PoweredByStripe from '../../../assets/images/powered-by-stripe.svg';
import { DEFAULT_COLOR_THEME } from '../../../utils/Styling';
import FacebookAdDetails from '../../Details/Ads/FacebookAdDetails';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';

const DiyForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );
  const { state } = useContext(AuthContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;

  const [templates, setTemplates] = useState<AdsTemplate[]>([]);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<string>(null);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<AdsTemplate>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [geoLocationTemplate, setGeoLocationTemplate] = useState<any>(null);
  const [excludedGeoLocationTemplate, setExcludedGeoLocationTemplate] =
    useState<any>(null);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [progress, setProgress] = useState<number>(0);
  const [reachEstimate, setReachEstimate] = useState<any>({
    users_lower_bound: 0,
    users_upper_bound: 0,
  });
  const [reachError, setReachError] = useState<string>(null);
  const [sizeLoading, setSizeLoading] = useState<boolean>(false);
  const [customerForm, setCustomerForm] = useState<StripeCustomerForm>({
    address: {
      city: '',
      country: 'US',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
    },
    email: '',
    name: '',
    phone: '',
    metadata: {
      user_id: (location || brand)?._id,
      role: 'brand',
    },
  });
  const [customer, setCustomer] = useState<any>(null);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [openTermsModal, setOpenTermsModal] = useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<string>(null);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>(null);
  const [paymentMethod, setPaymentMethod] = useState<string>(null);
  const [parsedBody, setParsedBody] = useState<string>(null);
  const [openMediaForm, setOpenMediaForm] = useState<boolean>(false);
  const [editMedia, setEditMedia] = useState<boolean>(false);
  const [editRatio, setEditRatio] = useState<string>(null);
  const [leadForm, setLeadForm] = useState<FBLeadGenData>(null);
  const [leadFormValues, setLeadFormValues] = useState<FBLeadGenForm>(null);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [brandDiySettings, setBrandDiySettings] = useState<DiySettings>(null);
  const [excludeGeolocation, setExcludeGeolocation] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [nameBuilt, setNameBuilt] = useState<boolean>(false);
  const [campaignNameTag, setCampaignNameTag] = useState<string>('');
  const [adSetNameTag, setAdSetNameTag] = useState<string>('');
  const [adNameTag, setAdNameTag] = useState<string>('');
  const [diyError, setDiyError] = useState<DiyError>({
    geolocationError: '',
    nameError: '',
    descriptionError: '',
  });
  const [dateSet, setDateSet] = useState<boolean>(false);
  const [confirmPayment, setConfirmPayment] = useState<boolean>(false);
  const [creditBalance, setCreditBalance] =
    useState<StripeCustomerBalanceCredit>(null);
  const [newVideoIds, setNewVideoIds] = useState<string[]>([]);

  useEffect(() => {
    if (diySettings?.excludeGeolocation) {
      setExcludeGeolocation(true);
    }
  }, [diySettings?.excludeGeolocation]);

  const isEmployment =
    selectedTemplate?.campaignTemplate?.special_ad_categories?.includes(
      'EMPLOYMENT',
    );
  const isSpecialAds =
    selectedTemplate?.campaignTemplate?.special_ad_categories?.length > 0;

  useEffect(() => {
    let cities: any[] = [];
    diySettings?.geolocation?.cities?.forEach((location: any) => {
      location.radius = diySettings?.maxCityRadius || 50;

      cities = [...cities, location];
    });

    const customLocations = [
      {
        latitude: (location || brand)?.address?.lat,
        longitude: (location || brand)?.address?.lng,
        distance_unit: 'mile',
        radius: diySettings?.maxAddressRadius || 50,
      },
    ];

    let temp: any = {};

    if (isEmployment) {
      temp = { custom_locations: customLocations };
    } else {
      temp = { ...diySettings?.geolocation, cities };
      if (diySettings?.locationTypes?.includes('address')) {
        temp = { ...temp, custom_locations: customLocations };
      }
    }

    setGeoLocationTemplate(temp);
    setSelectedAddress((location || brand)?.address);
  }, [brand, location, isEmployment, isSpecialAds]);

  useEffect(() => {
    if (!isSpecialAds) {
      setExcludedGeoLocationTemplate({
        ...diySettings?.excludedGeolocations,
      });
    }
  }, [brand, location, isEmployment, isSpecialAds]);

  useEffect(() => {
    if (selectedTemplate?.adSetTemplate) {
      let temp: AdsTemplate = { ...selectedTemplate };

      temp.adSetTemplate.targeting = {
        ...temp.adSetTemplate.targeting,
        geo_locations: {
          ...geoLocationTemplate,
        },
      };

      setSelectedTemplate(temp);
    }
  }, [brand, location, selectedTemplate?.adSetTemplate, geoLocationTemplate]);

  useEffect(() => {
    if (selectedTemplate?.adSetTemplate && excludedGeoLocationTemplate) {
      let temp: AdsTemplate = { ...selectedTemplate };

      temp.adSetTemplate.targeting = {
        ...temp.adSetTemplate.targeting,
        excluded_geo_locations: {
          ...(!diySettings?.allowAllGeolocation
            ? excludedGeoLocationTemplate
            : excludeGeolocation
            ? excludedGeoLocationTemplate
            : {}),
        },
      };

      setSelectedTemplate(temp);
    }
  }, [
    brand,
    location,
    selectedTemplate?.adSetTemplate,
    excludedGeoLocationTemplate,
    excludeGeolocation,
    diySettings?.allowAllGeolocation,
  ]);

  useEffect(() => {
    if (
      selectedTemplate?.urlLocalizable &&
      diySettings &&
      activeStep === 'Ad Preview' &&
      (location || brand)?.__type === 'location'
    ) {
      let temp: AdsTemplate = { ...selectedTemplate };

      temp.creativeTemplate.asset_feed_spec.link_urls[0].website_url =
        isEmployment
          ? diySettings?.defaultEmploymentUrl
          : diySettings?.defaultUrl;
      temp.creativeTemplate.asset_feed_spec.link_urls[0].display_url =
        isEmployment
          ? diySettings?.defaultEmploymentUrl
          : diySettings?.defaultUrl;

      setSelectedTemplate(temp);
    }
  }, [diySettings, selectedTemplate?.urlLocalizable, activeStep]);

  useEffect(() => {
    if (
      selectedTemplate?.campaignTemplate?.objective ===
        Objectives.OUTCOME_LEADS &&
      !leadForm &&
      (location || brand)?.__type === 'location'
    ) {
      getLeadForm();
    }
  }, [
    brand,
    location,
    selectedTemplate?.campaignTemplate?.objective,
    leadForm,
  ]);

  useEffect(() => {
    if (diySteps(location || brand).length > 0) {
      const step = diySteps(location || brand)?.find(
        (value: string, index: number) => {
          return index === activeStepIndex;
        },
      );

      setActiveStep(step);
    }
  }, [diySteps(location || brand), activeStepIndex]);

  useEffect(() => {
    if (brand) getTemplates(brand);
  }, [brand]);

  useEffect(() => {
    if (location) {
      getDiySettings(location);
      getBrandDiySettings(brand);
    } else {
      getDiySettings(brand);
      if (brand?.__type === 'location') {
        getBrandDiySettings(brand?.franchisor);
      }
    }
  }, [location, brand]);

  useEffect(() => {
    if (
      activeStep === 'Target Market' &&
      selectedTemplate?.adSetTemplate?.targeting
    ) {
      let temp: any = { ...selectedTemplate?.adSetTemplate?.targeting };
      let geolocations: any = {
        ...selectedTemplate?.adSetTemplate?.targeting?.geo_locations,
      };
      let excludedGeolocations: any = {
        ...selectedTemplate?.adSetTemplate?.targeting?.excluded_geo_locations,
      };

      if (selectedTemplate?.adSetTemplate?.targeting?.geo_locations) {
        temp = { ...temp, geo_locations: geolocations };
      }

      if (selectedTemplate?.adSetTemplate?.targeting?.excluded_geo_locations) {
        temp = { ...temp, excluded_geo_locations: excludedGeolocations };
      }

      getFacebookTargetReach(temp);
    }
  }, [activeStep, selectedTemplate?.adSetTemplate?.targeting]);

  useEffect(() => {
    if (activeStep === 'Billing Summary' && (location || brand)) {
      getSingleStripeCustomer(location || brand);
    }

    if (activeStep === 'Campaign Type') {
      setNameBuilt(false);
      setDateSet(false);
    }

    if (activeStep === 'Budget' && !dateSet) {
      const localTime = momentTz().tz(timezone);
      const startDate = localTime.clone().unix();

      let temp: AdsTemplate = { ...selectedTemplate };

      temp = {
        ...temp,
        adSetTemplate: {
          ...temp.adSetTemplate,
          start_time: startDate,
        },
      };

      setSelectedTemplate(temp);
      setDateSet(true);
    }

    if (activeStep === 'Ad Preview' && (location || brand)) {
      let temp: AdsTemplate = { ...selectedTemplate };

      let originalString: string =
        temp?.creativeTemplate?.asset_feed_spec?.bodies[0]?.text;

      if (!parsedBody) setParsedBody(originalString);

      let parsedCustomField: any[] = [];

      temp.customFields.forEach((custom: any) => {
        let params: any = { ...custom };

        switch (params.field) {
          case '{{address}}':
            params = { ...params, value: buildBrandAddress(location || brand) };
            originalString = originalString.replace(
              params.field,
              buildBrandAddress(location || brand),
            );
            break;
          case '{{phone_number}}':
            params = {
              ...params,
              value: (location || brand)?.phoneNumber || '',
            };
            originalString = originalString.replace(
              params.field,
              (location || brand)?.phoneNumber || '',
            );
            break;
          default:
            params = { ...params, value: params.field };
            originalString = originalString.replace(params.field, params.field);
            break;
        }

        parsedCustomField = [...parsedCustomField, params];
      });

      temp = { ...temp, customFields: parsedCustomField };
      temp.creativeTemplate.asset_feed_spec.bodies[0].text = originalString;
      setSelectedTemplate(temp);
    }

    (async () => {
      if (
        activeStep === 'Campaign Details' &&
        (location || brand) &&
        !nameBuilt
      ) {
        let temp: AdsTemplate = { ...selectedTemplate };

        const data = await getCampaigns();

        const campaignName = buildAdNamingConvention(
          'campaign',
          location || brand,
          selectedTemplate,
          data,
          selectedTemplate?.limited || false,
        );

        const adSetName = buildAdNamingConvention(
          'adset',
          location || brand,
          selectedTemplate,
          data,
          selectedTemplate?.limited || false,
          campaignName,
        );

        const adName = buildAdNamingConvention(
          'ad',
          location || brand,
          selectedTemplate,
          data,
          selectedTemplate?.limited || false,
          null,
          adSetName,
          selectedTemplate?.name,
        );

        temp = {
          ...temp,
          campaignTemplate: {
            ...temp.campaignTemplate,
            name: campaignName,
          },
          adSetTemplate: {
            ...temp.adSetTemplate,
            name: adSetName,
          },
          adTemplate: {
            ...temp.adTemplate,
            name: adName,
          },
        };

        setSelectedTemplate(temp);
        setNameBuilt(true);
      }
    })();
  }, [activeStep, brand, location, campaigns]);

  useEffect(() => {
    if (customer && (location || brand)) getPaymentMethods(location || brand);
  }, [customer, location, brand, openPaymentModal]);

  useEffect(() => {
    if (activeStep === 'Campaign Details') getCampaigns();

    if (activeStep === 'Billing Summary') getCustomerCredits();
  }, [activeStep]);

  const getCampaigns = async () => {
    setFetchLoading(true);
    try {
      const campaigns = await fetchBrandCampaigns(
        (location || brand)?._id,
        'facebook',
        true,
      );

      setCampaigns(campaigns?.data || []);
      return campaigns?.data || [];
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  /**
   * TODO: Re-enable if we allowed user to change the form cover photo
   * 
  useEffect(() => {
    if (leadForm?.context_card?.cover_photo?.id) {
      getCoverPhoto();
    }
  }, [leadForm?.context_card?.cover_photo?.id]);
   */

  const getTemplates = async (brand: Brand) => {
    setFetchLoading(true);
    try {
      const response = await fetchCampaignTemplates(
        brand?.__type === 'location' ? brand?.franchisor?._id : brand?._id,
        adsProvider.FACEBOOK,
        true,
      );

      setTemplates(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getFacebookTargetReach = async (targeting: FbTargetSearch) => {
    setSizeLoading(true);
    try {
      const response = await getFbTargetingReachEstimate(
        (location || brand)?._id,
        targeting,
        selectedTemplate?.adSetTemplate?.optimization_goal,
      );

      setReachEstimate(response.data);
      setReachError(null);
    } catch (error: any) {
      const err = error.response.data;
      console.log(err);
      setReachEstimate({
        users_lower_bound: 0,
        users_upper_bound: 0,
      });
      setReachError(err?.message);
    } finally {
      setSizeLoading(false);
    }
  };

  const getSingleStripeCustomer = async (brand: Brand) => {
    try {
      const { data } = await fetchSingleStripeCustomer(brand?._id, 'brand');

      setCustomer(data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getPaymentMethods = async (brand: Brand) => {
    setLoading(true);
    try {
      const response = await getCustomerPaymentMethods(brand?._id);
      let temp: any = [...(response?.data || [])];
      temp = temp.sort((a: any) => {
        if (customer.invoice_settings.default_payment_method === a.id) {
          return -1;
        }

        return 1;
      });

      setPaymentMethods(temp);
      if (temp.length > 0) {
        setPaymentMethod(temp[0]?.id);
      }
      setLoading(false);
    } catch (error: any) {
      setPaymentMethods([]);
      setLoading(false);
      console.log(error);
    }
  };

  const getLeadForm = async () => {
    setLoading(true);
    try {
      const response = await fetchSpecificFacebookGenForms(
        ((location || brand)?.__type === 'location'
          ? (location || brand)?.franchisor
          : location || brand
        )?._id,
        selectedTemplate?.creativeTemplate?.asset_feed_spec?.call_to_actions[0]
          ?.value?.lead_gen_form_id,
      );

      let data: FBLeadGenData = response.data;

      let contextCardData: any;
      if (data?.context_card) {
        const {
          id: contextCardId,
          cover_photo,
          ...contextCard
        } = data.context_card;

        contextCardData = contextCard;
      }

      const { id: thankPageId, ...thankYouPage } = data.thank_you_page;
      const questions = data.questions.map(
        (question: FBLeadGenQuestionForm) => {
          const { id, ...questions } = question;

          if (questions.type !== 'CUSTOM') {
            delete questions['label'];
          }

          return questions;
        },
      );
      let temp: FBLeadGenForm = {
        ...leadFormValues,
        context_card: contextCardData ? { ...contextCardData } : null,
        name: `${(location || brand)?.brand}-${momentTz().tz(timezone).unix()}`,
        privacy_policy: {
          url: data.legal_content.privacy_policy.url,
          link_text: data.legal_content.privacy_policy.link_text,
        },
        questions: [...questions],
        thank_you_page: { ...thankYouPage },
        block_display_for_non_targeted_viewer:
          data.block_display_for_non_targeted_viewer,
        locale: data.locale as any,
        allow_organic_lead: data.allow_organic_lead,
        is_optimized_for_quality: data.is_optimized_for_quality,
        follow_up_action_url: data.follow_up_action_url,
        question_page_custom_headline: data.question_page_custom_headline,
      };

      setLeadFormValues(temp);

      setLeadForm(data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getDiySettings = async (brand: Brand) => {
    setFetchLoading(true);
    try {
      const response = await getSingleDiySetting(brand?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getBrandDiySettings = async (brand: Brand) => {
    try {
      const response = await getSingleDiySetting(brand?._id);

      setBrandDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getPageDefaultIgAccount = async () => {
    try {
      const response = await getFbPageDefaultIgAccount(
        diySettings?.facebookPage?.fbPageId,
        (location || brand)?._id,
      );

      return response.data;
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      console.log(errorMsg);
      console.log(error.message);
      return { error: 'Please setup your Instagram Account first.' };
    }
  };

  /**
   * TODO: Re-enable if we allowed user to change the form cover photo
   * 
  const getCoverPhoto = async () => {
    setLoading(true);
    try {
      const response = await fetchFbCoverPhoto(
        ((location || brand)?.__type === 'location'
          ? (location || brand)?.franchisor
          : location || brand
        )?._id,
        leadForm?.context_card?.cover_photo?.id,
      );


      const base64Image: any = await imageUrlToBase64(response.data.url);
      const image = base64Image.split(',')[1];

      if (base64Image) {
        let temp: FBLeadGenForm = {
          ...leadFormValues,
          cover_photo: image,
        };
        setLeadFormValues(temp);
      }

      setCoverPhoto(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
   */

  const getCustomerCredits = async () => {
    setLoading(true);
    try {
      const response = await getCustomerCreditBalances(
        (location || brand)?._id,
      );

      if (response.data.length > 0) {
        setCreditBalance(response.data[0]);
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const createLeadForm = async () => {
    try {
      const response = await createFacebookGenForms(
        (location || brand)?._id,
        leadFormValues,
      );

      return response.data;
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const buildDestination = (objective: string) => {
    if (
      objective === 'Get More Traffic' ||
      objective === 'Get More Leads' ||
      objective === 'Get More App Download'
    ) {
      return FBDestinationType.Undefined;
    }

    return FBDestinationType.Messenger;
  };

  const handleOnSubmit = async () => {
    let formId: string = '';
    setLoading(true);
    const progressPercentage =
      progress + (1 / diySteps(location || brand).length) * 100;

    setProgress((prevProgress) =>
      progressPercentage >= 100
        ? 100
        : prevProgress + (1 / diySteps(location || brand).length) * 100,
    );

    const hasSpecialCategory =
      selectedTemplate?.campaignTemplate?.special_ad_categories.length > 0;

    try {
      let instagramId: string = '';

      if (!diySettings?.instagramAccount?._id) {
        if (diySettings?.facebookPage?.instagramId) {
          instagramId = diySettings?.facebookPage?.instagramId;
        } else {
          const response = await getPageDefaultIgAccount();
          if (response.error)
            return dispatch(
              toggleAlert({
                toggle: true,
                message: response.error,
                type: 'error',
              }),
            );
          instagramId = response.id;
        }
      } else {
        instagramId = diySettings?.instagramAccount?.instagramId;
      }

      let params: AdsTemplate = {
        ...selectedTemplate,
        paymentMethod,
        limited: selectedTemplate?.limited || false,
        campaignTemplate: {
          ...selectedTemplate?.campaignTemplate,
          status: !IS_PRODUCTION ? FbAdStatus.PAUSED : FbAdStatus.ACTIVE,
          account_id: diySettings?.fbAdAccount?.accountId,
        },
        adSetTemplate: {
          ...selectedTemplate?.adSetTemplate,
          status: !IS_PRODUCTION ? FbAdStatus.PAUSED : FbAdStatus.ACTIVE,
          destination_type: buildDestination(selectedTemplate?.objective),
          optimization_goal:
            selectedTemplate?.campaignTemplate?.objective ===
            Objectives.OUTCOME_LEADS
              ? 'LEAD_GENERATION'
              : 'LINK_CLICKS',
        },
        adTemplate: {
          ...selectedTemplate?.adTemplate,
          status: !IS_PRODUCTION ? FbAdStatus.PAUSED : FbAdStatus.ACTIVE,
          template: selectedTemplate?._id,
        },
        creativeTemplate: {
          ...selectedTemplate?.creativeTemplate,
          name: `${selectedTemplate?.category}-${humanizeString(
            selectedTemplate?.media,
          )}-${momentTz().tz(timezone).unix()}`,
          object_story_spec: {
            ...selectedTemplate?.creativeTemplate?.object_story_spec,
            page_id: diySettings?.facebookPage?.fbPageId,
            instagram_actor_id: instagramId,
          },
          asset_feed_spec: {
            ...selectedTemplate?.creativeTemplate?.asset_feed_spec,
            asset_customization_rules: [
              {
                ...selectedTemplate?.creativeTemplate?.asset_feed_spec
                  ?.asset_customization_rules[0],
                customization_spec: {
                  ...selectedTemplate?.creativeTemplate?.asset_feed_spec
                    ?.asset_customization_rules[0]?.customization_spec,
                  publisher_platforms: ['facebook', 'instagram'],
                  facebook_positions: [
                    'feed',
                    'video_feeds',
                    'marketplace',
                    'search',
                  ],
                  instagram_positions: [
                    'stream',
                    'ig_search',
                    'profile_feed',
                    'explore',
                    'explore_home',
                  ],
                },
              },
              {
                ...selectedTemplate?.creativeTemplate?.asset_feed_spec
                  ?.asset_customization_rules[1],
                customization_spec: {
                  ...selectedTemplate?.creativeTemplate?.asset_feed_spec
                    ?.asset_customization_rules[1]?.customization_spec,
                  publisher_platforms: [
                    'facebook',
                    'instagram',
                    'messenger',
                    'audience_network',
                  ],
                  facebook_positions: ['story', 'facebook_reels'],
                  instagram_positions: ['reels', 'story'],
                  messenger_positions: ['story'],
                  audience_network_positions: [],
                },
              },
            ],
          },
          degrees_of_freedom_spec: {
            creative_features_spec: {
              standard_enhancements: {
                enroll_status: 'OPT_OUT',
              },
            },
          },
          contextual_multi_ads: {
            enroll_status: 'OPT_OUT',
          },
        },
        amount: (location || brand)?.allowSetupPayment
          ? selectedTemplate?.adSetTemplate?.lifetime_budget ||
            selectedTemplate?.adSetTemplate?.daily_budget
          : (selectedTemplate?.adSetTemplate?.lifetime_budget ||
              selectedTemplate?.adSetTemplate?.daily_budget) *
            (((location || brand)?.budgetOffset || 100) / 100),
      };

      /**
       * TODO: Add here optional assigning of Instagram Account
       */

      if (params?.campaignTemplate?.objective === Objectives.OUTCOME_LEADS) {
        params.adSetTemplate = {
          ...params.adSetTemplate,
          destination_type: FBDestinationType.OnAd,
          promoted_object: {
            page_id: diySettings?.facebookPage?.fbPageId,
          },
        };
      }

      switch (params?.audienceType) {
        /**
         * Opt-in or Opt-out to Advantage Lookalike
         * @reference https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-expansion/advantage-lookalike
         */
        case 'custom':
          params = {
            ...params,
            adSetTemplate: {
              ...params?.adSetTemplate,
              targeting: {
                ...params?.adSetTemplate?.targeting,
                targeting_relaxation_types: {
                  ...(params?.adSetTemplate?.targeting
                    ?.targeting_relaxation_types || {
                    lookalike: 1,
                    custom_audience: 0,
                  }),
                },
              },
            },
          };
          break;
        /* TODO: Reactive once the Advantage+ Audience is stable
       * https://developers.facebook.com/community/threads/922471492846116/
       * https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-expansion/advantage-audience
      case 'ai':
        params = {
          ...params,
          adSetTemplate: {
            ...params?.adSetTemplate,
            targeting: {
              ...params?.adSetTemplate?.targeting,
               targeting_automation?: {
                advantage_audience: 1,
              },
            },
          },
        };
        break;
        */
        /**
         * Opt-in or Opt-out to Advantage Detailed Targeting
         * @reference https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-expansion/advantage-detailed-targeting
         */
        default:
          if (
            selectedTemplate?.campaignTemplate?.objective ===
            Objectives.OUTCOME_LEADS
          ) {
            params = {
              ...params,
              adSetTemplate: {
                ...params?.adSetTemplate,
                targeting: {
                  ...params?.adSetTemplate?.targeting,
                  targeting_optimization:
                    params?.adSetTemplate?.targeting?.targeting_optimization ||
                    'expansion_all',
                },
              },
            };
          } else {
            const { targeting_optimization, ...targeting } =
              params.adSetTemplate.targeting;

            params = {
              ...params,
              adSetTemplate: {
                ...params?.adSetTemplate,
                targeting: {
                  ...targeting,
                },
              },
            };
          }

          break;
      }

      if (
        params?.campaignTemplate?.objective === Objectives.OUTCOME_LEADS &&
        diySettings?.allowSetupOwnAccount &&
        diySettings?.facebookPage?.fbPageId !==
          brandDiySettings?.facebookPage?.fbPageId &&
        (location || brand)?.__type === 'location'
      ) {
        const leadForm: any = await createLeadForm();

        if (leadForm) {
          formId = leadForm.id;

          params.creativeTemplate.asset_feed_spec.call_to_actions[0].value = {
            ...params.creativeTemplate.asset_feed_spec.call_to_actions[0].value,
            lead_gen_form_id: formId,
          };
        }
      }

      if (hasSpecialCategory) {
        params = {
          ...params,
          campaignTemplate: {
            ...params.campaignTemplate,
            special_ad_category_country: ['US'],
          },
          // Remove age range when using campaign special categories
          adSetTemplate: {
            ...params?.adSetTemplate,
            targeting: {
              ...params?.adSetTemplate?.targeting,
              age_min: null,
              age_max: null,
              custom_audiences: [],
            },
          },
        };
      }

      /**
       * Image/Video Duplicator
       * @description Duplicate media to the different ad account if the template
       *              was created using different ad account.
       */
      if (params?.accountId !== diySettings?.fbAdAccount?.accountId) {
        if (params?.media === 'image') {
          params?.creativeTemplate?.asset_feed_spec?.images?.forEach(
            async (image: FbAssetFeedSpecMedia) => {
              const data: any = {
                source_account_id: params?.accountId,
                hash: image?.hash,
              };

              await duplicateFbAdAccountImages((location || brand)?._id, data);
            },
          );
        } else {
          return dispatch(
            toggleAlert({
              toggle: true,
              message:
                'The template was created using different ad account. Try to use a different one or update your template.',
              type: 'error',
            }),
          );
          /**
           * TODO: Re-enable this when needed
           *
          const response = await duplicateVideo(params);

          console.log(response);
          */
        }
      }

      await createDiyCampaign(location ? location?._id : brand?._id, params);

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Campaign created successfully!',
        }),
      );

      navigate('/adz');
    } catch (error: any) {
      const err = error.response.data;
      if (err?.message) {
        dispatch(
          toggleAlert({
            toggle: true,
            message: err?.message,
            type: 'error',
          }),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPreview = (template: AdsTemplate) => {
    setSelectedTemplate(template);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleSetStartDate = (newValue: Moment | null) => {
    let temp: AdsTemplate = { ...selectedTemplate };

    const startDate = newValue.unix();

    temp.adSetTemplate = {
      ...temp.adSetTemplate,
      start_time: startDate,
    };

    setSelectedTemplate(temp);
  };

  const handleSetEndDate = (newValue: Moment | null) => {
    let temp: AdsTemplate = { ...selectedTemplate };

    const endDate = newValue.unix();

    temp.adSetTemplate = {
      ...temp.adSetTemplate,
      end_time: endDate,
    };

    setSelectedTemplate(temp);
  };

  const handleOnChangeBudget = (e: ChangeEventType) => {
    const name = e.target.name;
    let temp: AdsTemplate = { ...selectedTemplate };

    const startDate = momentTz
      .unix(selectedTemplate?.adSetTemplate?.start_time)
      .tz(timezone);

    const currentDate = momentTz().tz(timezone);

    const isScheduled = startDate > currentDate;
    const endOfMonth = startDate.clone().endOf('month');
    const remainingDays =
      endOfMonth.diff(isScheduled ? startDate : currentDate, 'days') + 1;

    if (name === 'daily_budget') {
      // Re-calculate current total budget
      const currentBudget = parseFloat(
        (parseFloat(e.target.value) * remainingDays).toFixed(2),
      );

      temp.adSetTemplate = {
        ...temp.adSetTemplate,
        lifetime_budget: null,
        daily_budget: parseFloat(e.target.value),
        totalBudget: currentBudget,
      };
    } else if (name === 'lifetime_budget') {
      temp.adSetTemplate = {
        ...temp.adSetTemplate,
        lifetime_budget: parseFloat(e.target.value),
        daily_budget: null,
      };
    } else if (name === 'totalBudget') {
      // Re-calculate daily budget
      const dailyBudget = parseFloat(
        (parseFloat(e.target.value) / remainingDays).toFixed(2),
      );

      temp.adSetTemplate = {
        ...temp.adSetTemplate,
        totalBudget: parseFloat(e.target.value),
        daily_budget: dailyBudget,
      };
    } else {
      const nextMonth = startDate.clone().add(1, 'month');
      const numberOfDays = nextMonth.daysInMonth();
      const futureDailyBudget = parseFloat(
        (parseFloat(e.target.value) / numberOfDays).toFixed(2),
      );
      temp.adSetTemplate = {
        ...temp.adSetTemplate,
        futureBudget: parseFloat(e.target.value),
        futureDailyBudget,
      };
    }

    setSelectedTemplate(temp);
  };

  const handleOnChange = (e: ChangeEventType) => {
    let temp: AdsTemplate = { ...selectedTemplate };
    const name = e.target.name;

    switch (name) {
      case 'link':
        temp.creativeTemplate.asset_feed_spec.link_urls[0].website_url =
          e.target.value;
        temp.creativeTemplate.asset_feed_spec.link_urls[0].display_url =
          e.target.value;
        break;
      case 'name':
        temp = { ...temp, name: e.target.value };
        break;
      case 'description':
        temp = { ...temp, [name]: e.target.value };
        break;
      default:
        temp.creativeTemplate.asset_feed_spec.bodies[0].text = e.target.value;
        break;
    }

    setSelectedTemplate(temp);
  };

  const handleNext = () => {
    if (activeStep === 'Target Market') {
      if (reachError) {
        return dispatch(
          toggleAlert({
            toggle: true,
            message: `There's an error with your targeted locations: ${reachError}`,
            type: 'error',
          }),
        );
      }

      let temp: any[] = [];
      let geolocation: any = {
        ...selectedTemplate?.adSetTemplate?.targeting?.geo_locations,
      };

      Object.keys(geolocation).forEach((key: string) => {
        geolocation[key].forEach((location: any) => {
          temp = [...temp, location];
        });
      });

      if (temp.length === 0) {
        return setDiyError({
          geolocationError: 'Please select at lease one (1) target location',
        });
      } else {
        setDiyError({ geolocationError: '' });
      }
    }

    if (activeStep === 'Campaign Details' && !selectedTemplate?.name) {
      return setDiyError({ nameError: 'Ad name is required' });
    } else {
      setDiyError({ nameError: '' });
    }

    if (activeStep === 'Campaign Details' && !selectedTemplate?.description) {
      return setDiyError({ descriptionError: 'Description is required' });
    } else {
      setDiyError({ descriptionError: '' });
    }

    if (activeStep === 'Campaign Details') {
      setNameBuilt(true);
    }

    if (activeStep === 'Budget') {
      setDateSet(true);
    }

    setActiveStepIndex((prev) => prev + 1);
    setProgress(
      (prevProgress) =>
        prevProgress + (1 / diySteps(location || brand).length) * 100,
    );
  };

  const handleBack = () => {
    setActiveStepIndex((prev) => prev - 1);
    setProgress(
      (prevProgress) =>
        prevProgress - (1 / diySteps(location || brand).length) * 100,
    );
  };

  const handleSelectTempalte = (template: AdsTemplate) => {
    setSelectedTemplate(template);
    handleNext();
    handleClosePreview();
  };

  const buildPageheader = () => {
    let title: string = '';
    switch (activeStep) {
      case 'Campaign Type':
        title = 'PLEASE CHOOSE YOUR TEMPLATE';
        break;
      case 'Target Market':
        title = 'PLEASE CHOOSE YOUR TARGET MARKET';
        break;
      case 'Budget':
        title = 'PLEASE SET YOUR SCHEDULE AND BUDGET';
        break;
      case 'Ad Preview':
        title = 'AD PREVIEW';
        break;
      case 'Campaign Details':
        title = 'CAMPAIGN DETAILS';
        break;
      default:
        title = 'BILLING SUMMARY';
        break;
    }

    return title;
  };

  const isAddress = (field: string) => {
    return [
      'city',
      'country',
      'line1',
      'line2',
      'postal_code',
      'state',
    ].includes(field);
  };

  const handleOnChangeCustomerForm = (e: ChangeEventType) => {
    if (isAddress(e.target.name)) {
      setCustomerForm({
        ...customerForm,
        address: {
          ...customerForm?.address,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setCustomerForm({
        ...customerForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOpenTermsModal = () => {
    setOpenTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  const handleOpenPaymentModal = async (
    clientSecret: string,
    transactionType: string,
  ) => {
    setClientSecret(clientSecret);
    setOpenPaymentModal(true);
    setTransactionType(transactionType);
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
  };

  const buildStripeCustomerName = () => {
    if ((location || brand)?.__type === 'location') {
      return `${(location || brand)?.franchisor?.brand}-${
        (location || brand)?.brand
      }`;
    }

    return (location || brand)?.brand;
  };

  const handleCreateCustomer = async (e: FormEvent) => {
    e.preventDefault();
    handleCloseTermsModal();
    setLoading(true);
    try {
      const params: StripeCustomerForm = {
        ...customerForm,
        name: buildStripeCustomerName(),
        metadata: {
          user_id: (location || brand)?._id,
          role: 'brand',
          brand: `${(location || brand)?.brand}`,
        },
      };

      const customerData = await createOrUpdateStripeCustomer(
        params,
        (location || brand)?._id,
        'brand',
      );

      if (customerData?.data) {
        let temp: Brand = {
          ...(location || brand),
          stripe: {
            ...(location || brand)?.stripe,
            customerId: customerData.data.id,
          },
        };

        if (location) {
          dispatch(setLocation(temp));
        } else {
          dispatch(setBrand(temp));
        }

        const params: StripeCreateSetupIntentForm = {
          customer: customerData.data.id,
          payment_method_types: ['card'],
          metadata: {
            user_id: (location || brand)?._id,
            role: 'brand',
            action: 'payment_method',
          },
        };

        const setupIntent = await createStripeSetupIntent(
          params,
          (location || brand)?._id,
        );

        handleOpenPaymentModal(setupIntent.data.client_secret, 'setup');
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPaymentMethod = async () => {
    setLoading(true);
    try {
      const params: StripeCreateSetupIntentForm = {
        customer: (location || brand)?.stripe?.customerId,
        payment_method_types: ['card'],
        metadata: {
          user_id: (location || brand)?._id,
          role: 'brand',
          action: 'payment_method',
        },
      };

      const setupIntent = await createStripeSetupIntent(
        params,
        (location || brand)?._id,
      );

      handleOpenPaymentModal(setupIntent.data.client_secret, 'setup');
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChangeCustomField = (e: ChangeEventType) => {
    const name = e.target.name;
    let temp: AdsTemplate = { ...selectedTemplate };
    let originalString: string = parsedBody;

    const index = temp?.customFields?.findIndex((value: any) => {
      return value.field === name;
    });

    temp.customFields[index].value = e.target.value;

    temp.customFields.forEach((custom: any) => {
      const { field, value } = custom;

      originalString = originalString.replace(field, value);
    });

    temp.creativeTemplate.asset_feed_spec.bodies[0].text = originalString;

    setSelectedTemplate(temp);
  };

  const handleOpenMediaForm = (
    mediaType?: string,
    edit?: boolean,
    ratio?: string,
  ) => {
    let temp: AdsTemplate = { ...selectedTemplate };
    switch (mediaType) {
      case 'image':
      case 'video':
        temp = { ...temp, media: mediaType };
        setEditMedia(edit);
        setEditRatio(ratio);

        break;
      default:
        break;
    }

    setOpenMediaForm((prev) => !prev);
    setSelectedTemplate(temp);
  };

  const handleCloseMediaForm = () => {
    setOpenMediaForm((prev) => !prev);
  };

  const handleOnChangeUrl = (e: ChangeEventType) => {
    let temp: AdsTemplate = { ...selectedTemplate };

    if (temp?.creativeTemplate?.asset_feed_spec?.link_urls[0]) {
      temp.creativeTemplate.asset_feed_spec.link_urls[0].website_url =
        e.target.value;
      temp.creativeTemplate.asset_feed_spec.link_urls[0].display_url =
        e.target.value;

      temp.creativeTemplate.asset_feed_spec.call_to_actions = [
        {
          ...temp.creativeTemplate.asset_feed_spec.call_to_actions[0],
          value: { link: e.target.value },
        },
      ];
    }

    setSelectedTemplate(temp);
  };

  const handleOnChangeTags = (
    type: string,
    campaignTag?: string,
    adsetTag?: string,
    adTag?: string,
  ) => {
    let temp: AdsTemplate = { ...selectedTemplate };

    const campaignName = buildAdNamingConvention(
      'campaign',
      location || brand,
      selectedTemplate,
      campaigns,
      selectedTemplate?.limited || false,
      null,
      null,
      campaignTag,
    );

    const adsetName = buildAdNamingConvention(
      'adset',
      location || brand,
      selectedTemplate,
      campaigns,
      selectedTemplate?.limited || false,
      campaignName,
      null,
      null,
      adsetTag,
    );

    const adName = buildAdNamingConvention(
      'ad',
      location || brand,
      selectedTemplate,
      campaigns,
      selectedTemplate?.limited || false,
      null,
      adsetName,
      null,
      null,
      adTag,
    );

    switch (type) {
      case 'adset':
        temp = {
          ...selectedTemplate,
          adSetTemplate: {
            ...selectedTemplate?.adSetTemplate,
            name: adsetName,
          },
          adTemplate: {
            ...selectedTemplate?.adTemplate,
            name: adName,
          },
        };

        setAdSetNameTag(adsetTag);

        break;
      case 'ad':
        temp = {
          ...temp,
          name: adTag,
          adTemplate: {
            ...temp?.adTemplate,
            name: adName,
          },
        };

        setAdNameTag(adTag);

        break;
      default:
        temp = {
          ...temp,
          campaignTemplate: {
            ...temp?.campaignTemplate,
            name: campaignName,
          },
          adSetTemplate: {
            ...temp?.adSetTemplate,
            name: adsetName,
          },
          adTemplate: {
            ...temp?.adTemplate,
            name: adName,
          },
        };

        setCampaignNameTag(campaignTag);

        break;
    }

    setSelectedTemplate(temp);
  };

  const buildChargePlatformLabel = (subscription: boolean) => {
    let label: string = '';

    const startDate = momentTz
      .unix(selectedTemplate?.adSetTemplate?.start_time)
      .tz(timezone);
    const currentDate = momentTz().tz(timezone);

    if (!(location || brand)?.chargeMediaFee && !subscription) {
      label = 'Charged by Facebook';
    } else {
      label = 'Charged Immediately';
    }

    if (startDate > currentDate) {
      label = `${label} on ${momentTz
        .unix(selectedTemplate?.adSetTemplate?.start_time)
        .tz(timezone)
        .format('MM/DD/YYYY')}`;
    }

    return label;
  };

  const buildPaymentAgreementLabel = () => {
    let charge: number = 0;
    let date: string = '';

    const startDate = momentTz
      .unix(selectedTemplate?.adSetTemplate?.start_time)
      .tz(timezone);
    const currentDate = momentTz().tz(timezone);

    if (calculatedAppliedBalance() > 0) {
      charge = calculatedTotalAmount() - calculatedAppliedBalance();
    } else {
      charge = calculatedTotalAmount();
    }

    if (startDate > currentDate) {
      date = `on ${momentTz
        .unix(selectedTemplate?.adSetTemplate?.start_time)
        .tz(timezone)
        .format('MM/DD/YYYY')}`;
    } else {
      date = 'immediately';
    }

    return `I confirm that I will be charged ${toCurrency(
      'USD',
      charge,
    )} ${date} and I authorize this payment. And I authorize the creation of the ad campaign on Facebook ${
      !(location || brand)?.chargeMediaFee
        ? 'which will charge my credit card associated with my'
        : 'in my associated'
    } ad account.`;
  };

  const calculatedTotalAmount = () => {
    let amount: number = 0;
    if (!(location || brand)?.chargeMediaFee) {
      amount =
        (selectedTemplate?.adSetTemplate?.lifetime_budget ||
          selectedTemplate?.adSetTemplate?.totalBudget) *
        (((location || brand)?.budgetPercentageFee || 0) / 100);
    } else {
      amount =
        selectedTemplate?.adSetTemplate?.lifetime_budget ||
        selectedTemplate?.adSetTemplate?.totalBudget;
    }

    return amount;
  };

  const calculatedAppliedBalance = () => {
    let appliedBalance: number = 0;
    if (creditBalance?.ending_balance < 0) {
      if (
        calculatedTotalAmount() > Math.abs(creditBalance?.ending_balance / 100)
      ) {
        appliedBalance = Math.abs(creditBalance?.ending_balance / 100);
      } else {
        return calculatedTotalAmount();
      }
    }

    return appliedBalance;
  };

  const renderContent = () => {
    if (activeStep === 'Campaign Type') {
      return (
        <AdsTemplateListTable
          templates={templates}
          loading={fetchLoading}
          onOpenPreview={handleOpenPreview}
        />
      );
    }

    if (activeStep === 'Target Market') {
      return (
        <DiyTargetMarketForm
          diySettings={diySettings}
          setDiySettings={setDiySettings}
          brand={location || brand}
          geoLocationTemplate={geoLocationTemplate}
          setGeoLocationTemplate={setGeoLocationTemplate}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          isSpecialAds={isSpecialAds}
          isEmployment={isEmployment}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          getTargetReach={getFacebookTargetReach}
          excludedGeolocationTemplate={excludedGeoLocationTemplate}
          setExcludedGeolocationTemplate={setExcludedGeoLocationTemplate}
          excludeGeolocation={excludeGeolocation}
          setExcludeGeolocation={setExcludeGeolocation}
          error={diyError}
        />
      );
    }

    if (activeStep === 'Budget') {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DiyBudgetForm
              template={selectedTemplate}
              onSetEndDate={handleSetEndDate}
              onSetStartDate={handleSetStartDate}
              onChangeBudget={handleOnChangeBudget}
              setTemplate={setSelectedTemplate}
              brand={location || brand}
            />
          </Grid>
        </Grid>
      );
    }

    if (activeStep === 'Ad Preview') {
      return (
        <DiyAdPreview
          template={selectedTemplate}
          onOpenMediaForm={handleOpenMediaForm}
          onChange={handleOnChange}
          onChangeCustomField={handleOnChangeCustomField}
          onChangeUrl={handleOnChangeUrl}
          diySettings={diySettings}
          loading={fetchLoading}
          activeStep={activeStep}
          objective={selectedTemplate?.campaignTemplate?.objective}
          setTemplate={setSelectedTemplate}
          brand={location || brand}
        />
      );
    }

    if (activeStep === 'Campaign Details') {
      return (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              required
              size="small"
              value={selectedTemplate?.name}
              name="name"
              onChange={handleOnChange}
              InputLabelProps={{ shrink: true }}
              label="Ad Name"
              error={diyError?.nameError ? true : false}
              helperText={diyError?.nameError ? diyError?.nameError : ''}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              required
              size="small"
              value={selectedTemplate?.description}
              name="description"
              label="Description"
              multiline
              onChange={handleOnChange}
              InputLabelProps={{ shrink: true }}
              minRows={4}
              maxRows={100}
              error={diyError?.descriptionError ? true : false}
              helperText={
                diyError?.descriptionError ? diyError?.descriptionError : ''
              }
            />
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Campaign Name Tags
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2">
              This tag will be used as the naming convention for the Facebook
              campaign.
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              size="small"
              value={campaignNameTag}
              name="campaignNameTag"
              onChange={(e: ChangeEventType) =>
                handleOnChangeTags(
                  'campaign',
                  e.target.value,
                  adSetNameTag,
                  adNameTag,
                )
              }
              InputLabelProps={{ shrink: true }}
              label="Campaign Name Tag"
              helperText={selectedTemplate?.campaignTemplate?.name}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              size="small"
              value={adSetNameTag}
              name="adSetNameTag"
              onChange={(e: ChangeEventType) =>
                handleOnChangeTags(
                  'adset',
                  campaignNameTag,
                  e.target.value,
                  adNameTag,
                )
              }
              InputLabelProps={{ shrink: true }}
              label="Adset Name Tag"
              helperText={selectedTemplate?.adSetTemplate?.name}
            />
          </Grid>

          {isAdmin || isSuperAdmin ? (
            <>
              <Grid item xs={8}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Facebook Campaign Configuration
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={selectedTemplate?.campaignTemplate?.name}
                  name="name"
                  onChange={(e: ChangeEventType) =>
                    setSelectedTemplate({
                      ...selectedTemplate,
                      campaignTemplate: {
                        ...selectedTemplate?.campaignTemplate,
                        name: e.target.value,
                      },
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  label="Campaign Name"
                  multiline
                />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={selectedTemplate?.adSetTemplate?.name}
                  name="name"
                  onChange={(e: ChangeEventType) =>
                    setSelectedTemplate({
                      ...selectedTemplate,
                      adSetTemplate: {
                        ...selectedTemplate?.adSetTemplate,
                        name: e.target.value,
                      },
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  label="Adset Name"
                  multiline
                />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={selectedTemplate?.adTemplate?.name}
                  name="name"
                  onChange={(e: ChangeEventType) =>
                    setSelectedTemplate({
                      ...selectedTemplate,
                      adTemplate: {
                        ...selectedTemplate?.adTemplate,
                        name: e.target.value,
                      },
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  label="Ad Name"
                  multiline
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      );
    }

    if (activeStep === 'Billing Summary') {
      return (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ justifyContent: 'center', width: '100%' }}
          >
            {!(location || brand)?.stripe?.customerId ? (
              <Card sx={{ width: '100%' }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', color: '#096F4D' }}
                      >
                        Contact Details
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        variant="standard"
                        type="email"
                        id="name"
                        name="email"
                        label="Email"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.email}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <MuiTelInput
                        fullWidth
                        required
                        variant="standard"
                        id="phone"
                        name="phone"
                        label="Phone Number"
                        onChange={(newValue: any) =>
                          setCustomerForm({ ...customerForm, phone: newValue })
                        }
                        value={customerForm?.phone}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        onlyCountries={['US']}
                        defaultCountry="US"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', color: '#096F4D' }}
                      >
                        Address Details
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="standard"
                        type="text"
                        id="line1"
                        name="line1"
                        label="Company Name, Street, PO Box"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.address?.line1}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        autoFocus
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        id="line2"
                        name="line2"
                        label="Apartment, Suite, Unit, Building"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.address?.line2}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="standard"
                        required
                        type="text"
                        id="city"
                        name="city"
                        label="City"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.address?.city}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        variant="standard"
                        type="text"
                        id="state"
                        name="state"
                        label="State"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.address?.state}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CountrySelectMenu
                        value={{
                          code: 'US',
                          label: 'United States',
                          phone: '1',
                          suggested: true,
                        }}
                        disabled
                        shrink
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        variant="standard"
                        type="text"
                        id="postalCode"
                        name="postal_code"
                        label="Postal Code"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.address?.postal_code}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      <PrimaryButton
                        title="Add Credit Card"
                        type="button"
                        startIcon={<AddCardIcon />}
                        handleOnClick={handleOpenTermsModal}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ) : null}

            {(location || brand)?.stripe?.customerId ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#096F4D' }}
                  >
                    Select the payment method
                  </Typography>
                </Grid>

                {paymentMethods?.length > 0 ? (
                  paymentMethods.map((payment: any, index: number) => {
                    const selected = payment.id === paymentMethod;

                    return (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          key={`payment-card-${index}`}
                          sx={{
                            position: 'relative',
                          }}
                        >
                          {selected ? (
                            <Box
                              component="div"
                              sx={{
                                position: 'absolute',
                                top: '6px',
                                zIndex: 1,
                                right: '-12px',
                                float: 'right',
                                margin: '1px',
                              }}
                            >
                              <CheckCircleIcon
                                sx={{
                                  color: DEFAULT_COLOR_THEME,
                                  bgcolor: 'InfoBackground',
                                  borderRadius: '50%',
                                }}
                              />
                            </Box>
                          ) : null}

                          <Card
                            sx={{
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer',
                              border: selected ? 'solid' : '',
                              borderColor: selected ? DEFAULT_COLOR_THEME : '',
                              borderWidth: 'medium',
                              zIndex: 0,
                            }}
                            onClick={() => setPaymentMethod(payment.id)}
                          >
                            <CardHeader
                              avatar={
                                <IconContext.Provider value={{ size: '40px' }}>
                                  {renderCCIcon(payment.card.brand)}
                                </IconContext.Provider>
                              }
                              title={`${buildCardBrand(payment.card)} ●●●● ${
                                payment.card.last4
                              }`}
                              subheader={`Expires ${payment.card.exp_month}/${payment.card.exp_year}`}
                              titleTypographyProps={{ fontWeight: 'bold' }}
                            />
                          </Card>
                        </Grid>
                      </>
                    );
                  })
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        textAlign="center"
                        sx={{ color: 'grey' }}
                      >
                        No card available.
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        textAlign="center"
                        sx={{ color: 'grey' }}
                      >
                        Please click the "Add Payment Method" button to add your
                        card.
                      </Typography>
                    </Grid>
                  </>
                )}

                <Grid
                  item
                  xs={12}
                  sx={{
                    justifyContent: 'center',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <PrimaryButton
                    title="Add Payment Method"
                    type="button"
                    handleOnClick={handleAddPaymentMethod}
                    loading={loading}
                    startIcon={<AddCardIcon />}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card sx={{ width: '100%' }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      Media Budget
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ justifyContent: 'center', display: 'flex' }}
                  >
                    {toCurrency(
                      'USD',
                      (selectedTemplate?.adSetTemplate?.lifetime_budget ||
                        selectedTemplate?.adSetTemplate?.totalBudget) -
                        (selectedTemplate?.adSetTemplate?.lifetime_budget ||
                          selectedTemplate?.adSetTemplate?.totalBudget) *
                          (((location || brand)?.budgetPercentageFee || 0) /
                            100),
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: (location || brand)?.chargeMediaFee
                          ? 'bold'
                          : '',
                        color: (location || brand)?.chargeMediaFee ? 'red' : '',
                      }}
                    >
                      {`(${buildChargePlatformLabel(false)})`}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      Subscription Fee
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ justifyContent: 'center', display: 'flex' }}
                  >
                    {toCurrency(
                      'USD',
                      (selectedTemplate?.adSetTemplate?.lifetime_budget ||
                        selectedTemplate?.adSetTemplate?.totalBudget) *
                        (((location || brand)?.budgetPercentageFee || 0) / 100),
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        color: 'red',
                      }}
                    >
                      {`(${buildChargePlatformLabel(true)})`}
                    </Typography>
                  </Grid>

                  {creditBalance?.ending_balance < 0 ? (
                    <>
                      <Grid item xs={4}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Account Credit
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sx={{ justifyContent: 'center', display: 'flex' }}
                      >
                        {toCurrency('USD', calculatedAppliedBalance())}
                      </Grid>

                      <Grid item xs={4} />
                    </>
                  ) : null}

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      Total
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ justifyContent: 'center', display: 'flex' }}
                  >
                    {toCurrency(
                      'USD',
                      selectedTemplate?.adSetTemplate?.lifetime_budget ||
                        selectedTemplate?.adSetTemplate?.totalBudget,
                    )}
                  </Grid>

                  <Grid item xs={4} />

                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Box
                      component="img"
                      src={PoweredByStripe}
                      alt=""
                      width={130}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <FormControlLabel
              control={
                <Checkbox size="small" defaultChecked={confirmPayment} />
              }
              label={
                <Fragment>
                  <Typography
                    variant="body2"
                    sx={{ color: 'red', fontWeight: 600 }}
                  >
                    {buildPaymentAgreementLabel()}
                  </Typography>
                </Fragment>
              }
              value={confirmPayment}
              onChange={(e: any) => {
                let temp: AdsTemplate = { ...selectedTemplate };

                setSelectedTemplate({
                  ...temp,
                  adSetTemplate: {
                    ...temp?.adSetTemplate,
                    confirmPayment: e.target.checked,
                  },
                });
                setConfirmPayment(e.target.checked);
              }}
              sx={{ mt: 2 }}
            />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Box className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}>
      <CircularLoading loading={loading} />

      <Box
        component="div"
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
          marginBottom: '20px',
        }}
      >
        <Box component="div" sx={{ paddingBottom: 0 }}>
          <PageHeader
            title={buildPageheader()}
            textAlign="left"
            marginBottom="0"
          />
        </Box>
      </Box>

      <Grid container spacing={12}>
        <Grid
          item
          xs={12}
          md={
            ['Campaign Type', 'Ad Preview', 'Billing Summary'].includes(
              activeStep,
            )
              ? 12
              : 8
          }
          sx={{ minHeight: 'calc(100vh - 222px)' }}
        >
          {renderContent()}
        </Grid>

        {activeStep !== 'Campaign Type' &&
        activeStep !== 'Ad Preview' &&
        activeStep !== 'Billing Summary' &&
        !smAndDown ? (
          <Grid item xs={12} md={4} mt={2}>
            <Grid container spacing={2}>
              {activeStep === 'Target Market' ? (
                <Grid item xs={12}>
                  <DiyAudienceSize
                    reachEstimate={reachEstimate}
                    loading={sizeLoading}
                    brand={brand}
                    error={reachError}
                    diySettings={diySettings}
                    setDiySettings={setDiySettings}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Box
                  component="img"
                  src={footer}
                  alt=""
                  sx={{
                    width: '300px',
                    marginTop: '100px',
                    position: 'sticky',
                    bottom: 0,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <Grid
        container
        spacing={1}
        mt={4}
        sx={{
          position: 'sticky',
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: '#FFF',
          paddingBottom: '20px',
          zIndex: 999,
          alignItems: 'flex-end',
        }}
      >
        <Grid item xs={12} sm={6}>
          <LinearProgressBarWithLabel value={progress} />
        </Grid>

        {activeStepIndex > 0 ? (
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              justifyContent: 'flex-end',
              display: 'flex',
              paddingY: '20px',
            }}
          >
            <PrimaryButton
              title="Back"
              type="button"
              handleOnClick={handleBack}
              marginRight5
              variant="text"
              color="red"
            />

            {(activeStep === 'Target Market' && reachError) ||
            (activeStepIndex + 1 === diySteps(location || brand).length &&
              activeStep === 'Billing Summary' &&
              (!paymentMethod || !confirmPayment)) ||
            (activeStep === 'Budget' &&
              !selectedTemplate?.adSetTemplate?.lifetime_budget &&
              !selectedTemplate?.adSetTemplate?.daily_budget) ? null : (
              <PrimaryButton
                loading={
                  activeStepIndex + 1 === diySteps(location || brand).length &&
                  loading
                }
                title={
                  activeStepIndex + 1 === diySteps(location || brand).length
                    ? 'Go Live'
                    : 'Next'
                }
                type="button"
                handleOnClick={
                  activeStepIndex + 1 === diySteps(location || brand).length
                    ? handleOnSubmit
                    : handleNext
                }
                startIcon={
                  activeStepIndex + 1 === diySteps(location || brand).length ? (
                    <RocketLaunchIcon />
                  ) : null
                }
              />
            )}
          </Grid>
        ) : null}
      </Grid>

      <DrawerMenu
        anchor="right"
        open={openPreview}
        onClose={handleClosePreview}
      >
        <FacebookAdDetails
          brand={location || brand}
          templateId={selectedTemplate?._id}
          isTemplate
          withAction
          onSelect={handleSelectTempalte}
          isDiy
          onClose={handleClosePreview}
          isMobile={xsOnly}
        />
      </DrawerMenu>

      <StripePaymentTermsModal
        open={openTermsModal}
        onClose={handleCloseTermsModal}
        onAgree={handleCreateCustomer}
        title="Advertising Services Agreement"
      />

      <StripePaymentUIModal
        open={openPaymentModal}
        onClose={handleClosePaymentModal}
        clientSecret={clientSecret}
        transactionType={transactionType}
        allowRedirect={false}
      />

      <PopupModal open={openMediaForm} handleClose={handleCloseMediaForm}>
        <AdMediaForm
          open={openMediaForm}
          brand={
            (location || brand)?.__type === 'location'
              ? (location || brand)?.franchisor
              : location || brand
          }
          accountId={diySettings?.fbAdAccount?.accountId}
          format={'media'}
          mediaType={selectedTemplate?.media}
          onClose={handleCloseMediaForm}
          template={selectedTemplate}
          setTemplate={setSelectedTemplate}
          assetFeedSpec={selectedTemplate?.creativeTemplate?.asset_feed_spec}
          edit={editMedia}
          ratio={editRatio}
          diy
          diySettings={diySettings}
          objective={selectedTemplate?.campaignTemplate?.objective}
          newVideoIds={newVideoIds}
          setNewVideoIds={setNewVideoIds}
          isEmployment={isEmployment}
        />
      </PopupModal>
    </Box>
  );
};

export default DiyForm;
