import {
  Box,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import styles from '../../../assets/styles/components/Forms/Form.module.scss';
import PageHeader from '../../PageHeader';
import LinearProgressBarWithLabel from '../../ProgressBar/LinearProgressBarWithLabel';
import { PrimaryButton } from '../../Buttons';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AdsTemplateListTable from '../../Tables/TemplateList/AdsTemplateListTable';
import { AdsTemplate, DiyError, DiySettings } from '../../../types/IDiy';
import {
  createAdVariant,
  fetchCampaignTemplates,
  getSingleDiySetting,
} from '../../../services/diy';
import { adsProvider } from '../../../utils/constants/facebookAds';
import {
  Brand,
  Campaign,
  CampaignAd,
  ChangeEventType,
  FbAssetFeedSpecMedia,
  FbCampaignAdSetForm,
  FbCreativeForm,
} from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerMenu } from '../../Menu';
import {
  duplicateFbAdAccountImages,
  fetchBrandCampaigns,
  getFbPageDefaultIgAccount,
  getSingleFbAdCreative,
} from '../../../services/ads';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FBCampaignObjective,
  FbAdStatus,
} from '../../../utils/helpers/facebookAdsHelper';
import { buildBrandAddress } from '../../../utils/helpers/BrandHelpers';
import { ADMIN, IS_PRODUCTION, SUPER_ADMIN } from '../../../utils';
import { buildAdNamingConvention } from '../../../utils/helpers/DiyHelpers';
import { AuthContext } from '../../../context';
import { humanizeString } from '../../../utils/stringModifier';
import momentTz from 'moment-timezone';
import { toggleAlert } from '../../../redux/actions';
import DiyAdPreview from './DiyAdPreview';
import PopupModal from '../../Modal';
import AdMediaForm from '../Ads/AdMediaForm';
import CircularLoading from '../../CircularLoading';
import FacebookAdDetails from '../../Details/Ads/FacebookAdDetails';

const AB_TEST_STEPS: string[] = [
  'The Template',
  'Ad Preview',
  'Campaign Details',
];

const ABTestForm: React.FC = () => {
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const params = useParams();
  const adId: string = params.adId;
  const { state } = useContext(AuthContext);
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const [activeStep, setActiveStep] = useState<string>(null);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [selectedTemplate, setSelectedTemplate] = useState<AdsTemplate>(null);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [templates, setTemplates] = useState<AdsTemplate[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [campaign, setCampaign] = useState<Campaign>(null);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [nameBuilt, setNameBuilt] = useState<boolean>(false);
  const [diyError, setDiyError] = useState<DiyError>({
    nameError: '',
    descriptionError: '',
    leadFormError: '',
  });
  const [selectedAdSet, setSelectedAdSet] = useState<FbCampaignAdSetForm>(null);
  const [selectedAd, setSelectedAd] = useState<CampaignAd>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [creative, setCreative] = useState<FbCreativeForm>(null);
  const [parsedBody, setParsedBody] = useState<string>(null);
  const [openMediaForm, setOpenMediaForm] = useState<boolean>(false);
  const [editMedia, setEditMedia] = useState<boolean>(false);
  const [editRatio, setEditRatio] = useState<string>(null);
  const [newVideoIds, setNewVideoIds] = useState<string[]>([]);
  const [limited, setLimited] = useState<boolean>(false);

  const isEmployment = campaign?.special_ad_categories?.includes('EMPLOYMENT');

  useEffect(() => {
    if (location || brand) {
      getTemplates(location || brand);
      getDiySettings(location || brand);
      getCampaigns();
    }
  }, [brand, location]);

  useEffect(() => {
    if (AB_TEST_STEPS.length > 0) {
      const step = AB_TEST_STEPS.find((value: string, index: number) => {
        return index === activeStepIndex;
      });

      setActiveStep(step);
    }
  }, [AB_TEST_STEPS, activeStepIndex]);

  useEffect(() => {
    if (selectedTemplate && activeStep === 'Ad Preview') {
      if (
        !selectedTemplate?.creativeTemplate?.asset_feed_spec?.call_to_actions[0]
          ?.value?.lead_gen_form_id &&
        campaign?.objective === FBCampaignObjective.Leads
      ) {
        let temp: AdsTemplate = { ...selectedTemplate };
        temp.creativeTemplate.asset_feed_spec.call_to_actions[0].value = {
          ...temp.creativeTemplate.asset_feed_spec.call_to_actions[0].value,
          lead_gen_form_id:
            creative?.asset_feed_spec?.call_to_actions[0]?.value
              ?.lead_gen_form_id,
        };

        setSelectedTemplate(temp);
      }
    }
  }, [selectedTemplate, creative, campaign, activeStep]);

  useEffect(() => {
    if (activeStep === 'Ad Preview' && (location || brand)) {
      let temp: AdsTemplate = { ...selectedTemplate };

      let originalString: string =
        temp?.creativeTemplate?.asset_feed_spec?.bodies[0]?.text;

      if (!parsedBody) setParsedBody(originalString);

      let parsedCustomField: any[] = [];

      temp.customFields.forEach((custom: any) => {
        let params: any = { ...custom };

        switch (params.field) {
          case '{{address}}':
            params = { ...params, value: buildBrandAddress(location || brand) };
            originalString = originalString.replace(
              params.field,
              buildBrandAddress(location || brand),
            );
            break;
          case '{{phone_number}}':
            params = {
              ...params,
              value: (location || brand)?.phoneNumber || '',
            };
            originalString = originalString.replace(
              params.field,
              (location || brand)?.phoneNumber || '',
            );
            break;
          default:
            params = { ...params, value: params.field };
            originalString = originalString.replace(params.field, params.field);
            break;
        }

        parsedCustomField = [...parsedCustomField, params];
      });

      temp = { ...temp, customFields: parsedCustomField };
      temp.creativeTemplate.asset_feed_spec.bodies[0].text = originalString;
      setSelectedTemplate(temp);
    }

    (async () => {
      if (
        activeStep === 'Campaign Details' &&
        (location || brand) &&
        !nameBuilt
      ) {
        let temp: AdsTemplate = { ...selectedTemplate };

        const campaigns = await getCampaigns();

        const adName = buildAdNamingConvention(
          'ad',
          location || brand,
          selectedTemplate,
          campaigns,
          limited,
          null,
          selectedAdSet?.name,
          null,
          null,
          selectedTemplate?.name,
        );

        temp = {
          ...temp,
          adTemplate: {
            ...temp.adTemplate,
            name: `${adName}`,
          },
        };

        setSelectedTemplate(temp);
        setNameBuilt(true);
      }
    })();
  }, [activeStep, campaign, campaigns]);

  useEffect(() => {
    if (selectedAd) getCreative();
  }, [selectedAd]);

  const getTemplates = async (brand: Brand) => {
    setFetchLoading(true);
    try {
      const response = await fetchCampaignTemplates(
        brand?.__type === 'location' ? brand?.franchisor?._id : brand?._id,
        adsProvider.FACEBOOK,
        true,
      );

      setTemplates(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getDiySettings = async (brand: Brand) => {
    setFetchLoading(true);
    try {
      const response = await getSingleDiySetting(brand?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getCreative = async () => {
    setFetchLoading(true);
    try {
      const response = await getSingleFbAdCreative(
        (location || brand)?._id,
        selectedAd?.creative?.creative_id,
      );

      setCreative(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getCampaigns = async () => {
    setFetchLoading(true);
    try {
      const campaigns = await fetchBrandCampaigns(
        (location || brand)?._id,
        'facebook',
        true,
      );

      let tempAd: CampaignAd;
      campaigns?.data?.forEach((campaign: Campaign) => {
        campaign?.ads?.forEach((ad: CampaignAd) => {
          if (ad.id === adId) {
            tempAd = ad;
          }
        });
      });

      const tempCampaign: Campaign = campaigns?.data?.find(
        (campaign: Campaign) => {
          const ids = campaign?.ads?.map((ad: any) => ad.id);

          return ids.includes(tempAd.id);
        },
      );

      const isV2 = tempCampaign.version === 'v2';

      let tempAdset: FbCampaignAdSetForm;
      campaigns?.data?.forEach((campaign: Campaign) => {
        campaign?.adSets?.forEach((adset: FbCampaignAdSetForm) => {
          if (adset.id === tempAd.adset_id) {
            tempAdset = adset;
          }
        });
      });

      setSelectedAdSet(tempAdset);
      setSelectedAd(tempAd);
      setCampaign(tempCampaign);
      setCampaigns(campaigns?.data || []);
      setLimited(isV2 ? tempAdset.limited : tempCampaign.limited);

      return campaigns?.data || [];
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getPageDefaultIgAccount = async () => {
    try {
      const response = await getFbPageDefaultIgAccount(
        diySettings?.facebookPage?.fbPageId,
        (location || brand)?._id,
      );

      return response.data;
    } catch (error: any) {
      console.log(error.message);
      return { error: 'Please setup your Instagram Account first.' };
    }
  };

  const handleOnSubmit = async () => {
    try {
      if (activeStep === 'Campaign Details' && !selectedTemplate?.name) {
        return setDiyError({ nameError: 'Ad name is required' });
      } else {
        setDiyError({ nameError: '' });
      }

      if (activeStep === 'Campaign Details' && !selectedTemplate?.description) {
        return setDiyError({ descriptionError: 'Description is required' });
      } else {
        setDiyError({ descriptionError: '' });
      }
      setLoading(true);
      const progressPercentage = progress + (1 / AB_TEST_STEPS.length) * 100;

      setProgress((prevProgress) =>
        progressPercentage >= 100
          ? 100
          : prevProgress + (1 / AB_TEST_STEPS.length) * 100,
      );

      let instagramId: string = '';
      if (!diySettings?.instagramAccount?._id) {
        if (diySettings?.facebookPage?.instagramId) {
          instagramId = diySettings?.facebookPage?.instagramId;
        } else {
          const response = await getPageDefaultIgAccount();
          if (response.error)
            return dispatch(
              toggleAlert({
                toggle: true,
                message: response.error,
                type: 'error',
              }),
            );
          instagramId = response.id;
        }
      } else {
        instagramId = diySettings?.instagramAccount?.instagramId;
      }

      let params: AdsTemplate = {
        ...selectedTemplate,
        adTemplate: {
          ...selectedTemplate?.adTemplate,
          status: !IS_PRODUCTION ? FbAdStatus.PAUSED : FbAdStatus.ACTIVE,
          template: selectedTemplate?._id,
        },
        creativeTemplate: {
          ...selectedTemplate?.creativeTemplate,
          name: `${selectedTemplate?.category}-${humanizeString(
            selectedTemplate?.media,
          )}-${momentTz().tz(timezone).unix()}`,
          object_story_spec: {
            ...selectedTemplate?.creativeTemplate?.object_story_spec,
            page_id: diySettings?.facebookPage?.fbPageId,
            instagram_actor_id: instagramId,
          },
          degrees_of_freedom_spec: {
            creative_features_spec: {
              standard_enhancements: {
                enroll_status: 'OPT_OUT',
              },
            },
          },
          contextual_multi_ads: {
            enroll_status: 'OPT_OUT',
          },
        },
      };

      /**
       * Duplicate Ad Image from one ad account to another
       */
      if (params?.accountId !== diySettings?.fbAdAccount?.accountId) {
        if (params?.media === 'image') {
          params?.creativeTemplate?.asset_feed_spec?.images?.forEach(
            async (image: FbAssetFeedSpecMedia) => {
              const data: any = {
                source_account_id: params?.accountId,
                hash: image?.hash,
              };

              await duplicateFbAdAccountImages((location || brand)?._id, data);
            },
          );
        } else {
          return dispatch(
            toggleAlert({
              toggle: true,
              message:
                'The template was created using different ad account. Try to use a different one or update your template.',
              type: 'error',
            }),
          );
          /**
           * TODO: Re-enable this when needed
           *
          const response = await duplicateVideo(params);

          console.log(response);
          */
        }
      }

      await createAdVariant(
        params,
        (location || brand)?._id,
        campaign.id,
        selectedAdSet?.id,
      );

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Ad variation created successfully!',
        }),
      );

      navigate('/adz');
    } catch (error: any) {
      const err = error.response.data;
      if (err?.message) {
        dispatch(
          toggleAlert({
            toggle: true,
            message: err?.message,
            type: 'error',
          }),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const buildPageheader = () => {
    let title: string = '';
    switch (activeStep) {
      case 'The Template':
        title = 'PLEASE CHOOSE YOUR TEMPLATE';
        break;
      case 'Campaign Details':
        title = 'CAMPAIGN DETAILS';
        break;
      default:
        title = 'AD PREVIEW';
        break;
    }

    return title;
  };

  const handleBack = () => {
    setActiveStepIndex((prev) => prev - 1);
    setProgress(
      (prevProgress) => prevProgress - (1 / AB_TEST_STEPS.length) * 100,
    );
  };

  const handleNext = () => {
    if (
      activeStep === 'Ad Preview' &&
      campaign.objective === FBCampaignObjective.Leads &&
      !selectedTemplate?.creativeTemplate?.asset_feed_spec?.call_to_actions[0]
        ?.value?.lead_gen_form_id
    ) {
      return setDiyError({ leadFormError: 'Please select a lead form' });
    } else {
      setDiyError({ leadFormError: '' });
    }

    setActiveStepIndex((prev) => prev + 1);
    setProgress(
      (prevProgress) => prevProgress + (1 / AB_TEST_STEPS.length) * 100,
    );
  };

  const handleOpenPreview = (template: AdsTemplate) => {
    setSelectedTemplate(template);
    setOpenPreview(true);
  };

  const handleSelectTempalte = (template: AdsTemplate) => {
    setSelectedTemplate(template);
    handleNext();
    handleClosePreview();
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleOnChange = (e: ChangeEventType) => {
    let temp: AdsTemplate = { ...selectedTemplate };
    const name = e.target.name;

    switch (name) {
      default:
        temp = { ...temp, [name]: e.target.value };
        break;
    }

    setSelectedTemplate(temp);
  };

  const handleOnChangeTags = (e: ChangeEventType) => {
    const adName = buildAdNamingConvention(
      'ad',
      location || brand,
      selectedTemplate,
      campaigns,
      limited,
      null,
      selectedAdSet?.name,
      null,
      null,
      e.target.value,
    );

    setSelectedTemplate({
      ...selectedTemplate,
      name: e.target.value,
      adTemplate: {
        ...selectedTemplate?.adTemplate,
        name: adName,
      },
    });
  };

  const handleOnChangeCustomField = (e: ChangeEventType) => {
    const name = e.target.name;
    let temp: AdsTemplate = { ...selectedTemplate };
    let originalString: string = parsedBody;

    const index = temp?.customFields?.findIndex((value: any) => {
      return value.field === name;
    });

    temp.customFields[index].value = e.target.value;

    temp.customFields.forEach((custom: any) => {
      const { field, value } = custom;

      originalString = originalString.replace(field, value);
    });

    temp.creativeTemplate.asset_feed_spec.bodies[0].text = originalString;

    setSelectedTemplate(temp);
  };

  const handleOnChangeUrl = (e: ChangeEventType) => {
    let temp: AdsTemplate = { ...selectedTemplate };

    if (temp?.creativeTemplate?.asset_feed_spec?.link_urls[0]) {
      temp.creativeTemplate.asset_feed_spec.link_urls[0].website_url =
        e.target.value;
      temp.creativeTemplate.asset_feed_spec.link_urls[0].display_url =
        e.target.value;
    }

    setSelectedTemplate(temp);
  };

  const handleOpenMediaForm = (
    mediaType?: string,
    edit?: boolean,
    ratio?: string,
  ) => {
    let temp: AdsTemplate = { ...selectedTemplate };
    switch (mediaType) {
      case 'image':
      case 'video':
        temp = { ...temp, media: mediaType };
        setEditMedia(edit);
        setEditRatio(ratio);

        break;
      default:
        break;
    }

    setOpenMediaForm((prev) => !prev);
    setSelectedTemplate(temp);
  };

  const handleCloseMediaForm = () => {
    setOpenMediaForm((prev) => !prev);
  };

  const renderContent = () => {
    if (activeStep === 'The Template') {
      return (
        <AdsTemplateListTable
          templates={templates}
          loading={fetchLoading}
          onOpenPreview={handleOpenPreview}
        />
      );
    }

    if (activeStep === 'Ad Preview') {
      return (
        <DiyAdPreview
          template={selectedTemplate}
          onOpenMediaForm={handleOpenMediaForm}
          onChange={handleOnChange}
          onChangeCustomField={handleOnChangeCustomField}
          onChangeUrl={handleOnChangeUrl}
          diySettings={diySettings}
          loading={fetchLoading}
          activeStep={activeStep}
          objective={campaign.objective}
          setTemplate={setSelectedTemplate}
          brand={location || brand}
          isAbTest
        />
      );
    }

    if (activeStep === 'Campaign Details') {
      return (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              required
              size="small"
              value={selectedTemplate?.name}
              name="name"
              onChange={handleOnChangeTags}
              InputLabelProps={{ shrink: true }}
              label="Ad Name"
              error={diyError?.nameError ? true : false}
              helperText={diyError?.nameError ? diyError?.nameError : ''}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              required
              size="small"
              value={selectedTemplate?.description}
              name="description"
              label="Description"
              multiline
              onChange={handleOnChange}
              InputLabelProps={{ shrink: true }}
              minRows={4}
              maxRows={100}
              error={diyError?.descriptionError ? true : false}
              helperText={
                diyError?.descriptionError ? diyError?.descriptionError : ''
              }
            />
          </Grid>

          {isAdmin || isSuperAdmin ? (
            <>
              <Grid item xs={8}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Facebook Campaign Configuration
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={selectedTemplate?.adTemplate?.name}
                  name="name"
                  onChange={(e: ChangeEventType) =>
                    setSelectedTemplate({
                      ...selectedTemplate,
                      adTemplate: {
                        ...selectedTemplate?.adTemplate,
                        name: e.target.value,
                      },
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  label="Ad Name"
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      );
    }
  };

  return (
    <Box className={`${styles.form} ${isXs ? `${styles['-mobile']}` : ''}`}>
      <CircularLoading loading={loading || fetchLoading} />

      <Box
        component="div"
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
          marginBottom: '20px',
        }}
      >
        <Box component="div" sx={{ paddingBottom: 0 }}>
          <PageHeader
            title={buildPageheader()}
            textAlign="left"
            marginBottom="0"
          />
        </Box>
      </Box>

      <Grid container spacing={12}>
        <Grid item xs={12} sx={{ minHeight: 'calc(100vh - 222px)' }}>
          {renderContent()}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        mt={4}
        sx={{
          position: 'sticky',
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: '#FFF',
          paddingBottom: '20px',
          zIndex: 999,
          alignItems: 'flex-end',
        }}
      >
        <Grid item xs={12} sm={6}>
          <LinearProgressBarWithLabel value={progress} />
        </Grid>

        {activeStepIndex > 0 ? (
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              justifyContent: 'flex-end',
              display: 'flex',
              paddingY: '20px',
            }}
          >
            <PrimaryButton
              title="Back"
              type="button"
              handleOnClick={handleBack}
              marginRight5
              variant="text"
              color="red"
            />

            <PrimaryButton
              loading={activeStepIndex + 1 === AB_TEST_STEPS.length && loading}
              title={
                activeStepIndex + 1 === AB_TEST_STEPS.length
                  ? 'Go Live'
                  : 'Next'
              }
              type="button"
              handleOnClick={
                activeStepIndex + 1 === AB_TEST_STEPS.length
                  ? handleOnSubmit
                  : handleNext
              }
              startIcon={
                activeStepIndex + 1 === AB_TEST_STEPS.length ? (
                  <RocketLaunchIcon />
                ) : null
              }
            />
          </Grid>
        ) : null}
      </Grid>

      <DrawerMenu
        anchor="right"
        open={openPreview}
        onClose={handleClosePreview}
      >
        <FacebookAdDetails
          brand={location || brand}
          templateId={selectedTemplate?._id}
          isTemplate
          withAction
          onSelect={handleSelectTempalte}
          isDiy
          onClose={handleClosePreview}
          isMobile={isXs}
        />
      </DrawerMenu>

      <PopupModal open={openMediaForm} handleClose={handleCloseMediaForm}>
        <AdMediaForm
          open={openMediaForm}
          brand={
            (location || brand)?.__type === 'location'
              ? (location || brand)?.franchisor
              : location || brand
          }
          accountId={diySettings?.fbAdAccount?.accountId}
          format={'media'}
          mediaType={selectedTemplate?.media}
          onClose={handleCloseMediaForm}
          template={selectedTemplate}
          setTemplate={setSelectedTemplate}
          assetFeedSpec={selectedTemplate?.creativeTemplate?.asset_feed_spec}
          edit={editMedia}
          ratio={editRatio}
          diy
          diySettings={diySettings}
          objective={selectedTemplate?.campaignTemplate?.objective}
          newVideoIds={newVideoIds}
          setNewVideoIds={setNewVideoIds}
          isEmployment={isEmployment}
        />
      </PopupModal>
    </Box>
  );
};

export default ABTestForm;
