import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  List,
  ListItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';
import axios from 'axios';

interface GoogleTemplatesBSNameProps {
  submitForm: any;
  templateForm: any;
  googleAccount: any;
}

const GoogleTemplatesBSName: React.FC<GoogleTemplatesBSNameProps> = ({
  submitForm,
  templateForm,
  googleAccount,
}) => {
  const [businessName, setBusinessName] = useState('');
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    if (googleAccount?.descriptiveName) {
      setBusinessName(googleAccount?.descriptiveName);
      submitForm(googleAccount?.descriptiveName);
    }

    if (templateForm) {
      setBusinessName(templateForm);
      submitForm(templateForm);
    }

    setSaving(false);
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          marginBottom: 1,
          color: DEFAULT_COLOR_THEME,
          fontWeight: 800,
          display: 'block',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'white',
        }}
      >
        Set Business Name
      </Typography>

      <Box
        sx={{
          maxWidth: 500,
        }}
      >
        <TextField
          label="Business Name"
          variant="outlined"
          value={businessName}
          sx={{
            width: '100%',
          }}
          onInput={(e: any) => {
            setBusinessName(e.target?.value);
            submitForm(e.target?.value);
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontWeight: 400,
            fontStyle: 'italic',
            fontSize: '0.7rem',
            paddingX: 1,
            color:
              businessName?.length > googleResourceLimits.business_name
                ? 'red'
                : 'black',
          }}
        >
          {businessName?.length || 0} / {googleResourceLimits.business_name}
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleTemplatesBSName;
