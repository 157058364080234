import React, { SyntheticEvent, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Pages.module.scss';
import PageHeader from '../../components/PageHeader';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DEFAULT_COLOR_THEME } from '../../utils';
import { Tab } from '@mui/material';
import { LookalikeAudienceReport } from '../../types/IAudience';
import { getLookalikeAudienceReport } from '../../services/report';
import LookalikeAudienceReportTable from '../../components/Tables/ReportList/LookalikeAudienceReportTable';

const TABS: string[] = ['Shared Lookalike Audience'];

const Monitoring: React.FC = () => {
  const [tabValue, setTabValue] = useState<string>('1');
  const [report, setReport] = useState<LookalikeAudienceReport[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  useEffect(() => {
    getLookalikeReport();
  }, []);

  const getLookalikeReport = async () => {
    try {
      setFetchLoading(true);
      const response = await getLookalikeAudienceReport();

      setReport(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleChangeTabValue = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const renderTabContent = (tab: string) => {
    if (tab === 'Shared Lookalike Audience') {
      return (
        <LookalikeAudienceReportTable data={report} loading={fetchLoading} />
      );
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Monitoring" />
        </div>

        <TabContext value={tabValue}>
          <TabList
            onChange={handleChangeTabValue}
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons
            textColor="secondary"
            TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
            TabScrollButtonProps={{
              style: { backgroundColor: DEFAULT_COLOR_THEME, color: 'white' },
            }}
            sx={{
              backgroundColor: DEFAULT_COLOR_THEME,
              '.MuiTab-root': {
                top: 5,
                color: 'white',
              },
              '.Mui-selected': {
                color: `${DEFAULT_COLOR_THEME} !important`,
                backgroundColor: 'white',
                top: 5,
                borderRadius: 2,
              },
            }}
          >
            {TABS?.map((tab: string, index: number) => (
              <Tab
                label={tab}
                value={`${index + 1}`}
                key={`monitoring-tab-header-${index + 1}`}
              />
            ))}
          </TabList>

          {TABS?.map((tab: string, index: number) => {
            return (
              <TabPanel
                value={`${index + 1}`}
                sx={{
                  backgroundColor: '#d9d9d933',
                  minHeight: 'calc(100vh - 260px)',
                }}
                key={`monitoring-content-${index + 1}`}
              >
                {renderTabContent(tab)}
              </TabPanel>
            );
          })}
        </TabContext>
      </div>
    </div>
  );
};

export default Monitoring;
