import React, { useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Budgets.module.scss';
import PageHeader from '../../components/PageHeader';
import AddIcon from '@mui/icons-material/Add';
import { AuthContext } from '../../context';
import {
  ADMIN,
  AGENCY,
  IS_DIY_ADZ,
  MARKETING_MANAGER,
  SUPER_ADMIN,
} from '../../utils';
import {
  getAiBudgetReport,
  getAiBudgetReportByAgency,
  syncDailyBudget,
} from '../../services/budget';
import { Agency, BudgetReport } from '../../types';
import momentTz, { Moment } from 'moment-timezone';
import { humanizeString } from '../../utils/stringModifier';
import { CSVLink } from 'react-csv';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../../components/Buttons';
import BudgetReportTable from '../../components/Tables/BudgetList/BudgetReportTable';
import MonthAndYearPicker from '../../components/DateAndTimePickers/MonthAndYearPicker';
import { buildRefreshToken } from '../../utils/helpers/DefaultTokenBuilder';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { setEndRange, setStartRange, toggleAlert } from '../../redux/actions';
import DateRangePicker from '../../components/DateAndTimePickers/DateRangePicker';
import PopupModal from '../../components/Modal';
import ModalHeader from '../../components/Modal/ModalHeader';
import SyncSuccessSummaryTable from '../../components/Tables/BudgetList/SyncSuccessSummaryTable';
import SyncFailedSummaryTable from '../../components/Tables/BudgetList/SyncFailedSummaryTable';
import { adsProvider } from '../../utils/constants/facebookAds';
import { MdSync } from 'react-icons/md';
import { toCurrency } from '../../utils/numberFormatter';

const Budgets: React.FC = () => {
  const dispatch = useDispatch();
  const budgetProvider = useSelector(
    (state: any) => state?.budgetReportProvider?.provider,
  );
  const timezone: string = momentTz.tz.guess();
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const isAgency = state.role === AGENCY;
  const withAgencyAccess = state.withAgencyAccess;
  const isMarketingManager = state.role === MARKETING_MANAGER;
  const capabilities = state?.capabilities;
  const allowedExport =
    isSuperAdmin ||
    isAdmin ||
    withAgencyAccess ||
    isAgency ||
    isMarketingManager;
  const refreshToken = state.authUser?.refreshToken;
  const agency: Agency = useSelector((state: any) => state?.agency?.agency);
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const pathLocation = useLocation();
  const pathNames = pathLocation.pathname.split('/');
  const isReport = pathNames.includes('kpiz');
  const startRange = useSelector((state: any) => state.startRange.date);
  const endRange = useSelector((state: any) => state.endRange.date);
  const isFirstOfTheMonth = momentTz().tz(timezone).date() === 1;
  const isFacebook = budgetProvider === adsProvider.FACEBOOK;
  const canEditBudget =
    isSuperAdmin ||
    isAdmin ||
    (!(isSuperAdmin || isAdmin) && capabilities?.editBudgets);

  const [loading, setLoading] = useState<boolean>(false);
  const [reportRows, setReportRows] = useState<BudgetReport[]>([]);
  const [reportMonth, setReportMonth] = useState<string>(
    momentTz().tz(timezone).format('MMMM'),
  );
  const [reportYear, setReportYear] = useState<string>(
    momentTz().tz(timezone).year().toString(),
  );
  const [reportDateValue, setReportDateValue] = useState<Moment | null>(
    isFirstOfTheMonth
      ? momentTz().tz(timezone)
      : momentTz().tz(timezone).subtract(1, 'day'),
  );
  const [daysCount, setDaysCount] = useState<number>(
    isFirstOfTheMonth
      ? parseInt(momentTz().tz(timezone).format('DD'))
      : parseInt(momentTz().tz(timezone).subtract(1, 'day').format('DD')),
  );
  const [monthDays, setMonthDays] = useState<number>(
    momentTz().tz(timezone).daysInMonth(),
  );
  const [defaultRefreshToken, setDefaultRefreshToken] = useState<string>(null);
  const [selectedRows, setSelectedRows] = useState<BudgetReport[]>([]);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [openSyncSummary, setOpenSyncSummary] = useState<boolean>(false);
  const [syncSuccessfulSummary, setSyncSuccessfulySummary] = useState<any[]>(
    [],
  );
  const [syncErrorsSummary, setSyncErrorsSummary] = useState<any[]>([]);

  useEffect(() => {
    handleBuildProviderTokens();
  }, []);

  useEffect(() => {
    try {
      if (reportYear && reportMonth && defaultRefreshToken) {
        const delayDebounceFn = setTimeout(() => {
          setReportRows([]);
          if (agency) {
            getBudgetReportsByAgency();
          } else {
            getBudgetReports();
          }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  }, [reportYear, reportMonth, defaultRefreshToken, agency, budgetProvider]);

  const getBudgetReports = async () => {
    try {
      setLoading(true);

      const response = await getAiBudgetReport(
        reportMonth,
        reportYear,
        budgetProvider,
        IS_DIY_ADZ,
      );

      reportRowData(response?.data || [], daysCount, monthDays);
      if (response.errors.length > 0) {
        errorParser(response.errors);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getBudgetReportsByAgency = async () => {
    try {
      setLoading(true);
      const response = await getAiBudgetReportByAgency(
        agency?._id,
        reportMonth,
        reportYear,
        budgetProvider,
        IS_DIY_ADZ,
      );

      reportRowData(response?.data || [], daysCount, monthDays);
      if (response.errors.length > 0) {
        errorParser(response.errors);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const errorParser = (errors: string[]) => {
    let msg: string = '';
    errors.forEach((error: any) => {
      msg += error;
    });
    dispatch(
      toggleAlert({
        toggle: true,
        message: msg,
        type: 'error',
      }),
    );
  };

  const handleSetReportRange = (date: Moment | null) => {
    const month = date.format('MMMM');
    const year = date.year();
    setReportYear(`${year}`);
    setReportMonth(`${month}`);
    setMonthDays(date.daysInMonth());
    const isCurrentMonth = date.isSame(momentTz().tz(timezone), 'month');

    if (isCurrentMonth) {
      const days = date.format('DD');
      setDaysCount(parseInt(days));
    } else {
      const days = date.daysInMonth();
      setDaysCount(days);
    }
  };

  const getBudgetStatus = (percentage: number) => {
    if (percentage >= 101) return 'EXCEED_BUDGET';

    if (percentage >= 95 && percentage < 101) return 'REACHING_BUDGET';

    return 'WITHIN_BUDGET';
  };

  const reportRowData = (
    data: BudgetReport[],
    countDays: number,
    monthDays: number,
  ) => {
    const rows = data.map((report: BudgetReport) => {
      const {
        id,
        group,
        budget,
        spend,
        daily,
        currentDaily,
        level,
        brand,
        campaign,
        status,
        brandName,
        locationName,
        campaignName,
        isLimited,
      } = report;

      const percentage = isFirstOfTheMonth
        ? 0
        : Number(
            (budget
              ? (spend / ((budget / monthDays) * countDays)) * 100
              : 0
            ).toFixed(2),
          );

      return {
        id,
        group,
        budget,
        spend,
        percentage,
        daily: parseFloat((daily || 0).toFixed(2)),
        currentDaily: parseFloat((currentDaily || 0).toFixed(2)),
        level,
        brand,
        campaign,
        status,
        budgetStatus: getBudgetStatus(percentage),
        brandName,
        locationName,
        campaignName,
        isLimited,
      };
    });

    setReportRows(rows);
  };

  const buildReportExportData = () => {
    return reportRows.map((row: BudgetReport) => {
      return {
        entity: row.group[row.group.length - 1],
        brand: ['campaign', 'adset', 'location']?.includes(row.level)
          ? row.brandName
          : '',
        location: ['campaign', 'adset', 'brand']?.includes(row.level)
          ? row.locationName || ''
          : '',
        campaignName: row.level === 'adset' ? row.campaignName || '' : '',
        level: row.level,
        budget: toCurrency('USD', row.budget),
        spend: toCurrency('USD', row.spend),
        percentage: `${row.percentage}%`,
        currentDaily: toCurrency('USD', row.currentDaily),
        recommendedDaily: toCurrency('USD', row.daily),
      };
    });
  };

  const handleBuildProviderTokens = () => {
    buildRefreshToken(refreshToken, setDefaultRefreshToken);
  };

  const handleChangeDateRange = (dates: Moment[] | []) => {
    dispatch(setStartRange(dates[0]?.tz(timezone)));
    dispatch(setEndRange(dates[1]?.tz(timezone)));
  };

  const handleOnSyncDailyBudget = async () => {
    try {
      setSyncLoading(true);

      const response = await syncDailyBudget(selectedRows);

      let temp: BudgetReport[] = [];
      reportRows.forEach((report: BudgetReport) => {
        const data: BudgetReport = response.data.find(
          (data: BudgetReport) => data.id === report.id,
        );

        if (data) {
          report.currentDaily = data.currentDaily;
        }

        temp = [...temp, report];
      });

      response.data.forEach((data: BudgetReport) => {
        const newDailyDiff: number = data.currentDaily - data.oldDaily;

        let campaignLevelDaily: number = 0;
        let brandLevelDaily: number = 0;

        const campaignLevel = reportRows.find(
          (report: BudgetReport) => report.id === data.campaign,
        );
        if (campaignLevel) {
          campaignLevelDaily = campaignLevel.currentDaily + newDailyDiff;
        }

        temp = updateReportRows(temp, campaignLevel.id, campaignLevelDaily);

        const brandLevel = reportRows.find(
          (report: BudgetReport) => report.id === data.brand,
        );
        if (brandLevel) {
          brandLevelDaily = brandLevel.currentDaily + newDailyDiff;
        }

        temp = updateReportRows(temp, brandLevel.id, brandLevelDaily);
      });

      if (response.errors.length > 0 || response.data.length > 0) {
        setSyncSuccessfulySummary(response.data || []);
        setSyncErrorsSummary(response.errors || []);
        setOpenSyncSummary(true);
      }

      setReportRows(temp);
      setSelectedRows([]);
    } catch (error: any) {
      console.log(error);
    } finally {
      setSyncLoading(false);
    }
  };

  const updateReportRows = (
    rows: BudgetReport[],
    id: string,
    currentDaily: number,
  ) => {
    let temp: BudgetReport[] = [];
    rows.forEach((report: BudgetReport) => {
      if (report.id === id) {
        report.currentDaily = parseFloat((currentDaily || 0).toFixed(2));
      }

      temp = [...temp, report];
    });

    return temp;
  };

  return (
    <div className={styles.budgets}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title={IS_DIY_ADZ && isReport ? 'Reportz' : 'Budgets'} />

          {isReport ? (
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sx={{
                  justifyContent: 'center',
                  display: 'flex',
                  mb: 1,
                }}
              >
                <DateRangePicker
                  toDate={endRange}
                  fromDate={startRange}
                  onChange={handleChangeDateRange}
                  maxDate={momentTz().tz(timezone)}
                  separator="-"
                />
              </Grid>
            </Grid>
          ) : null}

          <Grid
            container
            spacing={1}
            sx={{
              justifyContent: xsOnly ? 'center' : 'space-between',
            }}
          >
            <Grid item sx={{ flexDirection: xsOnly ? 'column' : '' }}>
              {canEditBudget && (!IS_DIY_ADZ || (IS_DIY_ADZ && !isReport)) ? (
                <PrimaryButton
                  title={`Set Up ${humanizeString(budgetProvider)} Ads Budgets`}
                  type="button"
                  startIcon={<AddIcon />}
                  handleOnClick={() =>
                    navigate(`/${IS_DIY_ADZ ? 'budgetz' : 'budgets'}/set-up`)
                  }
                  width={xsOnly ? '100%' : 'unset'}
                  marginRight5
                />
              ) : null}

              {canEditBudget && selectedRows?.length > 0 ? (
                <PrimaryButton
                  title="Update Daily Budget"
                  type="button"
                  startIcon={<MdSync />}
                  handleOnClick={handleOnSyncDailyBudget}
                  width={xsOnly ? '100%' : 'unset'}
                  marginRight5
                  loading={syncLoading}
                />
              ) : null}

              {allowedExport ? (
                <CSVLink
                  data={reportRows.length > 0 ? buildReportExportData() : []}
                  filename={`budget_report_${momentTz()
                    .tz(timezone)
                    .unix()}.csv`}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <PrimaryButton
                    variant="outlined"
                    type="button"
                    title="Export"
                    startIcon={<FileDownloadOutlinedIcon />}
                    width={xsOnly ? '100%' : 'unset'}
                    marginTop={xsOnly ? '10px' : 'unset'}
                  />
                </CSVLink>
              ) : null}
            </Grid>

            {!isReport ? (
              <Grid item>
                <MonthAndYearPicker
                  value={reportDateValue}
                  setValue={setReportDateValue}
                  handleChangeDate={handleSetReportRange}
                  endDay={daysCount.toString()}
                  isDiy={IS_DIY_ADZ}
                  timezone={timezone}
                />
              </Grid>
            ) : null}
          </Grid>
        </div>

        <div>
          <BudgetReportTable
            budgetReports={reportRows}
            loading={loading}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            canEditBudget={canEditBudget}
            isFacebook={isFacebook}
          />
        </div>
      </div>

      <PopupModal
        open={openSyncSummary}
        handleClose={() => setOpenSyncSummary(false)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ModalHeader title="Sync Daily Budget Summary" />
          </Grid>

          {syncSuccessfulSummary?.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Successful Sync
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <SyncSuccessSummaryTable rows={syncSuccessfulSummary} />
              </Grid>
            </>
          ) : null}

          {syncErrorsSummary?.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Failed Sync
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <SyncFailedSummaryTable rows={syncErrorsSummary} />
              </Grid>
            </>
          ) : null}
        </Grid>
      </PopupModal>
    </div>
  );
};

export default Budgets;
